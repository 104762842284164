<div (click)="showPublicProfile()" class="public_avatar" [ngClass]="{'info' : profile.publicName != null}">
    <svg class="no-foto" [ngClass]="{ 'avatar-book' : profile.isUserLogin && profile.guest == null }" width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="(profile.publicName == null || profile.publicName == '') && (!profile.isUserLogin || (profile.isUserLogin && profile.guest == null))">
        <g clip-path="url(#clip0_2662_2959)">
            <rect class="no-foto-background" x="0.599609" width="40" height="40" rx="20" fill="#FFF2E6"/>
            <rect class="no-foto-circle" x="12.5996" y="6" width="16" height="16" rx="8" fill="#FF6319"/>
            <rect class="no-foto-circle" x="-9.40039" y="27" width="60" height="60" rx="30" fill="#FF6319"/>
        </g>
        <defs>
            <clipPath id="clip0_2662_2959">
                <rect x="0.599609" width="40" height="40" rx="20" fill="white"/>
            </clipPath>
        </defs>
    </svg>
    <nz-avatar nzSize="large" [ngClass]="{ 'my-avatar-book' : profile.isUserLogin && profile.guest == null }" #avatarComponent [nzText]="(profile.publicName | slice: 0 : 2) | uppercase" *ngIf="profile.publicName != null && profile.publicName != '' && profile?.avatar?.name == null && profile.guest == null"></nz-avatar>
    <nz-avatar nzSize="large" [ngClass]="{ 'my-avatar-border' : profile.isUserLogin && profile.guest == null }" [nzSrc]="profile.avatar.url" *ngIf="profile.publicName != null && profile.publicName != '' && profile?.avatar?.name != null && profile.guest == null"></nz-avatar>
    <nz-avatar nzSize="large" [ngClass]="{ 'my-avatar-guest' : profile.isUserLogin && profile.guest != null }" #avatarComponent [nzText]="(profile.guest.name | slice: 0 : 1) + '' + (profile.guest.lastName | slice: 0 : 1)" *ngIf="(profile.publicName != null && profile.publicName != '' && profile.guest != null) || (profile.isUserLogin && profile.guest != null)"></nz-avatar>
</div>

<app-public-profile-viewer *ngIf="isVisiblePublicProfile" [isVisiblePublicProfile]="isVisiblePublicProfile" [publicProfile]="profile" [bookingPlace]="bookingPlace" (closeEvent)="closePublicProfile()"></app-public-profile-viewer>