import { Domain } from '@models/webpage/domain/domain';
import { TimeTableCenter } from '../time-table-center';
import { CenterPaymentConfiguration } from '../center-payment-configuration';
import { BookingCenterOption } from '../booking/booking-center-option';
import { WebPageColorPalette } from './color-palette/web-page-color-palette';
import { LoginSetting } from './setting/login-setting';
import { SignupSetting } from './setting/signup-setting';
import { CenterInfoCountry } from './country/center-info-country';
import { CorporateCenterSetting } from './setting/corporate-center-setting';

export class CenterInfo {
    private DEFAULT_LANGUAGE = navigator.language.replace('-', '_');
    private DEFAULT_LOCALE = navigator.language.substring(0, 2);

    idLanguage!: number;
    codeLanguage!: string;
    codeCountry!: string;
    locale!: string;
    color!: string;
    currency!: string;
    currencyCode!: string;
    dateFormat!: string;
    hourFormat!: string;
    id!: number;
    name!: string;
    publicToken!: string;
    secondaryColor!: string;
    showMeridian!: boolean;
    address!: string;
    email!: string;
    phone!: string;
    domain!: Domain;
    logo!: string;
    logoUrl!: string;
    timeTables!: TimeTableCenter[];
    zone!: string;
    firstWeekDay!: number;
    isVirtualSectionActive!: boolean;
    isPayment!: boolean;

    paymentConfiguration!: CenterPaymentConfiguration;
    bookingOptions!: BookingCenterOption;
    idTGCustom!: number;
    isActiveDigitalKit!: boolean;
    colorPalette!: WebPageColorPalette;
    loginSetting!: LoginSetting;
    signupSetting!: SignupSetting;
    countries!: CenterInfoCountry[];
    corporateCenterSetting!: CorporateCenterSetting;
    isActiveBiometricsLOPD!: boolean;
    isVisiblePublicProfile!: boolean;

    constructor() {
        this.id = 0;
        this.domain = new Domain();
        this.codeLanguage = this.DEFAULT_LANGUAGE;
        this.locale = this.DEFAULT_LOCALE;
    }

    build(content: any): this {
        Object.assign(this, content);

        if (content.timeTables) {
            this.timeTables = [];

            content.timeTables.forEach((timeTable: any) => {
                this.timeTables.push(new TimeTableCenter().build(timeTable, this.hourFormat));
            });
        }

        if (content.paymentConfiguration) {
            this.paymentConfiguration = new CenterPaymentConfiguration().build(content.paymentConfiguration);
        }

        if (content.bookingOptions) {
            this.bookingOptions = new BookingCenterOption().build(content.bookingOptions);
        }

        this.locale = this.codeLanguage.split('_')[0];

        return this;
    }

    getPhoneCode(): string {
        const country = this.countries.find(c => c.code === this.codeCountry);
        if (country) {
            return country.codePhone;
        }

        return '+34';
    }
}
