import { Tax } from '@models/tax/tax';

import { IntegrationPlatformType } from '@enumerates/index';

import { IntegrationPlatformData } from '@models/settings/integration-platform-data';

export class CenterPaymentConfiguration {
    isActiveSubscription!: boolean;
    isInvoiceByCenter!: boolean;
    idAccount!: string;
    isActiveManualPayments!: boolean;
    isActiveOnlinePayments!: boolean;
    isActiveServiceCredits!: boolean;
    isActiveServicePremium!: boolean;
    isSignUpNifRequired!: boolean;
    isActiveDebitSepa!: boolean;
    creditsTax!: Tax;
    integrationData!: Partial<IntegrationPlatformData>;
    idIntegration!: IntegrationPlatformType;
    isDisabledCancellationFromApp!: boolean;
    cancellationInfo!: string;

    build(content: any): this {
        Object.assign(this, content);

        if (this.integrationData) {
            this.idAccount = `${this.integrationData?.id}`;
        }

        return this;
    }

    centerHasActiveOnlinePayments(): boolean {
        return !!this.idAccount && this.isActiveOnlinePayments;
    }
}
