import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SessionService } from '@services/utils/session.service';

@Component({
    selector: 'app-token',
    templateUrl: './token.component.html'
})
export class TokenComponent implements OnInit {
    isCollapsed = false;

    constructor(private route: ActivatedRoute, private router: Router, private sessionService: SessionService) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            const domain = params.get('domain');
            const op = params.get('op');
            const jwt = params.get('jwt');

            if (domain != null) {
                this.sessionService.setDomain(domain);
                if (op != null && jwt != null) {
                    this.router.navigateByUrl('', { state: { op, jwt } });
                } else {
                    this.router.navigateByUrl('');
                }
            } else {
                this.router.navigateByUrl('unauthorized');
            }
        });
    }
}
