<nz-card class="product-card" [ngClass]="{ 'primary' : fee.isFeatured, 'secondary' : !fee.isFeatured }">
    <ng-container *ngIf="fee.isFeatured">
        <div class="featured-product">{{ fee.featuredTag }}</div>
    </ng-container>
    <div>
        <div class="heading-4 product-title" text-center>{{ fee.title }}</div>
        <div class="sub-heading-2 text-ellipsis" text-center top-24>{{ fee.subtitle }}</div>
        <div class="pricing heading-2" text-center top-24>
            {{ fee.oneTimePrice | number:'1.2-2' }} {{ currency }}
        </div>
        <div class="common-1 product-description" top-24>{{ fee.description }}</div>
    </div>
    <div class="product-card-footer-info">
        <button nz-button nzType="primary" nzBlock class="big" (click)="viewRegister()">{{ fee.buttonTitle }}</button>

        <div text-center>
            <a (click)="viewDetails()" class="common-caption-1">{{ 'view_details' | translate }}</a>
        </div>
    </div>
</nz-card>