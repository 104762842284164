import { inject, Injectable } from '@angular/core';
import { ModalOptions, NzModalService } from 'ng-zorro-antd/modal';

@Injectable({
    providedIn: 'root'
})
export class NotificationModalService {
    private modalService = inject(NzModalService);

    add(title: string, message: string): void {
        const modalOptions: ModalOptions = {
            nzTitle: title,
            nzContent: message,
            nzCentered: true
        };

        modalOptions.nzTitle = title;

        this.modalService.warning(modalOptions);
    }
}
