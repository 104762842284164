<ng-template #indicatorTemplate>
  <i nz-icon nzType="loading" [nzTheme]="'outline'" style="font-size: 24px; display: block;"></i>
</ng-template>

<nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="isLoading | async">
    <div class="fees-container">
        <ng-container *ngIf="oneTimeFees$ | async as oneTimeFees;">
            <ng-container *ngFor="let oneTime of oneTimeFees">
                <app-fee-detail [fee]="oneTime"></app-fee-detail>
            </ng-container>
            
            <ng-container *ngIf="oneTimeFees.length == 0">
                <div nz-row nzJustify="center" nzAlign="middle" style="height: 350px;">
                    <div nz-col nzSpan="8">
                        <h2 style="text-align: center;">{{ 'no_rates_show' | translate }}</h2>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</nz-spin>
