import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from '@views/auth/auth.component';
import { NotFoundComponent } from '@views/generic/notfound/notfound.component';
import { TokenComponent } from './components/token/token.component';

import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

const routes: Routes = [
    {
        path: 'unauthorized',
        component: UnauthorizedComponent
    },
    {
        path: 'auth',
        component: AuthComponent
    },
    {
        path: '',
        loadChildren: () => import('src/app/views/views.module').then(m => m.ViewsModule)
    },
    {
        path: 'home/:domain',
        component: TokenComponent
    },
    {
        path: 'home/:domain/:op/:jwt',
        component: TokenComponent
    },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', onSameUrlNavigation: 'reload' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
