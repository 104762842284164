import { environment } from '@environments/environment';

export class VideoHelper {
    // tslint:disable-next-line:max-line-length
    static expressionYoutube = /http(?:s?):\/\/(?:www\.)?youtu(?:(be\.com\/)*(?:e(?:mbed)\/live_stream\?channel=|watch\?v=|\.be\/))([\w\-\_]*)(&(amp;)?‌[\w\?‌=]*)?/;
    static expressionVimeo = /\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i;
    static expressionWhereby = /https:\/\/(([a-zA-Z]+)\.)*whereby\.com\/([a-zA-Z]+)/;
    static expressionZoom = /https:\/\/(.*\.)?zoom.us\/([a-z])\/(.+?\b)/;
    static expressionHangouts = /https:\/\/meet.google.com\/([a-z]+\-[a-z]+\-[a-z]+)/;
    static expressionFacebook = /https?:\/\/(?:www\.)?(facebook|fb)(\.com.*|\.gg)?(\/|\.)(video(s)?|watch|story|v)(\.php?|\/)([a-zA-Z0-9_-]+)(\/([a-zA-Z0-9_-]+))?/;
    static expressionInstagram = /http(?:s?):\/\/(?:www\.)?instagram\.com\/([a-z]+)\/([a-zA-Z0-9-]+)/;
    static expressionTwitch = /^(?:https?:\/\/)?(?:www\.|go\.)?twitch\.tv\/([a-z0-9_]+)($|\?)/;
    static expressionMicrosoft = /https?:\/\/(?:www\.)?teams.microsoft.com\/[a-z]\/meetup-join\/(.*)/;
    static default = '';

    static isValidUrl = (url: string): boolean => VideoHelper.expressionYoutube.exec(url) != null || VideoHelper.expressionVimeo.exec(url) != null;
    static isInstagram = (url: string): boolean => VideoHelper.expressionInstagram.exec(url) != null;

    static isGoogleOrMicrosoft = (url: string): boolean => VideoHelper.expressionHangouts.exec(url) != null ||
                VideoHelper.expressionMicrosoft.exec(url) != null ||
                VideoHelper.expressionZoom.exec(url) != null;

    static getEmbedUrl = (url: string): string => {
        let data = VideoHelper.expressionYoutube.exec(url);

        if (data != null) {
            return `https://www.youtube.com/embed/${data[2]}?autoplay=1&controls=0`;
        }

        data = VideoHelper.expressionVimeo.exec(url);

        if (data != null) {
            return `https://player.vimeo.com/video/${data[1]}`;
        }

        data = VideoHelper.expressionWhereby.exec(url);

        if (data != null) {
            if (data[1] == null || data[1] === undefined || data[1] === '') {
                return `https://whereby.com/${data[3]}?embed`;
            } else {
                return `https://${data[1]}.whereby.com/${data[3]}?embed`;
            }
        }

        data = VideoHelper.expressionZoom.exec(url);

        if (data != null) {
            return `https://zoom.us/${data[2]}/${data[3]}/join?prefer=1&un=TWluZGF1Z2Fz`;
        }

        data = VideoHelper.expressionHangouts.exec(url);

        if (data != null) {
            return '';
        }

        data = VideoHelper.expressionFacebook.exec(url);

        if (data != null) {
            if (data[7] === 'v') {
                return `https://www.facebook.com/video/embed?video_id=${data[9]}`;
            } else {
                return `https://www.facebook.com/video/embed?video_id=${data[7]}`;
            }
        }

        data = VideoHelper.expressionInstagram.exec(url);

        if (data != null) {
            return `https://instagram.com/p/${data[2]}/embed`;
        }

        data = VideoHelper.expressionTwitch.exec(url);

        if (data != null) {
            return `https://player.twitch.tv/?channel=${data[1]}&parent=${environment.apps.polaris.replace(/https:\/\//g, '')}`;
        }

        data = VideoHelper.expressionMicrosoft.exec(url);

        if (data != null) {
            return '';
        }

        return '';
    };
}
