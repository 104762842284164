import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { EndpointParams } from '@models/utils/endpoint-params';

import { HttpService } from '@services/http.service';
import { GuestCreate } from '@views/booking/models/guest/guest-create';
import { Guest } from '@views/booking/models/guest/guest';

@Injectable({
    providedIn: 'root'
})
export class GuestsService extends HttpService {
    private readonly defaultEndpointParams: EndpointParams = { app: 'booking', version: 1 };

    create(guestCreate: GuestCreate): Observable<Guest> {
        return this.post('customer/guest', this.defaultEndpointParams, guestCreate);
    }
}
