import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignupFormType } from '@enumerates/setting/signup-form-type';
import { PaymentsDomainHelper } from '@helpers/payments-domain-helper';
import { CenterInfo } from '@models/center/info/center-info';

import { TranslateService } from '@ngx-translate/core';
import { SettingPaymentsService } from '@services/settings/settings-payments.service';
import { InternationalizationService } from '@services/utils/internationalization/internationalization.service';
import { SessionService } from '@services/utils/session.service';

import * as moment from 'moment';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
    private DEFAULT_HOUR_FORMAT_24 = 'HH:mm';
    private DEFAULT_HOUR_FORMAT_12 = 'h:mm a';

    constructor(private route: ActivatedRoute,
                private router: Router,
                private sessionService: SessionService,
                private settingPaymentsService: SettingPaymentsService,
                private internationalizationService: InternationalizationService,
                private translateService: TranslateService) { }

    ngOnInit(): void {
        this.route.queryParams.subscribe(
            params => {
                const token = params.token;
                const idCenter = params.idCenter;
                const redirect = params.redirect;
                const idActivity = params.idActivity;
                const idTask = params.idTask;

                if (token != null && idCenter != null) {
                    this.sessionService.setCenter({ id: idCenter, name: '', publicToken: ''});
                    this.sessionService.setDomain(PaymentsDomainHelper.getDomainMaster());
                    this.sessionService.setToken(token).pipe(take(1)).subscribe(() => {
                        this.settingPaymentsService.getSettings().subscribe(
                            data => {
                                const centerInfo: CenterInfo = new CenterInfo().build({
                                    idLanguage: data.language.id,
                                    codeLanguage: data.language.codeLanguage,
                                    color: data.color ? data.color : 'FF6319',
                                    secondaryColor: data.secondaryColor ? data.secondaryColor : 'BF4215',
                                    currency: data.currency,
                                    currencyCode: data.currencyCode,
                                    dateFormat: data.date,
                                    hourFormat: data.showMeridian ? this.DEFAULT_HOUR_FORMAT_12 : this.DEFAULT_HOUR_FORMAT_24,
                                    id: idCenter,
                                    name: '',
                                    publicToken: '',
                                    showMeridian: data.showMeridian,
                                    address: '',
                                    email: '',
                                    phone: '',
                                    logo: '',
                                    logoUrl: '',
                                    zone: data.zone,
                                    domain: { url: PaymentsDomainHelper.getDomainMaster() },
                                    paymentConfiguration: {
                                        isActiveSubscription: false,
                                        isInvoiceByCenter: data.isInvoiceByCenter,
                                        idAccount: data.integrationPlatformData?.id,
                                        isActiveManualPayments: data.isActiveManualPayments,
                                        isActiveOnlinePayments: data.isActiveOnlinePayments,
                                        isActiveDebitSepa: data.isActiveDebitSepa,
                                        isActiveServiceCredits: data.isActiveServiceCredits,
                                        isActiveServicePremium: data.isActiveServicePremium,
                                        integrationData: data.integrationPlatformData,
                                        idIntegration: data.idIntegration,
                                        creditsTax: data.creditsTax,
                                        isDisabledCancellationFromApp: data.isDisabledCancellationFromApp,
                                        cancellationInfo: data.cancellationInfo
                                    },
                                    isPayment: true,
                                    idTGCustom: data.idTGCustom,
                                    colorPalette: {
                                        firstColor: data.color ? data.color : 'FF6319',
                                        firstColorContrast: data.secondaryColor ? data.secondaryColor : '000000',
                                        secondColor: data.color ? data.color : 'FF6319',
                                        secondColorContrast: '000000',
                                        thirdColor: data.color ? data.color : 'FF6319',
                                        firstColorText: '000000',
                                        secondColorText: '4A4A4A',
                                        thirdColorText: '595959',
                                    },
                                    signupSetting: {
                                        signupFormType: SignupFormType.simple
                                    },
                                    isVirtualSectionActive: data.isVirtualSectionActive,
                                    countries: [],
                                    corporateCenterSetting: {
                                        isModuleActive: false,
                                        canInviteGuests: false
                                    },
                                    isActiveBiometricsLOPD: false,
                                    isVisiblePublicProfile: false
                                });

                                this.translateService.use(data.language.codeLanguage);
                                const code = data.language.codeLanguage.split('_')[0];
                                this.internationalizationService.setLanguage(data.language.codeLanguage);
                                this.internationalizationService.setLocale(code);
                                moment.updateLocale(code, null);
                                this.setInternationalization(centerInfo);

                                this.sessionService.setCenterInfo(centerInfo);
                                this.succeded(redirect, idActivity, idTask);
                            },
                            () => {
                                this.sessionService.deleteToken();
                                this.sessionService.deleteCenter();
                                this.sessionService.deleteDomain();
                                this.failed();
                            }
                        );
                    });
                } else {
                    this.failed();
                }
            }
        );
    }

    succeded(redirect: string, idActivity: number, idTask: string): void {
        if (redirect && redirect !== '') {
            this.router.navigateByUrl(this.getRedirect(redirect), { state: { idActivity, idTask } });
        } else {
            this.router.navigateByUrl('/private/dashboard');
        }
    }

    private getRedirect(redirect: string): string {
        if (redirect === 'credits') {
            return '/private/credits';
        }

        if (redirect === 'services') {
            return '/private/services';
        }

        if (redirect === 'fees') {
            return '/rates';
        }

        return '/private/dashboard';
    }

    failed(): void {
        this.router.navigateByUrl('/unauthorized');
    }

    private setInternationalization(centerInfo: CenterInfo): void {
        this.internationalizationService.setLanguage(centerInfo.codeLanguage);
        this.internationalizationService.setLocale(centerInfo.locale);
        this.internationalizationService.setDateFormat(centerInfo.dateFormat);
        this.internationalizationService.setHourFormat(centerInfo.hourFormat);
        this.internationalizationService.setFirstDayOfWeek(centerInfo.firstWeekDay % 7);
    }
}
