import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { FilterPipeModule } from 'ngx-filter-pipe';

import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxCookiebotModule } from '@halloverden/ngx-cookiebot';

import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzCardModule } from 'ng-zorro-antd/card';
import { PortalModule } from '@angular/cdk/portal';

import { CalendarDetailComponent } from '@views/booking/calendar/detail/calendar-detail.component';
import { RealTimeComponent } from '@views/booking/realtime/realtime.component';
import { WaitQueueModalComponent } from '@views/booking/calendar/waitqueue/wait-queue-modal.component';
import { CalendarDetailOldComponent } from '@views/booking/calendar/detail/old/calendar-detail-old.component';
import { BookingMemberInfoComponent } from '@views/booking/realtime/booking-info/booking-member-info.component';
import { CalendarMapComponent } from '@views/booking/calendar/map/calendar-map.component';
import { RatesComponent } from '@views/private/rates/rates.component';
import { RatesSinglePaymentComponent } from '@views/private/rates/single-payment/rates-single-payment.component';
import { RatesSubscriptionComponent } from '@views/private/rates/suscription/rates-subscription.component';
import { PublicProfileViewerComponent } from 'src/app/components/public-profile/public-profile-viewer/public-profile-viewer.component';
import { RatesProductsComponent } from '@views/private/rates/products/rates-products.component';
import { GuestsComponent } from '@views/booking/calendar/guests/guests.component';
import { CancelBooksComponent } from '@views/booking/calendar/cancel-books/cancel-books.component';
import { DndDirective } from '../directives/dnd.directive';
import { FeeDetailComponent } from '../components/fee-detail/fee-detail.component';
import { FeeDetailSubscriptionComponent } from '../components/fee-detail/subscription/fee-detail-subscription.component';
import { FeeDetailOneTimeComponent } from '../components/fee-detail/onetime/fee-detail-onetime.component';
import { FeeDetailInfoComponent } from '../components/fee-detail-info/fee-detail-info.component';
import { PublicProfileAvatarComponent } from '../components/public-profile/public-profile-avatar.component';
import { FeeDetailProductComponent } from '../components';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        NzLayoutModule,
        NzMenuModule,
        NzButtonModule,
        NzModalModule,
        NzGridModule,
        NzCarouselModule,
        NzFormModule,
        NzSpaceModule,
        NzIconModule,
        NzInputModule,
        NzNotificationModule,
        NzDividerModule,
        NzRadioModule,
        FilterPipeModule,
        NzBadgeModule,
        NzSpinModule,
        NzDropDownModule,
        NzAvatarModule,
        NzDrawerModule,
        NzListModule,
        NzTabsModule,
        NzImageModule,
        NzStepsModule,
        NzCheckboxModule,
        NzCollapseModule,
        NzDescriptionsModule,
        NzUploadModule,
        NzToolTipModule,
        NzDatePickerModule,
        NzSelectModule,
        NzPopconfirmModule,
        NzResultModule,
        NzTableModule,
        NzSwitchModule,
        NzTagModule,
        NzEmptyModule,
        NzBackTopModule,
        NzSkeletonModule,
        NzAffixModule,
        NzCardModule,
        NzAlertModule,
        PortalModule
    ],
    declarations: [
        CalendarDetailComponent,
        GuestsComponent,
        CancelBooksComponent,
        CalendarMapComponent,
        CalendarDetailOldComponent,
        WaitQueueModalComponent,
        RealTimeComponent,
        BookingMemberInfoComponent,
        PublicProfileViewerComponent,
        DndDirective,
        FeeDetailComponent,
        FeeDetailSubscriptionComponent,
        FeeDetailOneTimeComponent,
        FeeDetailProductComponent,
        FeeDetailInfoComponent,
        PublicProfileAvatarComponent,
        RatesComponent,
        RatesSinglePaymentComponent,
        RatesSubscriptionComponent,
        RatesProductsComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        ImageCropperModule,
        NgxCookiebotModule,
        NzLayoutModule,
        NzMenuModule,
        NzButtonModule,
        NzModalModule,
        NzGridModule,
        NzCarouselModule,
        NzFormModule,
        NzSpaceModule,
        NzIconModule,
        NzInputModule,
        NzNotificationModule,
        NzDividerModule,
        NzRadioModule,
        FilterPipeModule,
        NzBadgeModule,
        NzSpinModule,
        NzDropDownModule,
        NzAvatarModule,
        NzDrawerModule,
        NzListModule,
        NzTabsModule,
        NzImageModule,
        NzStepsModule,
        NzCheckboxModule,
        NzCollapseModule,
        NzDescriptionsModule,
        NzUploadModule,
        NzToolTipModule,
        NzDatePickerModule,
        NzSelectModule,
        NzPopconfirmModule,
        NzResultModule,
        NzAlertModule,
        NzTableModule,
        NzSwitchModule,
        NzTagModule,
        CalendarDetailComponent,
        CalendarMapComponent,
        CalendarDetailOldComponent,
        WaitQueueModalComponent,
        NzEmptyModule,
        NzBackTopModule,
        RealTimeComponent,
        BookingMemberInfoComponent,
        PublicProfileViewerComponent,
        NzMessageModule,
        DndDirective,
        NzProgressModule,
        NzSkeletonModule,
        NzAffixModule,
        NzCardModule,
        PortalModule,
        NzInputNumberModule,
        FeeDetailComponent,
        FeeDetailSubscriptionComponent,
        FeeDetailOneTimeComponent,
        FeeDetailProductComponent,
        FeeDetailInfoComponent,
        PublicProfileAvatarComponent,
        RatesComponent,
        RatesSinglePaymentComponent,
        RatesSubscriptionComponent,
        RatesProductsComponent
    ]
})
export class SharedModule { }
