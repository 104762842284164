import { PlatformType } from '@views/booking/enumerates/platform-type';

export class BookingEvent {
    idMember!: number;
    idSchedule!: number;
    platformType!: PlatformType;
    bookedSeat!: number;
    idPosition!: string;
    idCenter!: number;
    idBooking!: number;
    amountCredits!: number;
    ipAddress!: string;
    idGuest!: string;

    build(content: string): this {
        const dataParsed = JSON.parse(content);

        return this.buildFromData(dataParsed);
    }

    buildFromData(content: any): this {
        Object.assign(this, content);

        return this;
    }
}
