import { Guid } from 'guid-typescript';
import * as moment from 'moment';
import { PublicProfile } from '@models/public-profile/public-profile';
import { ScheduleState } from '../../../enumerates/schedule-state';
import { BookingState } from '../../../enumerates/booking-state';
import { BookingStateColor } from '../../../enumerates/booking-state-color';

import { RoomBasic } from '../../room/room-basic';
import { ActivityInfo } from '../../activity/activity-info';
import { ScaleBorgCenterTranslate } from '../../scale-borg/scale-borg-center-translate';
import { ScheduleBook } from '../../schedule-book/schedule-book';
import { StaffBasic } from '../../staff/staff-basic';
import { AccessInfo } from './access-info';

export class ScheduleInfoBasic {
    id!: number;
    publicToken!: Guid;
    timeStart!: string;
    timeEnd!: string;
    duration!: number;
    assistants!: number;
    capacityAssistant!: number;
    currentState!: ScheduleState;
    room!: RoomBasic;
    activity!: ActivityInfo;
    scaleDifficulty!: ScaleBorgCenterTranslate;
    bookingInfo!: ScheduleBook;
    staff!: StaffBasic | null;

    bookingState!: BookingState;
    bookingStateName!: string;
    bookingStateText!: string;
    orderBookingWaitText!: string;
    bookingStateColor!: string;

    currentStateColor!: string;
    isFavorite!: boolean;
    timeStartDate!: Date;
    timeEndDate!: Date;
    isAvailableInMyFee!: boolean;

    dateProgram!: Date;
    availableSeats!: number;
    bookedMemberResumen!: PublicProfile[] | undefined;
    accessInfo!: AccessInfo;

    build(content: any): this {
        Object.assign(this, content);

        if (content.activity) {
            this.activity = new ActivityInfo().build(content.activity);
        }

        if (content.bookingInfo) {
            this.bookingInfo = new ScheduleBook().build(content.bookingInfo);
        }

        if (content.staff) {
            this.staff = new StaffBasic().build(content.staff);
        }

        this.bookingStateName = BookingState[this.bookingState];
        if (this.bookingState === BookingState.multipleBookedAvailable || this.bookingState === BookingState.bookedMultipleTimes) {
            this.bookingStateName = BookingState[BookingState.booked];
        }
        this.bookingStateColor = BookingStateColor[this.bookingState];

        this.timeStartDate = moment(content.timeStart, 'HH:mm:ss').toDate();
        this.timeEndDate = moment(content.timeEnd, 'HH:mm:ss').toDate();
        this.getAvailableSeats();

        return this;
    }

    private getAvailableSeats(): void {
        if (!this.bookingInfo) {
            return;
        }

        this.availableSeats = Math.max(this.capacityAssistant - this.bookingInfo.bookedPlaces, 0);
    }
}
