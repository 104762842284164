export enum CategoryMachine {
    spinning = 0,
    elliptical = 1,
    step = 2,
    running = 3,
    set_bar_and_discs = 4,
    fitball = 5,
    rowing = 6,
    elastic_tensioner = 7,
    trx = 8,
    pull_up_bar = 9,
    pilates_reformers = 10,
    bosu = 11,
    curved_tape = 12,
    cardio_ladder = 13,
    drawer = 14,
    battling_ropes = 15,
    rack = 16,
    boxing_bag = 17,
    sand_bag = 18,
    yoga = 19,
    air_bike = 20,
    skierg = 21,
    no_material = 22,
    other = 23
}
