<nz-modal [nzWidth]="'620px'" [(nzVisible)]="showModal" [nzFooter]="null" [nzClosable]="false" [nzKeyboard]="false" [nzMaskClosable]="false" nzCentered [nzClassName]="'resume-modal'">
    <ng-container *nzModalContent>
        <div nz-row [nzGutter]="[0, 16]">
            <div nz-col [nzSpan]="24" text-center class="heading-4">
                {{ 'select_seat_to_cancel' | translate }}
            </div>

            <div nz-col [nzSpan]="24" text-center class="sub-heading-2">
                {{ 'select_seat_to_cancel_info' | translate }}
            </div>
        </div>
        <div nz-row top-16>
            <div nz-col [nzSpan]="24">
                <nz-radio-group [(ngModel)]="idGuest" class="reversed-radio">
                    <label nz-radio [nzValue]="null">{{ 'all' | translate }}</label>
                    <ng-container *ngFor="let bookedMember of bookingMembersGuests$ | async">
                        <span nz-radio [nzValue]="bookedMember.guest.id">{{ bookedMember.guest.name }} {{ bookedMember.guest.lastName }}</span>
                    </ng-container>
                </nz-radio-group>
            </div>
        </div>
        <div nz-row [nzGutter]="24" top-40>
            <div nz-col [nzSpan]="12">
                <button nz-button nzBlock nzType="text" class="big sub-heading-2" (click)="close()">
                    {{ 'cancel' | translate }}
                </button>
            </div>

            <div nz-col [nzSpan]="12">
                <button nz-button nzBlock nzType="primary" (click)="confirm()" class="big" [disabled]="false">
                    {{ 'confirm' | translate }}
                </button>
            </div>
        </div>
    </ng-container>
</nz-modal>