import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { HttpService } from '@services/http.service';
import { EndpointParams } from '@models/utils/endpoint-params';

@Injectable({
    providedIn: 'root'
})
export class PlaceInfoService extends HttpService {
    private readonly defaultEndpointParams: EndpointParams = { app: 'booking', version: 2.1 };

    list(idSchedule: number): Observable<any> {
        return this.get(`bookings/schedules/${idSchedule}/places`, this.defaultEndpointParams);
    }

    listAnonimous(idSchedule: number, publicToken: string): Observable<any> {
        const params = new HttpParams({ fromString: `centerToken=${publicToken}` });

        return this.get(`bookings/schedules/${idSchedule}/places/center`, this.defaultEndpointParams, params);
    }
}
