import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(private notification: NzNotificationService, private translateService: TranslateService) { }

    showErrorMessage(error: string): void {
        this.translateService.get(['error']).subscribe(
            translates => {
                this.notification.error(
                    translates.error,
                    error,
                    { nzDuration: 5000 }
                );
            }
        );
    }

    showSuccessMessage(): void {
        this.translateService.get(['save_successful', 'success']).subscribe(
            translates => {
                this.notification.success(
                    translates.success,
                    translates.save_successful,
                    { nzDuration: 5000 }
                );
            }
        );
    }

    showRemoveSuccessMessage(): void {
        this.translateService.get(['remove_successful', 'success']).subscribe(
            translates => {
                this.notification.success(
                    translates.success,
                    translates.remove_successful,
                    { nzDuration: 5000 }
                );
            }
        );
    }

    showInfoMessage(message: string): void {
        this.notification.info('', message, { nzDuration: 5000 });
    }
}
