export class MemberBasic {
    id!: number;
    name!: string;
    lastName!: string;
    email!: string;
    photo!: string;
    language!: number;

    build(content: any): this {
        Object.assign(this, content);

        return this;
    }

    getName(): string {
        return `${this.name} ${this.lastName}`;
    }
}
