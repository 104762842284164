<nz-modal [(nzVisible)]="showModal" [nzWidth]="'620px'" [nzFooter]="null" [nzClosable]="false" [nzKeyboard]="false" [nzMaskClosable]="false" nzCentered [nzClassName]="'resume-modal schedule-info-modal'">
    <ng-container *nzModalContent>
        <div nz-row [nzGutter]="[24, 32]">
            <ng-container *ngIf="bookingInfo.viewDetail">
                <div nz-col [nzSpan]="24" text-center class="heading-4">
                    {{ bookingInfo.details?.activityName }}
                </div>
            </ng-container>
        </div>
        <div nz-row [nzGutter]="[0, 32]" [nzJustify]="'center'" top-32>
            <div nz-col [nzSpan]="8" class="booking-icon-with-text">
                <span nz-icon nzType="calendar" nzTheme="outline" class="secondary-icon-48"></span> <div class="common-2">{{ bookingInfo.dateProgram | date:dateFormat }}</div>
            </div>
            <div nz-col [nzSpan]="8" class="booking-icon-with-text">
                <span nz-icon nzType="clock-circle" nzTheme="outline" class="secondary-icon-48"></span> <div class="common-2">{{ bookingInfo.timeStart }}</div>
            </div>
            <div nz-col [nzSpan]="8" class="booking-icon-with-text">
                <span nz-icon nzType="field-time" nzTheme="outline" class="secondary-icon-48"></span> <div class="common-2">{{ bookingInfo.duration }} min.</div>
            </div>
            <div nz-col [nzSpan]="8" class="booking-icon-with-text">
                <span nz-icon nzType="environment" nzTheme="outline" class="secondary-icon-48"></span> <div class="common-2">{{ bookingInfo.room }}</div>
            </div>
            <div nz-col [nzSpan]="8" class="booking-icon-with-text">
                <nz-avatar nzSize="large" [nzSrc]="bookingInfo.details?.staff?.urlPhoto"></nz-avatar> <div class="common-2" style="margin-top: 8px;">{{ bookingInfo.details?.staff?.name }} {{ bookingInfo.details?.staff?.lastName }}</div>
            </div>            
            <ng-container *ngIf="positionName">
                <div nz-col [nzSpan]="8" class="booking-icon-with-text">
                    <span nz-icon nzType="carry-out" nzTheme="outline" class="secondary-icon-48"></span> <div class="common-2">{{ ('seat_n' | translate) + ': ' + positionName }}</div>
                </div>
            </ng-container>
        </div>
        <div nz-row top-32>
            <div nz-col [nzSpan]="24">
                <nz-card class="card-3">
                    <div nz-row [nzGutter]="[0, 16]">
                        <div nz-col [nzSpan]="24" class="common-body-label" text-center>
                            {{ 'access_with_x_minutes' | translate: { x: bookingInfo.amountMinutesToAccess } }}
                        </div>
                        <div nz-col [nzSpan]="24" class="common-caption-1" text-center>
                            {{ 'booking_can_cancel' | translate: { hour: bookingInfo.maxDateToCancel | date:hourFormat, day: bookingInfo.maxDateToCancel | date:dateFormat } }}
                        </div>
                    </div>
                </nz-card>
            </div>
        </div>

        <ng-container *ngIf="!schedule.bookingInfo.isNumberedSeat">
            <div nz-row top-32 *ngIf="bookingPlace">
                <div nz-col [nzSpan]="24" text-center class="sub-heading-2">
                    {{ ('assistant' | translate) + '(' + bookingPlace.length + ')' }}
                </div>
                <div nz-col [nzSpan]="24" top-16 class="public_profile_container">
                    <ng-container *ngFor="let profile of bookingPlace">
                        <div class="public_avatar">
                            <app-public-profile-avatar [profile]="profile" [bookingPlace]="bookingPlace"></app-public-profile-avatar>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="schedule.bookingInfo.isNumberedSeat">
            <div nz-row top-32>
                <div nz-col [nzSpan]="24" text-center class="heading-4">
                    {{ 'select_a_empty_seat' | translate }}
                </div>

                <ng-container *ngIf="!isMap">
                    <div nz-col [nzSpan]="24">
                        <nz-spin class="nopadding-spin" [nzIndicator]="indicatorTemplate" [nzSpinning]="isLoadingGrid">                    
                            <div nz-row top-40>
                                <div nz-col [nzXl]="{ span: 18, offset: 3 }" [nzSm]="{ span: 18, offset: 3 }" [nzXs]="{ span: 22, offset: 1 }">
                                    <div class="seats-row">
                                        <ng-container *ngFor="let line of grid">
                                            <ng-container *ngFor="let seat of line.seats">
                                                <app-public-profile-avatar *ngIf="seat.isBooked || seat.isBookedForMe" [profile]="seat.memberPublicProfile" [bookingPlace]="bookingPlace"></app-public-profile-avatar>
                                                <button nz-button nzType="default" class="seat-button" *ngIf="!seat.isBooked && !seat.isBookedForMe"
                                                    [ngClass]="{
                                                        'your': seat.isBookedForMe,
                                                        'selected': seat.isChecked,
                                                        'free': !seat.isBooked && !seat.isChecked,
                                                        'unavailable': seat.unavailable
                                                    }" (click)="checkSeat(seat, !seat.isChecked)">
                                                    {{ seat.bookedSeat | number:'2.0-0' }}
                                                </button>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                            <div nz-row nzJustify="center" top-16>
                                <div nz-col [nzSpan]="23" class="flex justify gap-8">
                                    <div class="caption-data">
                                        <div class="circle free"></div>
                                        <span class="label-seat">{{ 'vacant' | translate }}</span>
                                    </div>
                                    <div class="caption-data">
                                        <div class="circle unavailable"></div>
                                        <span class="label-seat">{{ 'occupied' | translate }}</span>
                                    </div>
                                    <div class="caption-data">
                                        <div class="circle selected"></div>
                                        <span class="label-seat">{{ 'selected' | translate }}</span>
                                    </div>
                                    <div class="caption-data">
                                        <div class="circle your"></div>
                                        <span class="label-seat">{{ 'your_seat' | translate }}</span>
                                    </div>
                                </div>
                            </div>
                        </nz-spin>
                    </div>
                </ng-container>

                <ng-container *ngIf="isMap && roomMapDetail">
                    <div nz-col [nzSpan]="24" style="overflow: auto;">
                        <div class="map-grid">
                            <div class="map-body">
                                <ng-container *ngFor="let row of roomMapDetail.grid; let i=index">
                                    <ng-container *ngFor="let cell of row; let j=index">
                                        <div class="cell"></div>
                                    </ng-container>                        
                                </ng-container>
                                <div style="display: block; position: absolute;">
                                    <ng-container *ngFor="let position of roomMapDetail.positions">
                                        <div [ngStyle]="{ 'position': 'absolute', 'top': (position.positionY * Y_SIZE) + PADDING + 'px', 'left': (position.positionX * X_SIZE) + PADDING + 'px'  }">
                                            <app-public-profile-avatar *ngIf="position.state === bookingPlaceState.booked || position.state === bookingPlaceState.bookedByGuest || position.state === bookingPlaceState.bookedForMe"
                                                [profile]="position.memberPublicProfile" [bookingPlace]="bookingPlace"></app-public-profile-avatar>
                                            <div *ngIf="position.state === bookingPlaceState.available" class="map-item"
                                                [ngClass]="{ 'is-disabled': !position.isEnabled,
                                                'free': (position.state === bookingPlaceState.available && position.isEnabled),
                                                'selected': position.isSelected}" 
                                                (click)="selectSeat(position)">
                                                <ng-container *ngIf="!(position.state === bookingPlaceState.available && position.isEnabled)">
                                                    <img alt="Position" [src]="'assets/icons/map/white/'+position.icon+'.svg'">
                                                </ng-container>
                                                <ng-container *ngIf="(position.state === bookingPlaceState.available && position.isEnabled)">
                                                    <img alt="Position" [src]="'assets/icons/map/black/'+position.icon+'.svg'">
                                                </ng-container>
                                            </div>
                                            
                                            <div class="map-text" [ngClass]="{ 'is-disabled': !position.isEnabled, 'booked': (position.state === bookingPlaceState.booked || position.state === bookingPlaceState.bookedByGuest),
                                                'free': (position.state === bookingPlaceState.available && position.isEnabled), 'selected': position.isSelected }">{{ position.name }}</div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngFor="let item of roomMapDetail.items">
                                        <div [ngStyle]="{ 'position': 'absolute', 'top': (item.positionY * Y_SIZE) + PADDING + 'px', 'left': (item.positionX * X_SIZE) + PADDING + 'px'  }">
                                            <div class="map-item is-disabled">
                                                <img alt="Position" [src]="'assets/icons/map/white/'+item.icon+'.svg'">
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div nz-row nzJustify="center" top-16 style="width: 100%;">
                        <div nz-col [nzSpan]="23" class="flex justify gap-8">
                            <div class="caption-data">
                                <div class="circle free"></div>
                                <span class="label-seat">{{ 'vacant' | translate }}</span>
                            </div>
                            <div class="caption-data">
                                <div class="circle unavailable"></div>
                                <span class="label-seat">{{ 'occupied' | translate }}</span>
                            </div>
                            <div class="caption-data">
                                <div class="circle selected"></div>
                                <span class="label-seat">{{ 'selected' | translate }}</span>
                            </div>
                            <div class="caption-data">
                                <div class="circle your"></div>
                                <span class="label-seat">{{ 'your_seat' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="schedule.bookingState == bookingStates.multipleBookedAvailable && !schedule.bookingInfo.isNumberedSeat">
            <div nz-row top-16>
                <button nz-button nzType="dashed" nzBlock class="special-btn" [nzLoading]="isLoading" (click)="confirmBook()">
                    <span nz-icon nzType="plus-circle" nzTheme="outline"></span>{{ 'booking_additional_seat' | translate }}
                </button>
            </div>
        </ng-container>

        <div nz-row [nzGutter]="24" top-32>
            <div nz-col [nzSpan]="12">
                <button nz-button nzBlock nzType="text" class="big sub-heading-2" (click)="close()">{{ 'cancel' | translate }}</button>
            </div>

            <div nz-col [nzSpan]="12">
                <ng-container *ngIf="schedule.bookingState == bookingStates.canBook">
                    <button nz-button nzBlock nzType="primary" class="big" 
                            [nzLoading]="isLoading" 
                            [disabled]="schedule.bookingInfo.isNumberedSeat && (!selectedSeat && !selectedPosition)" 
                            (click)="confirmBook()">
                        <span *ngIf="!schedule.bookingInfo.isNumberedSeat || selectedSeat || selectedPosition">
                            <span *ngIf="!isPremiumBooking">
                            {{ 'book' | translate }}
                            </span>
                            <span *ngIf="isPremiumBooking">
                            {{ 'book_premium' | translate:{ credits: schedule.bookingInfo.amountCredits } }}
                            </span>
                        </span>
                        <span *ngIf="schedule.bookingInfo.isNumberedSeat && (!selectedSeat && !selectedPosition)">
                            {{ 'select_a_seat' | translate }}
                        </span>
                    </button>
                </ng-container>

                <ng-container *ngIf="schedule.bookingState == bookingStates.multipleBookedAvailable && schedule.bookingInfo.isNumberedSeat">
                    <ng-container *ngIf="!schedule.bookingInfo.isNumberedSeat || selectedSeat || selectedPosition">
                        <button nz-button nzBlock nzType="primary" class="big" 
                            [nzLoading]="isLoading" 
                            [disabled]="schedule.bookingInfo.isNumberedSeat && (!selectedSeat && !selectedPosition)" 
                            (click)="confirmBook()">                            
                            <span *ngIf="!isPremiumBooking">
                                {{ 'book' | translate }}
                            </span>
                            <span *ngIf="isPremiumBooking">
                                {{ 'book_premium' | translate:{ credits: schedule.bookingInfo.amountCredits } }}
                            </span>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="schedule.bookingInfo.isNumberedSeat && (!selectedSeat && !selectedPosition)">
                        <button nz-button nzBlock nzType="primary" class="big" (click)="cancelMultiple(schedule.id)" [nzLoading]="isLoading">
                            {{ 'cancel_book' | translate }}
                        </button>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="schedule.bookingState == bookingStates.multipleBookedAvailable && !schedule.bookingInfo.isNumberedSeat">
                    <button nz-button nzBlock nzType="primary" class="big" (click)="cancelMultiple(schedule.id)" [nzLoading]="isLoading">
                        {{ 'cancel_book' | translate }}
                    </button>
                </ng-container>

                <ng-container *ngIf="schedule.bookingState == bookingStates.booked">
                    <button nz-button nzBlock nzType="primary" class="big" nz-popconfirm [nzPopconfirmTitle]="'are_you_sure_cancel_book' | translate"
                        (nzOnConfirm)="cancel(schedule.id)" [nzLoading]="isLoading">
                        {{ 'cancel_book' | translate }}
                    </button>
                </ng-container>

                <ng-container *ngIf="schedule.bookingState == bookingStates.bookedMultipleTimes">
                    <button nz-button nzBlock nzType="primary" class="big" (click)="cancelMultiple(schedule.id)" [nzLoading]="isLoading">
                        {{ 'cancel_book' | translate }}
                    </button>
                </ng-container>
            </div>
        </div>
    </ng-container>
</nz-modal>

<ng-template #indicatorTemplate><i nz-icon nzType="loading" [nzTheme]="'outline'"
    style="font-size: 24px; display: block;"></i>{{ 'loading' | translate }}...
</ng-template>