import { SignupFieldVisibility } from '@enumerates/setting/signup-field-visibility';
import { SignupSetting } from '@models/center/info/setting/signup-setting';
import { ProfilePhone } from './profile-phone';
import { WorldLocation } from './location/world-location';

export class Profile {
    id!: number;
    publicName!: string;
    password!: string;
    name!: string;
    lastName!: string;
    birthday!: Date | string;
    nif!: string;
    gender!: number;
    email!: string;
    mobile!: ProfilePhone | null;
    mobileString!: string;
    photo!: string | null;
    photoUrl!: string;
    photoProfile!: string;
    photoProfileUrl!: string;
    wantPush!: boolean;
    wantEmail!: boolean;
    wantPools!: boolean;
    address!: string;
    birthday_date!: number;
    birthday_month!: number;
    birthday_year!: number;
    worldLocation!: WorldLocation;
    isVisiblePublicProfile!: boolean;
    instagram!: string;
    tiktok!: string;
    isAceptedBiometricLOPD!: boolean;
    observations!: string;

    build(content: any): this {
        Object.assign(this, content);

        const birthday = new Date(this.birthday);
        this.birthday_date = birthday.getDate();
        this.birthday_month = birthday.getMonth() + 1;
        this.birthday_year = birthday.getFullYear();

        if (this.mobile) {
            this.mobileString = `${this.mobile.phoneCode}${this.mobile.mobile}`;
        }

        return this;
    }

    buildFromPaymentProcess(profile: Profile, signUpSetting: SignupSetting, content: any): this {
        this.name = profile.name;
        this.lastName = profile.lastName;
        this.birthday = `${content.birthday_year}-${content.birthday_month}-${content.birthday_date}`;
        this.wantEmail = profile.wantEmail;
        this.wantPools = profile.wantPools;
        this.wantPush = profile.wantPush;
        this.publicName = profile.publicName;
        if (signUpSetting.fieldSignupDNI === SignupFieldVisibility.hidden) {
            this.nif = profile.nif;
        } else {
            this.nif = content.nif;
        }

        this.gender = content.gender;
        this.email = profile.email;
        this.observations = content.observations;
        if (signUpSetting.fieldSignupMobile === SignupFieldVisibility.hidden) {
            this.mobile = profile.mobile;
        } else {
            this.mobile = content.mobile ? {
                phoneCode: content.phoneCode,
                mobile: content.mobile
            } : null;
        }

        if (signUpSetting.fieldSignupAddress === SignupFieldVisibility.hidden) {
            this.address = profile.address;
            this.worldLocation = {
                id: profile.worldLocation.id,
                city: '',
                codPostal: '',
                country: '',
                countryCode: '',
                region: ''
            };
        } else {
            this.address = content.address;
            this.worldLocation = {
                id: content.idLocalization,
                city: '',
                codPostal: '',
                country: '',
                countryCode: '',
                region: ''
            };
        }

        return this;
    }
}
