export class BookingSetting {
    amountMinutesFromActivityWaitQueue!: number;
    isActiveWaitQueueNotification!: boolean;
    isActiveWaitQueueAutomatic!: boolean;
    isHideCapacityAndAvailabilityAnonymous!: boolean;
    amountMinutesOfCancelBookings!: number;

    build(content: any): BookingSetting {
        Object.assign(this, content);

        return this;
    }
}
