export class Internationalization {
    codeLanguage!: string;
    locale!: string;
    firstDayOfWeek!: number;
    lastDayOfWeek!: number;
    dateFormat!: string;
    hourFormat!: string;

    build(content: any): this {
        Object.assign(this, content);

        return this;
    }
}
