import { CenterLogin } from './center-login';

export class CenterBasic implements CenterLogin {
    public id!: number;
    public name!: string;
    public publicToken!: string;

    build(content: any): this {
        Object.assign(this, content);

        return this;
    }
}
