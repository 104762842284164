import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { HttpService } from '@services/http.service';
import { BookingMemberGuest } from '@views/booking/models/booking-member/booking-member-guest';
import { EndpointParams } from '@models/utils/endpoint-params';

@Injectable({
    providedIn: 'root'
})
export class BookingMemberGuestService extends HttpService {
    private readonly defaultEndpointParams: EndpointParams = { app: 'booking', version: 2.2 };

    private dataStore: { guests: BookingMemberGuest[] } = { guests: [] };

    private readonly dataSubject = new BehaviorSubject<BookingMemberGuest[]>(this.dataStore.guests);
    private readonly loadingSubject = new BehaviorSubject<boolean>(false);

    readonly bookingMembersGuests = this.dataSubject.asObservable();
    readonly loading = this.loadingSubject.asObservable();

    list(idSchedule: number): void {
        this.setLoading(true);
        this.listGuests(idSchedule)
            .pipe(finalize(() => this.setLoading(false)))
            .subscribe(() => this.next());
    }

    private listGuests(idSchedule: number): Observable<BookingMemberGuest[]> {
        return this.get(`bookings/members/me/schedule/${idSchedule}`, this.defaultEndpointParams).pipe(
            tap(data => this.dataStore.guests = data.filter(c => c.isBookedByGuest))
        );
    }

    private next(): void {
        this.dataSubject.next([...this.dataStore.guests]);
    }

    private setLoading(value: boolean): void {
        this.loadingSubject.next(value);
    }
}
