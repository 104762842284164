import { Component, inject, OnDestroy } from '@angular/core';
import { StringDto } from '@models/basic/string-dto';

import { Modal } from '@models/index';
import { PublicProfile } from '@models/public-profile/public-profile';
import { ModalBuilderService } from '@services/index';
import { InternationalizationService } from '@services/utils/internationalization/internationalization.service';
import { BookingPlaceState } from '@views/booking/enumerates/booking-place-state';
import { BookingState } from '@views/booking/enumerates/booking-state';
import { BookingInfo } from '@views/booking/models/booking-info/booking-info';
import { BookingPlaceInfo } from '@views/booking/models/booking-place-info/booking-place-info';
import { ScheduleInfoBasic } from '@views/booking/models/calendar-full/schedule/schedule-info-basic';
import { RoomFullMap } from '@views/booking/models/map/room-full-map';
import { Seat } from '@views/booking/models/seats/seat';
import { SeatRow } from '@views/booking/models/seats/seat-row';
import { BookingService } from '@views/booking/services/booking/booking.service';
import { PlaceInfoService } from '@views/booking/services/place-info/place-info.service';
import { takeWhile } from 'rxjs/operators';
import { MapElementMachine } from '@views/booking/models/map/machine/map-element-machine';
import { CategoryMachine } from '@views/booking/enumerates/machines/category-machine';
import { Subject } from 'rxjs';
import { GuestsComponent } from '../../guests/guests.component';
import { CancelBooksComponent } from '../../cancel-books/cancel-books.component';

@Component({
    selector: 'app-schedule-info',
    templateUrl: './schedule-info.component.html'
})
export class ScheduleInfoComponent extends Modal implements OnDestroy {
    private readonly internationalizationService = inject(InternationalizationService);
    private readonly placeInfoService = inject(PlaceInfoService);
    private readonly bookingService = inject(BookingService);
    private readonly modalBuilderService = inject(ModalBuilderService);

    private isAlive = true;

    idGuest: string | null = null;
    bookingStates = BookingState;
    showModal = false;
    isLoading = false;
    isLoadingGrid = false;
    grid: SeatRow[] = [];
    selectedSeat!: Seat | undefined;
    selectedPosition?: string;
    selectedPositionName?: string;
    isPremiumBooking = false;
    positionName!: string;

    schedule!: ScheduleInfoBasic;
    bookingInfo!: BookingInfo;
    bookingPlace: PublicProfile[] = [];
    isMap = false;
    roomMapDetail!: RoomFullMap | null;

    readonly dateFormat = this.internationalizationService.getDateFormat();
    readonly hourFormat = this.internationalizationService.getHourFormat();
    readonly bookingPlaceState = BookingPlaceState;
    readonly X_SIZE = 44;
    readonly Y_SIZE = 76;
    readonly PADDING = 8;
    readonly ROWS = 5;

    private destroy$ = new Subject<boolean>();

    ngOnDestroy(): void {
        this.isAlive = false;
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    onInjectInputs(inputs?: any): void {
        this.schedule = inputs.schedule;
        this.bookingInfo = inputs.bookingInfo;
        this.loadPlaces();

        this.bookingService.isLoading.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
            this.isLoading = isLoading;
        });

        this.bookingService.reload.pipe(takeWhile(() => this.isAlive)).subscribe(
            reload => {
                if (reload) {
                    this.showModal = false;
                    this.saving();
                }
            }
        );
        this.isPremiumBooking = this.schedule.bookingInfo.isPayment && !this.schedule.isAvailableInMyFee &&
            this.schedule.bookingInfo.amountCredits != null && this.schedule.bookingInfo.amountCredits > 0;
        this.showModal = true;
    }

    checkSeat(seat: Seat, check: boolean): void {
        if (!this.schedule || seat.isBooked || seat.isBookedForMe ||  seat.unavailable
            || (this.schedule.bookingState !== BookingState.canBook && this.schedule.bookingState !== BookingState.multipleBookedAvailable)) {
            return;
        }

        this.grid.forEach(row => row.seats.forEach(s => s.isChecked = false));

        if (check) {
            this.selectedSeat = this.grid[Math.floor((seat.bookedSeat - 1) / this.schedule.bookingInfo.seatByRow)]
                .seats.find(a => a.bookedSeat === seat.bookedSeat);
            if (this.selectedSeat) {
                this.selectedSeat.isChecked = true;
            }
        } else {
            this.selectedSeat = undefined;
        }
    }

    confirmBook(): void {
        if (this.schedule.bookingState === BookingState.multipleBookedAvailable) {
            let seat = this.selectedPositionName;
            if (this.selectedSeat?.isChecked) {
                seat = this.selectedSeat.tag;
            }

            const modalUtil = this.modalBuilderService.open(GuestsComponent, { seat, bookingPlace: this.bookingPlace }, this.destroy$);
            modalUtil.onResult.subscribe(
                idGuest => {
                    this.idGuest = idGuest;
                    this.checkBook();
                }
            );

            return;
        }

        this.checkBook();
    }

    cancel(idSchedule: number): void {
        this.isLoading = true;
        let guest: StringDto | null = null;
        if (this.idGuest) {
            guest = new StringDto().build({ value: this.idGuest });
        }
        this.bookingService.cancel(idSchedule, guest).subscribe(() => {
            this.bookingService.isLoading.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
                this.isLoading = isLoading;
            });
        });
    }

    cancelMultiple(idSchedule: number): void {
        const modalUtil = this.modalBuilderService.open(CancelBooksComponent, { idSchedule }, this.destroy$);
        modalUtil.onResult.subscribe(
            idGuest => {
                this.idGuest = idGuest;
                this.cancel(this.schedule.id);
            }
        );
    }

    close(): void {
        this.showModal = false;
        this.closing();
    }

    checkBook(): void {
        this.book(this.schedule.id);
    }

    selectSeat(element: MapElementMachine): void {
        if (element.isEnabled && element.state === BookingPlaceState.available
            && (this.schedule.bookingState === BookingState.canBook || this.schedule.bookingState === BookingState.multipleBookedAvailable)) {
            if (element.isSelected) {
                element.isSelected = false;
                this.selectedPosition = undefined;
                this.selectedPositionName = undefined;
            } else {
                if (this.roomMapDetail) {
                    this.roomMapDetail.positions.forEach(position => position.isSelected = false);
                }
                element.isSelected = true;
                this.selectedPosition = element.id;
                this.selectedPositionName = element.name;
            }
        }
    }

    private book(idSchedule: number): void {
        this.isLoading = true;

        let bookedSeat = 0;
        if (this.schedule && this.schedule.bookingInfo.isNumberedSeat && this.selectedSeat) {
            bookedSeat = this.selectedSeat.bookedSeat;
        }

        this.bookingService.book(idSchedule, bookedSeat, this.selectedPosition, undefined, this.idGuest).subscribe();
    }

    private loadPlaces(bookedSeat?: string | number | undefined): void {
        this.isLoadingGrid = true;
        this.grid = [];

        this.placeInfoService.list(this.schedule.id).subscribe(
            info => {
                if (info.bookingPlace) {
                    this.isLoadingGrid = false;

                    return this.loadAssitants(info.bookingPlace);
                }

                document.documentElement.style.setProperty('--number-columns', `${this.schedule.bookingInfo.seatByRow}`);
                this.bookingPlace = [];
                if (info.bookingPlaceNumbered) {
                    info.bookingPlaceNumbered.forEach((row: any) => {
                        const seatRow = new SeatRow();
                        row.forEach((place: BookingPlaceInfo) => {
                            seatRow.seats.push(
                                {
                                    tag: place.tag,
                                    bookedSeat: place.seat,
                                    isBooked: place.state === BookingPlaceState.booked || place.state === BookingPlaceState.bookedByGuest && place.memberPublicProfile.isUserLogin,
                                    isBookedForMe: place.state === BookingPlaceState.bookedForMe,
                                    isChecked: false,
                                    memberPublicProfile: place.memberPublicProfile,
                                    unavailable: place.state === BookingPlaceState.bookedByGuest && !place.memberPublicProfile.isUserLogin
                                }
                            );

                            if (place.state === BookingPlaceState.booked || place.state === BookingPlaceState.bookedByGuest) {
                                this.bookingPlace.push(place.memberPublicProfile);
                            }

                            if (place.state === BookingPlaceState.bookedForMe) {
                                this.bookingPlace.push(place.memberPublicProfile);
                                this.positionName = `${place.seat}`;
                            }
                        });

                        this.grid.push(seatRow);
                    });

                    if (bookedSeat) {
                        let seat: Seat | undefined;
                        for (const row of this.grid) {
                            seat = row.seats.find(c => c.bookedSeat === bookedSeat);

                            if (seat) {
                                break;
                            }
                        }

                        if (seat) {
                            this.checkSeat(seat, true);
                        }
                    }
                } else {
                    this.roomMapDetail = { ...info.bookingPlaceMap };
                    this.roomMapDetail?.positions.forEach(position => {
                        if (position.state === BookingPlaceState.booked || position.state === BookingPlaceState.bookedByGuest) {
                            this.bookingPlace.push(position.memberPublicProfile);
                        }

                        if (position.state === BookingPlaceState.bookedForMe) {
                            this.bookingPlace.push(position.memberPublicProfile);
                            this.positionName = `${position.name}`;
                        }
                    });
                    document.documentElement.style.setProperty('--map-x-size', `${this.X_SIZE}px`);
                    document.documentElement.style.setProperty('--map-y-size', `${this.Y_SIZE}px`);
                    document.documentElement.style.setProperty('--map-padding', `${this.PADDING}px`);
                    document.documentElement.style.setProperty('--map-max-height', `${this.Y_SIZE * this.ROWS}px`);
                    this.buildMap();
                    this.isMap = true;
                }

                this.isLoadingGrid = false;
            }
        );
    }

    private buildMap(): void {
        if (!this.roomMapDetail) {
            return;
        }

        const cellMap = [];

        this.roomMapDetail.positions = [...this.roomMapDetail.positions.map(
            position => ({
                ...position,
                icon: position.idCategoryMachine ? CategoryMachine[position.idCategoryMachine] : 'other'
            })
        )];

        this.roomMapDetail.items = [...this.roomMapDetail.items.map(
            item => ({
                ...item,
                icon: 'staff'
            })
        )];

        let h = 0;
        if (this.roomMapDetail.map.numberRows && this.roomMapDetail.map.numberColumns) {
            document.documentElement.style.setProperty('--number-columns-map', `${this.roomMapDetail.map.numberColumns}`);
            for (let i = 0; i < this.roomMapDetail.map.numberRows; i++) {
                const elements = [];
                for (let j = 0; j < this.roomMapDetail.map.numberColumns; j++) {
                    elements.push(h);
                    h++;
                }
                cellMap.push(elements);
            }
        }

        this.roomMapDetail = {
            ...this.roomMapDetail,
            grid: cellMap
        };
    }

    private loadAssitants(bookingPlaces: any): void {
        this.bookingPlace = bookingPlaces;
        this.positionName = '';
    }
}

