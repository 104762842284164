<nz-modal [nzTitle]="'public_profile' | translate" [(nzVisible)]="isVisiblePublicProfile" [nzWidth]="'520px'" [nzFooter]="null" [nzClosable]="true" nzCentered (nzAfterOpen)="reScaleAvatar()" (nzOnCancel)="close()">
    <ng-container *nzModalContent>
        <div nz-row *ngIf="publicProfile">
            <div nz-col nzSpan="24" class="principal_profile">
                <div nz-row nzJustify="center" bottom-16>
                    <div nz-col>
                        <span class="public_name" family-bold>{{ publicProfile.publicName }}</span>
                    </div>
                </div>
                <div nz-row nzJustify="center" bottom-16>
                    <div nz-col>
                        <nz-avatar [nzSize]="200" nzSrc="assets/imgs/nofoto.svg" *ngIf="publicProfile.publicName == null"></nz-avatar>
                        <nz-avatar [nzSize]="200" #avatarComponent [nzText]="publicProfile.publicName | slice: 0 : 2" *ngIf="publicProfile.publicName != null && publicProfile?.avatar?.name == null"></nz-avatar>
                        <nz-avatar [nzSize]="200" [nzSrc]="publicProfile.avatar.url" *ngIf="publicProfile.publicName != null && publicProfile?.avatar?.name != null"></nz-avatar>
                    </div>
                </div>
                <div nz-row nzJustify="center" top-32  class="icons_profile" nzGutter="16">
                    <div nz-col *ngIf="publicProfile.instagram">
                        <a [href]="publicProfile.instagram" target="_blank">
                            <span nz-icon nzType="instagram" nzTheme="outline"></span>
                        </a>
                    </div>
                    <div nz-col *ngIf="publicProfile.tiktok">
                        <a [href]="publicProfile.tiktok" target="_blank">
                            <i nz-icon>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2859 3333" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd">
                                    <path d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z" />
                                </svg>
                            </i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        
        <div nz-row top-32>
            <div nz-col [nzSpan]="24" text-center class="sub-heading-2">
                {{ ('assistant' | translate) + '(' + bookingPlace?.length + ')' }}
            </div>
            <div nz-col [nzSpan]="24" top-16 class="public_profile_container">
                <ng-container *ngFor="let profile of bookingPlace">
                    <div (click)="showPublicProfile(profile)" class="public_avatar" [ngClass]="{'info' : profile.publicName != null}">
                        <svg class="no-foto" [ngClass]="{ 'avatar-book' : profile.isUserLogin && profile.guest == null }" width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="(profile.publicName == null || profile.publicName == '') && (!profile.isUserLogin || (profile.isUserLogin && profile.guest == null))">
                            <g clip-path="url(#clip0_2662_2959)">
                                <rect class="no-foto-background" x="0.599609" width="40" height="40" rx="20" fill="#FFF2E6"/>
                                <rect class="no-foto-circle" x="12.5996" y="6" width="16" height="16" rx="8" fill="#FF6319"/>
                                <rect class="no-foto-circle" x="-9.40039" y="27" width="60" height="60" rx="30" fill="#FF6319"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_2662_2959">
                                    <rect x="0.599609" width="40" height="40" rx="20" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <nz-avatar nzSize="large" [ngClass]="{ 'my-avatar-book' : profile.isUserLogin && profile.guest == null }" #avatarComponent [nzText]="(profile.publicName | slice: 0 : 2) | uppercase" *ngIf="profile.publicName != null && profile.publicName != '' && profile?.avatar?.name == null && profile.guest == null"></nz-avatar>
                        <nz-avatar nzSize="large" [ngClass]="{ 'my-avatar-border' : profile.isUserLogin && profile.guest == null }" [nzSrc]="profile.avatar.url" *ngIf="profile.publicName != null && profile.publicName != '' && profile?.avatar?.name != null && profile.guest == null"></nz-avatar>
                        <nz-avatar nzSize="large" [ngClass]="{ 'my-avatar-guest' : profile.isUserLogin && profile.guest != null }" #avatarComponent [nzText]="(profile.guest.name | slice: 0 : 1) + '' + (profile.guest.lastName | slice: 0 : 1)" *ngIf="(profile.publicName != null && profile.publicName != '' && profile.guest != null) || (profile.isUserLogin && profile.guest != null)"></nz-avatar>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</nz-modal>