import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { ca, de, enUS, es, eu, fr, it, pt, ptBR } from 'date-fns/locale';
import {
    NZ_I18N, es_ES, en_US, de_DE, fr_FR, pt_PT, ca_ES, gl_ES,
    pt_BR, /* eu_ES,*/ it_IT, /* gl_ES, en_AU,*/
    NZ_DATE_CONFIG,
    NZ_DATE_LOCALE
} from 'ng-zorro-antd/i18n';

import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { NotFoundComponent } from '@views/generic/notfound/notfound.component';
import { BadRequestComponent } from '@views/generic/bad-request/bad-request.component';

import { CookieService } from 'ngx-cookie-service';

import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import { environment } from '@environments/environment';

import { InternationalizationService } from '@services/utils/internationalization/internationalization.service';
import { WINDOW_PROVIDERS } from '@services/utils/window/window.provider';
import { AuthComponent } from '@views/auth/auth.component';
import { StoreModule } from '@ngrx/store';
import { TokenComponent } from './components/token/token.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { AuthInterceptor } from './core/auth-interceptor';
import { IconsProviderModule } from './icons-provider.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ModalBuilderService } from './services';

/**
 * TODO: Cuando se acepten los idiomas de euskera y gallego, volver a versión anterior
 */
export function getLanguage(internationalizationService: InternationalizationService): string {
    let language: string = internationalizationService.getLanguage();
    if (language.toLowerCase() === 'eu_es') {
        language = 'es_ES';
    }

    return language;
}

export function getFirstDayOfWeek(internationalizationService: InternationalizationService): any {
    return { firstDayOfWeek: internationalizationService.getFirstDayOfWeek() };
}

/**
 * TODO: Modificar Euskera, Español de México, Español de Argentina y Australiano cuando Zorro los acepte
 */
export function getLocale(internationalizationService: InternationalizationService): any {
    let language: any;
    switch (internationalizationService.getLanguage().toLowerCase()) {
    case 'es_es':
        language = es_ES;
        break;
    case 'en_us':
        language = en_US;
        break;
    case 'de_de':
        language = de_DE;
        break;
    case 'fr_fr':
        language = fr_FR;
        break;
    case 'pt_pt':
        language = pt_PT;
        break;
    case 'ca_es':
        language = ca_ES;
        break;
    case 'pt_br':
        language = pt_BR;
        break;
    case 'eu_es':
        language = es_ES;
        break;
    case 'it_it':
        language = it_IT;
        break;
    case 'gl_es':
        language = gl_ES;
        break;
    case 'en_au':
        language = en_US;
        break;
    case 'es_mx':
        language = es_ES;
        break;
    case 'es_ar':
        language = es_ES;
    }

    return language;
}

export function getDateFns(internationalizationService: InternationalizationService): Locale {
    let dateFnsLocale: Locale = es;
    switch (internationalizationService.getLanguage().toLowerCase()) {
    case 'es_es':
        dateFnsLocale = es;
        break;
    case 'en_us':
        dateFnsLocale = enUS;
        break;
    case 'de_de':
        dateFnsLocale = de;
        break;
    case 'fr_fr':
        dateFnsLocale = fr;
        break;
    case 'pt_pt':
        dateFnsLocale = pt;
        break;
    case 'ca_es':
        dateFnsLocale = ca;
        break;
    case 'pt_br':
        dateFnsLocale = ptBR;
        break;
    case 'eu_es':
        dateFnsLocale = eu;
        break;
    case 'it_it':
        dateFnsLocale = it;
        break;
    case 'gl_es':
        dateFnsLocale = es;
        break;
    case 'en_au':
        dateFnsLocale = enUS;
        break;
    case 'es_mx':
        dateFnsLocale = es;
        break;
    case 'es_ar':
        dateFnsLocale = es;
        break;
    }

    return dateFnsLocale;
}

@NgModule({
    declarations: [
        AppComponent,
        UnauthorizedComponent,
        TokenComponent,
        NotFoundComponent,
        BadRequestComponent,
        AuthComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        IconsProviderModule,
        CoreModule.forRoot(),
        RecaptchaV3Module,
        RecaptchaModule,
        RecaptchaFormsModule,
        SharedModule,
        StoreModule.forRoot({}, {}),
    ],
    providers: [
        {
            provide: NZ_I18N,
            deps: [InternationalizationService],
            useFactory: getLocale
        },
        {
            provide: LOCALE_ID,
            deps: [InternationalizationService],
            useFactory: getLanguage
        },
        {
            provide: NZ_DATE_CONFIG,
            deps: [InternationalizationService],
            useFactory: getFirstDayOfWeek
        },
        {
            provide: NZ_DATE_LOCALE,
            deps: [InternationalizationService],
            useFactory: getDateFns
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaKey },
        { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: environment.recaptchaKeyV2, size: 'invisible' } as RecaptchaSettings },
        CookieService,
        ModalBuilderService,
        WINDOW_PROVIDERS
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(internationalizationService: InternationalizationService) {
        internationalizationService.importLocale();
    }
}
