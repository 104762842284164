// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apps: {
        member: 'https://tg-member-dev-sandbox.azurewebsites.net/api',
        booking: 'https://tg-booking-dev-sandbox.azurewebsites.net/api',
        center: 'https://tg-center-dev-sandbox.azurewebsites.net/api',
        utilities: 'https://tg-utilities-dev-sandbox.azurewebsites.net/api',
        polaris: 'https://tg-polaris-dev-sandbox.azurewebsites.net',
        realtime: 'https://tg-realtime-dev-sandbox.azurewebsites.net',
        payments: 'https://tg-payments-dev-sandbox.azurewebsites.net/api',
        notification: 'https://tg-notification-dev-sandbox.azurewebsites.net/api',
        manager: 'https://tg-manager-v3-dev-sandbox.azurewebsites.net'
    },
    hubs: {
        booking: 'bookinghub'
    },
    recaptchaKey: '6Ldgv6wUAAAAALneupa_TOPWPa2cSH3SDhPaO6d0',
    recaptchaKeyV2: '6Le-N-4ZAAAAAOTO4dO0Ab8lDyjluVKimQ2mym4T',
    stripeKey: 'pk_test_gVWVpawz61IlkiaHlLEYKMXV',
    idCookiebot: 'f6f59944-0599-46e6-8a51-32ca406d5901',
    secureacceptance: 'https://testsecureacceptance.cybersource.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
