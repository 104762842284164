import { createAction, props } from '@ngrx/store';
import { PaymentProcessStep } from '../enumerate/payment-process-step';

export const setCurrentStep = createAction(
    '[Payment Process] set current step',
    props<{
        step: PaymentProcessStep;
    }>()
);

export const setCleanData = createAction(
    '[Payment Process] set clean data',
    props<{
        cleanData: boolean;
    }>()
);
