import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { HttpService } from '@services/http.service';
import { BookingWaitQueueType } from '@views/booking/enumerates/booking-wait-queue-type';
import { ScheduleInfoBasic } from '@views/booking/models/calendar-full/schedule/schedule-info-basic';
import { BookingValidation } from '@views/booking/models/booking-validation/booking-validation';
import { EndpointParams } from '@models/utils/endpoint-params';
import { StringDto } from '@models/basic/string-dto';

@Injectable({
    providedIn: 'root'
})
export class BookingService extends HttpService {
    private readonly defaultEndpointParams: EndpointParams = { app: 'booking', version: 2 };

    private reloadDataSubject = new BehaviorSubject<boolean>(false);
    private isLoadingSubject = new BehaviorSubject<boolean>(false);
    private isWaitQueueSubject = new BehaviorSubject<ScheduleInfoBasic|undefined>(undefined);

    readonly reload = this.reloadDataSubject.asObservable();
    readonly isLoading = this.isLoadingSubject.asObservable();
    readonly isWaitQueue = this.isWaitQueueSubject.asObservable();

    book(idSchedule: number, bookedSeat?: number, selectedPosition?: string, forceBuyWithCredits?: boolean, idGuest?: string | null): Observable<any> {
        this.setLoading(true);
        const bookActivity: { bookedSeat: number | null; idPosition: string | null; forceBuyWithCredits: boolean; idGuest: string | null }
            = { bookedSeat: null, idPosition: null, forceBuyWithCredits: false, idGuest: null };
        if (!selectedPosition && bookedSeat) {
            bookActivity.bookedSeat = bookedSeat;
        }

        if (selectedPosition) {
            bookActivity.idPosition = selectedPosition;
        }

        if (forceBuyWithCredits) {
            bookActivity.forceBuyWithCredits = forceBuyWithCredits;
        }

        if (idGuest) {
            bookActivity.idGuest = idGuest;
        }

        return this.post(`bookings/schedules/${idSchedule}`, this.defaultEndpointParams, bookActivity);
    }

    validate(idSchedule: number, idMember: number): Observable<BookingValidation> {
        this.setLoading(true);

        return this.post(`bookings/schedules/${idSchedule}/members/${idMember}/validate`, this.defaultEndpointParams, null,
            this.noShowErrorNotification);
    }

    cancel(idSchedule: number, guest: StringDto | null): Observable<any> {
        this.setLoading(true);

        return this.post(`bookings/schedules/${idSchedule}/cancel`, this.defaultEndpointParams, guest ? { idGuest: guest.value } : null);
    }

    waitQueue(idSchedule: number, bookingWaitType: BookingWaitQueueType): Observable<any> {
        this.setLoading(true);

        return this.post('waitqueue', this.defaultEndpointParams, { idSchedule, bookingWaitType});
    }

    cancelWaitQueue(idSchedule: number): Observable<any> {
        this.setLoading(true);

        return this.delete(`waitqueue/${idSchedule}`, this.defaultEndpointParams, { });
    }

    setLoading(value: boolean): void {
        this.isLoadingSubject.next(value);
    }

    setReload(value: boolean): void {
        this.reloadDataSubject.next(value);
    }

    setWaitQueue(value: ScheduleInfoBasic|undefined): void {
        this.isWaitQueueSubject.next(value);
    }
}
