import { AccessPermissionType } from '@views/booking/enumerates/access-permission-type';

export class ScheduleBook {
    isReservable!: boolean;
    mustStartByStaff!: boolean;
    maxSeatOnline!: number;
    seatByRow!: number;
    isNumberedSeat!: boolean;
    accessPermissionType!: AccessPermissionType;
    isStreaming!: boolean;
    isCustomUrl!: boolean;
    urlStreaming!: string;
    isCustomAccessConfiguration!: boolean;
    isPayment!: boolean;
    amountCredits!: number;
    idSchedule!: number;
    bookedPlaces!: number;
    isMultiple!: boolean;

    build(content: any): this {
        Object.assign(this, content);

        return this;
    }
}
