import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TokenSession } from '@models/token/token-session';

import { NotificationService } from '@services/utils/notification.service';
import { SessionService } from '@services/utils/session.service';
import { Exceptions } from '@enumerates/exceptions';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private readonly environmentApps = Object.values(environment.apps);

    constructor(
        private readonly injector: Injector,
        private readonly sessionService: SessionService,
        private readonly router: Router) { }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const easyToken: TokenSession = this.sessionService.getToken();
        const idCenter = this.sessionService.getIdCenter();

        if (easyToken != null && easyToken.token !== '' && this.environmentApps.some(e => req.url.startsWith(e))) {
            const cloned = req.clone({
                headers: req.headers
                    .append('X-origin', 'easy')
                    .append('X-accesstoken', easyToken.token)
                    .append('X-idcenter', idCenter.toString())
            });

            return this.buildRequest(cloned, next);
        } else {
            return this.buildRequest(req, next);
        }
    }

    buildRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(
                () => { },
                err => {
                    const showErrorMessage = req.headers.get('showErrorMessage');
                    if (showErrorMessage == null || showErrorMessage === 'true') {
                        this.doError(err);
                    }
                }
            )
        );
    }

    doError(err: any): void {
        const notificationService = this.injector.get(NotificationService);
        if (err instanceof HttpErrorResponse) {
            if (err.error && !(err.error instanceof ProgressEvent) && err.error.errors) {
                const errors = err.error.errors || err.error;

                if (errors[0].code === Exceptions.emailRequireVerification){
                    this.router.navigateByUrl('/account/validate/pending');
                } else {
                    errors.forEach((error: { message: any }) => {
                        notificationService.showErrorMessage(error.message);
                    });
                }
            }
        }
    }
}
