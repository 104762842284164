import { ComponentRef } from '@angular/core';

import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { Modal } from './modal';

export class ModalUtil {
    private result$ = new Subject<any>();
    readonly onResult = this.result$.asObservable();

    constructor(private modal: ComponentRef<Modal<any>>, autoDestroy$?: Subject<boolean>) {
        this.modal.instance.modalInstance = this;
        if (autoDestroy$) {
            autoDestroy$.asObservable().pipe(take(1)).subscribe(() => this.destroy$());
        }
    }

    save(output: any): void {
        this.result$.next(output);
        this.destroy$();
    }

    close(output: any): void {
        this.result$.error(output);
        this.destroy$();
    }

    private destroy$(): void {
        setTimeout(() => this.modal.destroy(), 200);
        this.result$.complete();
    }
}
