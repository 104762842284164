export const Constants = {
    WS: {
        RESULT_KO: 1,
        RESULT_OK: 0,
        RESULT_MULTI: 50
    },
    SETTINGS: {
        BOOKING: {
            GENERAL: 0,
            ACCESS: 1,
            PENALTIES: 2
        },
        WEB: {
            CONFIGURATION: 0,
            HEADER: 1,
            BODY: 2
        }
    },
    UPLOAD: {
        EVENT: {
            START: 'start',
            REMOVED: 'removed',
            SUCCESS: 'success'
        }
    },
    FRAME_ACTION: {
        LOADING_HIDE: 'Loading.Hide',
        LOADING_SHOW: 'Loading.Show',
        REDIRECT: 'Redirect',
        ALERT: 'Alert',
        INTERCOM_URL: 'Intercom.Url',
        MENU_RELOAD: 'Menu.Reload',
        LOGOUT: 'Logout',
        MENU_FIRST_CHILD: 'Menu.First.Child',
        NO_SESSION: 'No-Session'
    },
    REPORTS: {
        REPORT: {
            NAME: 'report',
            URL: 'https://app.powerbi.com/reportEmbed?reportId=:idReport&groupId=:idGroup'
        },
        DASHBOARD: {
            NAME: 'dashboard',
            URL: 'https://app.powerbi.com/dashboardEmbed?dashboardId=:idReport&groupId=:idGroup'
        },
        FILTERS: {
            CENTER: 'filterCenter',
            LANGUAGE: 'filterLanguage',
            COMPANY: 'filterCompany',
            CENTERS_COMPANY: 'filterCentersCompany'
        }
    },
    MODE: {
        MODE_NAME: 'viewMode',
        EMBED : 'embed'
    }
};
