import { SubscriptionType } from '@enumerates/fee/subscription-type';
import { Fee } from '../fee';
import { SubscriptionPlanPrice } from './subscription-plan-price';

export class SubscriptionPlan extends Fee {
    isClient!: boolean;
    totalSubscribers!: number;
    subscriptionPrices!: SubscriptionPlanPrice[];
    priceDefault = 0;
    isVirtual!: boolean;
    isProrated!: boolean;

    get prices(): any {
        return this.subscriptionPrices;
    }

    build(content: any): this {
        super.build(content);

        this.isClient = content.isClient;
        this.isVirtual = content.isVirtual;
        this.isProrated = content.isProrated;

        this.subscriptionPrices = [];
        if (content.subscriptionPrices) {
            content.subscriptionPrices.forEach((price: any) => {
                this.subscriptionPrices.push(new SubscriptionPlanPrice().buildWithTax(price, this.tax));
            });

            this.subscriptionPrices.sort((a, b) => a.type - b.type);
        }

        this.getPrice();

        return this;
    }

    private getPrice(): void {
        if (this.subscriptionPrices.length === 0) {
            this.price = 0;
            this.typeName = '';

            return;
        }

        const first = this.subscriptionPrices[0];
        this.priceWithTax = first.priceWithTax;
        this.typeName = SubscriptionType[first.type];
    }
}
