import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { InternationalizationService } from '@services/utils/internationalization/internationalization.service';
import { BookingInfo } from '@views/booking/models/booking-info/booking-info';
import { NzAvatarComponent } from 'ng-zorro-antd/avatar';

@Component({
    selector: 'app-booking-member-info',
    templateUrl: './booking-member-info.component.html',
    styleUrls: ['./booking-member-info.component.scss']
})
export class BookingMemberInfoComponent {
    @Input() isVisibleBooked!: boolean;
    @Input() bookingInfo!: BookingInfo;

    @ViewChild('avatarComponent')
    public avatarComponent!: NzAvatarComponent;

    @Output() closeEvent = new EventEmitter<boolean>();

    dateFormat = this.internationalizationService.getDateFormat();
    hourFormat = this.internationalizationService.getHourFormat();

    constructor(private router: Router, private internationalizationService: InternationalizationService) { }

    goToDashboard(): void {
        this.router.navigateByUrl('private/dashboard');
    }

    close(): void {
        this.closeEvent.next(true);
    }
}
