import { createAction, props } from '@ngrx/store';

export const setSeat = createAction(
    '[Seats] set seat',
    props<{ idSchedule: number; dateProgram: Date; isMap: boolean; position: string | number | undefined }>()
);

export const clean = createAction(
    '[Seats] clean'
);
