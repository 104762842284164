import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { Profile } from '@models/profile/profile';
import { EndpointParams } from '@models/utils/endpoint-params';
import { HttpService } from '@services/http.service';

@Injectable({
    providedIn: 'root'
})
export class ProfileService extends HttpService {
    private readonly defaultEndpointParams: EndpointParams = { app: 'member', version: 2 };

    private profileSubject = new BehaviorSubject<Profile>(new Profile());
    private isLoadingDataSubject = new BehaviorSubject<boolean>(true);

    private dataStore: { profile: Profile } = { profile: new Profile() };

    readonly profileInfo = this.profileSubject.asObservable();
    readonly loadingData = this.isLoadingDataSubject.asObservable();

    search(): void {
        this.loading(true);
        this.getProfile().subscribe(
            () => {
                this.next(this.dataStore.profile);
                this.loading(false);
            }
        );
    }

    update(memberPolarisDto: Profile): Observable<boolean> {
        return new Observable<boolean>(
            observable => {
                this.loading(true);
                this.put('members/me/polaris', this.defaultEndpointParams, memberPolarisDto).subscribe(
                    () => {
                        this.loading(false);
                        observable.next(true);
                    },
                    () => observable.next(false)
                );
            }
        );
    }

    private getProfile(): Observable<boolean> {
        return new Observable<boolean>(
            observable => {
                this.get('members/me/polaris', this.defaultEndpointParams).subscribe(
                    data => {
                        this.dataStore.profile = new Profile().build(data);
                        observable.next(true);
                    },
                    () => observable.next(false)
                );
            }
        );
    }

    private next(data: Profile): void {
        this.profileSubject.next(data);
    }

    private loading(value: boolean): void {
        this.isLoadingDataSubject.next(value);
    }
}
