import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[appDnd]'
})
export class DndDirective {
    @HostBinding('class.fileover') fileOver!: boolean;

    @Output()
        fileDropped = new EventEmitter<any>();

    @HostListener('dragover', ['$event']) onDragOver(evt: any): void {
        evt.preventDefault();
        evt.stopPropagation();

        this.fileOver = true;
    }

    @HostListener('dragleave', ['$event']) onDragLeave(evt: any): void {
        evt.preventDefault();
        evt.stopPropagation();
    }

    @HostListener('drop', ['$event']) onDrop(evt: any): void {
        evt.preventDefault();
        evt.stopPropagation();

        const files = evt.dataTransfer.files;

        if (files && files.length === 1) {
            this.fileDropped.emit(files[0]);
        }
    }
}
