import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { SettingsPayments } from '@models/settings/settings-payments';
import { EndpointParams } from '@models/utils/endpoint-params';
import { HttpService } from '@services/http.service';

@Injectable({
    providedIn: 'root'
})
export class SettingPaymentsService extends HttpService {
    private readonly defaultEndpointParams: EndpointParams = { app: 'payments', version: 1 };

    getSettings(): Observable<SettingsPayments> {
        return this.get('settings/member', this.defaultEndpointParams);
    }
}
