import { Guid } from 'guid-typescript';

export class ActivityInfo {
    idCenter!: number;
    duration!: number;
    photo!: string;
    id!: number;
    name!: string;
    publicToken!: Guid;
    description!: string;
    colorScheduler!: string;
    urlPhoto!: string;
    isInvisible!: boolean;
    idGroupActivity!: number;

    constructor() { }

    build(content: any): ActivityInfo {
        Object.assign(this, content);

        return this;
    }
}
