import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '@services/http.service';
import { EndpointParams } from '@models/utils/endpoint-params';
import { MemberInfo } from '@models/index';

@Injectable({
    providedIn: 'root'
})
export class MemberInfoService extends HttpService {
    private readonly defaultEndpointParams: EndpointParams = { app: 'payments', version: 1 };

    info(): Observable<MemberInfo> {
        return this.get('member/info', this.defaultEndpointParams);
    }
}
