export class LegalPaper {
    public idCenter!: number;
    public contractingConditions!: string;
    public commercialRegister!: string;

    build(content: any): this {
        Object.assign(this, content);

        return this;
    }
}
