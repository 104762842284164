import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { PaymentFlowState } from '@models/payment-flow/payment-flow-state';
import { SessionService } from '@services/utils/session.service';
import { PaymentProcessState } from '@views/payment/models/payment-process/payment-process-state';
import { PaymentProcessStep } from '@views/payment/enumerate/payment-process-step';
import { CONTAINER_FEE } from '@models/fee/fee-injector';
import { SignupFormType } from '@enumerates/setting/signup-form-type';

import * as paymentProcessActions from '@views/payment/reducers/payment-process.action';
import { Product } from '@models/index';
import * as paymentFlowActions from '../../../reducers/payment-flow/payment-flow.action';

@Component({
    selector: 'app-fee-detail-product',
    templateUrl: './fee-detail-product.component.html'
})
export class FeeDetailProductComponent {
    currency = '$';

    constructor(@Inject(CONTAINER_FEE) public fee: Product,
                private router: Router,
                private sessionService: SessionService,
                private store: Store<{ paymentProcess: PaymentProcessState; paymentFlow: PaymentFlowState }>) {
        this.currency = sessionService.getCenterInfo()?.currency;
    }

    viewDetails(): void {
        this.router.navigate([`/info/p/${this.fee.id}`]);
    }

    viewRegister(): void {
        this.store.dispatch(paymentFlowActions.setProduct({
            idProduct: this.fee.id,
            title: this.fee.title,
            price: this.fee.productPrice,
            subtotal: this.fee.subtotal,
            tax: this.fee.tax,
            amountTax: this.fee.amountTax,
            itemPrice: this.fee.itemPrice
        }));

        this.sessionService.isLogged.pipe(take(1)).subscribe((isLogged) => {
            if (isLogged || this.sessionService.getIsTokenNoSession()) {
                if (this.sessionService.getCenterInfo().signupSetting.signupFormType === SignupFormType.multiple && !this.sessionService.getToken().session) {
                    this.store.dispatch(paymentProcessActions.setCurrentStep({ step: PaymentProcessStep.data }));
                } else {
                    this.store.dispatch(paymentProcessActions.setCurrentStep({ step: PaymentProcessStep.confirm }));
                }
                this.router.navigateByUrl('payment/process');
            } else {
                this.router.navigateByUrl('register');
            }
        });
    }
}
