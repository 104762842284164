import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { StringDto } from '@models/basic/string-dto';
import { EndpointParams } from '@models/utils/endpoint-params';
import { MemberFee } from '@views/private/models/member-fee/member-fee';

import { BehaviorSubject, Observable } from 'rxjs';

import { HttpService } from 'src/app/services/http.service';

@Injectable({
    providedIn: 'root'
})
export class MemberFeeService extends HttpService {

    private readonly httpLocal: HttpClient = inject(HttpClient);
    private readonly defaultEndpointParams: EndpointParams = { app: 'payments', version: 1 };

    private readonly memberFeeSubject = new BehaviorSubject<MemberFee[]>([]);
    private readonly isLoadingDataSubject = new BehaviorSubject<boolean>(true);
    private readonly dataStore: { memberFees: MemberFee[] } = { memberFees: [] };

    readonly memberFees = this.memberFeeSubject.asObservable();
    readonly isLoading = this.isLoadingDataSubject.asObservable();

    list(): void {
        this.loading(true);
        this.listMemberFees().subscribe(
            () => {
                this.next();
                this.loading(false);
            }
        );
    }

    searchMemberFees(): Observable<any> {
        return this.get('memberfee/member', this.defaultEndpointParams);
    }

    searchMemberSubscription(idMemberFee: string): Observable<MemberFee>{
        return this.get(`memberfee/recurrentfee/member/${idMemberFee}`, this.defaultEndpointParams);
    }

    searchMemberOneTimeFee(idMemberFee: string): Observable<MemberFee>{
        return this.get(`memberfee/onetimefee/member/${idMemberFee}`, this.defaultEndpointParams);
    }

    getContractingConditions(contractingConditionsUrl: string): Observable<StringDto>{

        return this.httpLocal.get<StringDto>(contractingConditionsUrl);
    }

    private listMemberFees(): Observable<boolean> {
        return new Observable<boolean>(
            observable => {
                this.searchMemberFees().subscribe(
                    data => {
                        this.dataStore.memberFees = [];
                        data.forEach((memberFee: any) => {
                            this.dataStore.memberFees.push(new MemberFee().build(memberFee));
                        });

                        observable.next(true);
                    }, () => observable.next(false)
                );
            }
        );
    }

    private next(): void {
        this.memberFeeSubject.next([ ...this.dataStore.memberFees ]);
    }

    private loading(value: boolean): void {
        this.isLoadingDataSubject.next(value);
    }
}
