<div class="error-background">
    <div class="error-body">
        <p class="error-title">
            {{ 'error_400' | translate }}
        </p>

        <img alt="Error400" src="assets/imgs/error400.svg" />

        <p class="error-subtitle">
            {{ 'no_communication' | translate }}
        </p>

        <p class="error-description">
            {{ 'error_400_description' | translate }}
        </p>
    </div>
</div>