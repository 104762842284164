import { Injectable } from '@angular/core';
import { ScheduleInfoBasic } from '@views/booking/models/calendar-full/schedule/schedule-info-basic';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BookingRealtimeService {
    private scheduleSubject = new BehaviorSubject<ScheduleInfoBasic | null>(null);
    private dataStore: { schedule: ScheduleInfoBasic | null } = { schedule: null };

    readonly schedule = this.scheduleSubject.asObservable();

    setSchedule(schedule: ScheduleInfoBasic): void {
        this.dataStore.schedule = schedule;
        this.next();
    }

    removeSchedule(): void {
        this.dataStore.schedule = null;
        this.next();
    }

    private next(): void {
        this.scheduleSubject.next(this.dataStore.schedule);
    }
}
