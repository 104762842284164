<div class="page-content gray-content">
    <div nz-row nzJustify="center">
        <div nz-col [nzSpan]="24">
            <h1 class="page-title">{{ 'products' | translate }}</h1>
        </div>
    </div>
    <div nz-row [nzGutter]="[0, 40]" nzJustify="center">
        <div nz-col [nzXl]="16" [nzSm]="20" [nzXs]="22" class="heading-3" text-center>
            {{ subscriptionTxt | translate }}
        </div>
        <div nz-col [nzXl]="16" [nzSm]="20" [nzXs]="22">
            <app-rates-subscription></app-rates-subscription>
        </div>
        <div nz-col [nzXl]="16" [nzSm]="20" [nzXs]="22" class="heading-3" text-center>
            {{ singlePaymentTxt | translate }}
        </div>
        <div nz-col [nzXl]="16" [nzSm]="20" [nzXs]="22">
            <app-rates-single-payment></app-rates-single-payment>
        </div>
        <ng-container *ngIf="showProducts">
            <div nz-col [nzXl]="16" [nzSm]="20" [nzXs]="22" class="heading-3" text-center>
                {{ 'products' | translate }}
            </div>
            <div nz-col [nzXl]="16" [nzSm]="20" [nzXs]="22">
                <app-rates-products></app-rates-products>
            </div>
        </ng-container>
    </div>
</div>
