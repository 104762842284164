export class TaskFee {
    id!: number;
    idFee!: number;
    idStaffTask!: number;
    staffTask!: any;
    sessionsDay!: number;
    sessionsWeek!: number;
    sessionsMonth!: number;

    build(content: any): this {
        Object.assign(this, content);

        return this;
    }
}
