import { FeeType } from '@enumerates/fee/fee-type';

import { Tax } from '@models/tax/tax';
import { ActivityFee } from './activity/activity-fee';
import { TaskFee } from './task/task-fee';

export class Fee {
    id!: string;
    image!: string;
    imageUrl!: string;
    title!: string;
    subtitle!: string;
    description!: string;
    buttonTitle!: string;
    isDeleted!: boolean;
    order!: number;
    isVisible!: boolean;
    isLimited!: boolean;
    sessions!: number;
    sessionsDay!: number;
    sessionsWeek!: number;
    sessionsMonth!: number;
    type!: FeeType;
    isVirtual!: boolean;
    activityFees!: ActivityFee[];
    taskFees!: TaskFee[];
    isFeatured!: boolean;
    featuredTag!: string;

    price!: number;
    typeName!: string;
    isProrated!: boolean;
    isSelected!: boolean;

    isFreeTrial!: boolean;
    daysFreeTrial!: number;

    tax!: Tax;
    priceWithTax!: number;
    amountTax!: number;
    subtotal!: number;
    itemPrice!: number;

    private feePrices!: any;
    private feePrice!: any;
    private feeTiersPeriod!: any;

    constructor() {
        this.activityFees = [];
        this.taskFees = [];
    }

    build(content: any): this {
        Object.assign(this, content);

        this.activityFees = [];
        if (content.activityFees) {
            content.activityFees.forEach((activity: any) => {
                this.activityFees.push(new ActivityFee().build(activity));
            });
        }

        this.taskFees = [];
        if (content.taskFees) {
            content.taskFees.forEach((task: any) => {
                this.taskFees.push(new TaskFee().build(task));
            });
        }

        this.getTaxes();

        return this;
    }

    public get prices(): any {
        return this.feePrices;
    }

    public set prices(value: any) {
        this.feePrices = value;
    }

    public get oneTimePrice(): any {
        return this.feePrice;
    }

    public set oneTimePrice(value: any) {
        this.feePrice = value;
    }

    public get productPrice(): any {
        return this.feePrice;
    }

    public set productPrice(value: any) {
        this.feePrice = value;
    }

    public get tiersPeriod(): any {
        return this.feeTiersPeriod;
    }

    public set tiersPeriod(value: any) {
        this.feeTiersPeriod = value;
    }

    private getTaxes(): void {
        this.subtotal = this.price;
        this.itemPrice = this.subtotal;
        if (!this.tax || this.tax.isIncludeInPVP) {
            this.priceWithTax = this.price;
        } else {
            this.priceWithTax = this.price * ((this.tax.rate / 100) + 1);
        }

        if (this.tax) {
            const taxRate = (this.tax.rate / 100) + 1;
            this.subtotal = this.priceWithTax / taxRate;
            this.amountTax = this.subtotal * (this.tax.rate / 100);

            if (!this.tax.isIncludeInPVP) {
                this.itemPrice = this.subtotal;
            }
        }
    }
}
