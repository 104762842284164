export class PaymentsDomainHelper {
    static readonly DOMAIN_MASTER = 'njzythdouj';

    static getDomainMaster(): string {
        return this.DOMAIN_MASTER;
    }

    static isDomainMaster(domain: string): boolean {
        return domain === this.DOMAIN_MASTER;
    }
}
