export class StringDto {
    public value: string;

    constructor() {
        this.value = '';
    }

    build(content: any): this {
        this.value = content.value;

        return this;
    }
}
