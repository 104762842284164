export enum BookingState {
    available = 0,
    canBook = 1,
    completedOnline = 2,
    booked = 3,
    canceled = 4,
    canBookSoon = 5,
    finished = 6,
    completed = 7,
    penalized = 8,
    onlyOnline = 9,
    onlyTotem = 10,
    memberBookingDisabled = 11,
    streamingAvailable = 12,
    streamingSoon = 13,
    waitQueueAvailable = 14,
    inWaitQueue = 15,
    accessWorkout = 16,
    multipleBookedAvailable = 17,
    bookedMultipleTimes = 18
}
