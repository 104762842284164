import { PlatformType } from '@views/booking/enumerates/platform-type';

export class BookingMemberEvent {
    idMember!: number;
    idBooking!: number;
    originValidated!: PlatformType;

    constructor() { }

    build(content: any): BookingMemberEvent {
        Object.assign(this, content);

        return this;
    }
}
