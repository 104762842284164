import { Component, inject } from '@angular/core';
import { Modal } from '@models/index';

import { GuestsTab } from '@views/booking/enumerates/guests/guests-tab';
import { GuestCreate } from '@views/booking/models/guest/guest-create';
import { CalendarFullService } from '@views/booking/services/calendar/calendar-full.service';
import { GuestsService } from '@views/booking/services/guests/guests.service';

@Component({
    selector: 'app-guests',
    templateUrl: './guests.component.html'
})
export class GuestsComponent extends Modal<string> {
    private readonly calendarService = inject(CalendarFullService);
    private readonly guestService = inject(GuestsService);
    showModal = false;

    bookedGuests: any = { } = [];
    actualTab = 1;
    guestCreate: GuestCreate = {
        name: '',
        lastName: ''
    };
    selectedGuest!: string;
    seat!: string;

    readonly guests$ = this.calendarService.guests;
    readonly guestsTab = GuestsTab;

    onInjectInputs(inputs: any): void {
        this.seat = inputs.seat;
        inputs.bookingPlace.filter((c: any) => c.guest).forEach((m: any) => this.bookedGuests[m.guest.id] = true);
        this.showModal = true;
    }

    changeTab(event: GuestsTab): void {
        if (event === GuestsTab.list) {
            this.guestCreate = {
                name: '',
                lastName: ''
            };
        } else if (event === GuestsTab.new) {
            this.selectedGuest = '';
        }
    }

    close(): void {
        this.showModal = false;
        this.closing();
    }

    confirm(): void {
        if (this.actualTab === GuestsTab.list) {
            this.showModal = false;
            this.saving(this.selectedGuest);

            return;
        }

        if (this.actualTab === GuestsTab.new) {
            this.guestService.create(this.guestCreate).subscribe(
                guest => {
                    this.showModal = false;
                    this.saving(guest.id);
                }
            );
        }
    }
}
