export enum BookingExceptions {
    notEnoughCredits = 7033,
    limitsInFeeReached = 1120,
    limitsInFeeReachedCanBuyWithCredits = 1121,
    limitsInFeeDaylyReached = 1122,
    limitsInFeeDaylyReachedCanBuyWithCredits = 1123,
    limitsInFeeWeeklyReached = 1124,
    limitsInFeeWeeklyReachedCanBuyWithCredits = 1125,
    limitsInFeeMonthlyReached = 1126,
    limitsInFeeMonthlyReachedCanBuyWithCredits = 1127,
    activityNotAvailableInFee = 1128,
    activityNotAvailableInFeeCanBuyWithCredits = 1129,
    notFeeFound = 1130,
    notFeeFoundCanBuyWithCredits = 1131,
    memberDailyLimitPerServiceExceded = 5314
}
