<nz-modal [(nzVisible)]="showModal" [nzWidth]="'520px'" [nzFooter]="null" [nzClosable]="true" nzCentered (nzOnCancel)="close()" [nzClassName]="'resume-modal'">
    <ng-container *nzModalContent>
        <div nz-row [nzGutter]="[0, 16]">
            <div nz-col [nzSpan]="24" text-center class="heading-4">
                <ng-container *ngIf="!seat">
                    {{ 'select_seat_guest' | translate }}
                </ng-container>
                <ng-container *ngIf="seat">
                    {{ 'book_x_seat_guest' | translate: { x: seat } }}
                </ng-container>
            </div>

            <div nz-col [nzSpan]="24" text-center class="sub-heading-2">
                {{ 'select_seat_guest_info' | translate }}
            </div>
        </div>
        <div nz-row [nzGutter]="[0, 16]">
            <div nz-col [nzSpan]="24">
                <nz-tabset [(nzSelectedIndex)]="actualTab" (nzSelectedIndexChange)="changeTab($event)" [nzAnimated]="false">
                    <nz-tab [nzTitle]="'my_guests' | translate">
                        <nz-radio-group [(ngModel)]="selectedGuest" class="reversed-radio">
                            <ng-container *ngFor="let guest of guests$ | async">
                                <label nz-radio [nzValue]="guest.id" [nzDisabled]="this.bookedGuests[guest.id]">{{ guest.name }} {{ guest.lastName }}</label>
                            </ng-container>
                        </nz-radio-group>
                    </nz-tab>
                    <nz-tab [nzTitle]="'new_guest' | translate">
                        <div nz-row [nzGutter]="[0, 16]">
                            <div nz-col [nzSpan]="24">
                                <input nz-input [placeholder]="'firstname' | translate" [(ngModel)]="guestCreate.name" />
                            </div>
                            <div nz-col [nzSpan]="24">
                                <input nz-input [placeholder]="'lastname' | translate" [(ngModel)]="guestCreate.lastName" />
                            </div>
                        </div>
                    </nz-tab>
                </nz-tabset>
            </div>
        </div>
        <div nz-row [nzGutter]="24" top-40>
            <div nz-col [nzSpan]="12">
                <button nz-button nzBlock nzType="text" class="big sub-heading-2" (click)="close()">{{ 'cancel' | translate }}</button>
            </div>

            <div nz-col [nzSpan]="12">
                <button nz-button nzBlock nzType="primary" (click)="confirm()" class="big" 
                    [disabled]="(actualTab === guestsTab.list && !selectedGuest) || (actualTab === guestsTab.new && (!guestCreate.name || !guestCreate.lastName))">
                    {{ 'confirm' | translate }}
                </button>
            </div>
        </div>
    </ng-container>
</nz-modal>