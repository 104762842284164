import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';

import { Internationalization } from '@models/utils/internationalization';
import { DateCheck } from '@models/utils/date-check';

import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class InternationalizationService {
    private DEFAULT_FIRST_DAY_OF_WEEK = 1;
    private DEFAULT_LANGUAGE = navigator.language.replace('-', '_');
    private DEFAULT_LOCALE = navigator.language.substring(0, 2);
    private DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
    private DEFAULT_HOUR_FORMAT = 'h:mm a';

    constructor(private cookieService: CookieService) {
        this.setDefaultData();
    }

    private setDefaultData(): void {
        const languagesAvailable = ['es_ES', 'en_US', 'de_DE', 'fr_FR', 'pt_PT', 'ca_ES', 'pt_BR', 'eu_ES', 'it_IT', 'gl_ES', 'en_AU'];

        if (!languagesAvailable.includes(this.DEFAULT_LANGUAGE)) {
            if (this.DEFAULT_LANGUAGE.startsWith('es')) {
                this.DEFAULT_LANGUAGE = languagesAvailable[0];
            } else if (this.DEFAULT_LANGUAGE.startsWith('en')) {
                this.DEFAULT_LANGUAGE = languagesAvailable[1];
            } else {
                this.DEFAULT_LANGUAGE = languagesAvailable[0];
                this.DEFAULT_LOCALE = languagesAvailable[0].substring(0, 2);
            }
        }
    }

    getFirstDayOfWeek(): number {
        return this.getInternationalization().firstDayOfWeek;
    }

    getLastDayOfWeek(): number {
        return this.getInternationalization().lastDayOfWeek;
    }

    setFirstDayOfWeek(firstDayOfWeek: number): void {
        const internationalization = this.getInternationalization();
        internationalization.firstDayOfWeek = firstDayOfWeek;
        internationalization.lastDayOfWeek = (firstDayOfWeek + 6) % 7;
        this.setInternationalization(internationalization);
    }

    getLanguage(): string {
        return this.getInternationalization().codeLanguage;
    }

    getParsedLanguage(): string {
        return this.getInternationalization().codeLanguage.replace(/_/g, '-').toLowerCase();
    }

    setLanguage(language: string): void {
        const internationalization = this.getInternationalization();
        internationalization.codeLanguage = language;
        this.setInternationalization(internationalization);
    }

    getLocale(): string {
        return this.getInternationalization().locale;
    }

    setLocale(locale: string): void {
        const internationalization = this.getInternationalization();
        internationalization.locale = locale;
        this.setInternationalization(internationalization);
    }

    getDateFormat(): string {
        return this.getInternationalization().dateFormat;
    }

    setDateFormat(dateFormat: string): void {
        const internationalization = this.getInternationalization();
        internationalization.dateFormat = dateFormat;
        this.setInternationalization(internationalization);
    }

    getHourFormat(): string {
        return this.getInternationalization().hourFormat;
    }

    setHourFormat(hourFormat: string): void {
        const internationalization = this.getInternationalization();
        internationalization.hourFormat = hourFormat;
        this.setInternationalization(internationalization);
    }

    isMeridianHour(): boolean {
        return this.getHourFormat() === this.DEFAULT_HOUR_FORMAT;
    }

    private getInternationalization(): Internationalization {
        const intString = this.cookieService.get('internationalization');
        let internationalization: Internationalization;
        if (intString) {
            internationalization = new Internationalization().build(JSON.parse(intString));
        } else {
            this.setDefaultData();

            internationalization = new Internationalization().build({
                codeLanguage: this.DEFAULT_LANGUAGE,
                locale: this.DEFAULT_LOCALE, firstDayOfWeek: this.DEFAULT_FIRST_DAY_OF_WEEK, dateFormat: this.DEFAULT_DATE_FORMAT,
                hourFormat: this.DEFAULT_HOUR_FORMAT
            });
            this.setInternationalization(internationalization);
        }

        return internationalization;
    }

    private setInternationalization(internationalization: Internationalization): void {
        this.cookieService.set('internationalization', JSON.stringify(internationalization), 1, '/');
    }

    getWeekDay(weekDay: number, firstDayOfWeek?: number): number {
        return (weekDay + (firstDayOfWeek ? firstDayOfWeek : this.getFirstDayOfWeek())) % 7;
    }

    loadWeek(dateMoment: moment.Moment): DateCheck[] {
        const days: DateCheck[] = [];
        for (let i = 0; i < 7; i++) {
            days.push({
                date: dateMoment.clone().weekday(i).toDate(),
                weekDay: this.getWeekDay(i),
                isChecked: false
            });
        }

        return days;
    }

    importLocale(): void {
        switch (this.getLanguage().toLowerCase()) {
        case 'es_es':
            // @ts-ignore
            import('@/../node_modules/@angular/common/locales/es.mjs').then(lang => registerLocaleData(lang.default));
            break;
        case 'en_us':
            // @ts-ignore
            import('@/../node_modules/@angular/common/locales/en.mjs').then(lang => registerLocaleData(lang.default));
            break;
        case 'de_de':
            // @ts-ignore
            import('@/../node_modules/@angular/common/locales/de.mjs').then(lang => registerLocaleData(lang.default));
            break;
        case 'fr_fr':
            // @ts-ignore
            import('@/../node_modules/@angular/common/locales/fr.mjs').then(lang => registerLocaleData(lang.default));
            break;
        case 'pt_pt':
            // @ts-ignore
            import('@/../node_modules/@angular/common/locales/pt.mjs').then(lang => registerLocaleData(lang.default));
            break;
        case 'ca_es':
            // @ts-ignore
            import('@/../node_modules/@angular/common/locales/ca.mjs').then(lang => registerLocaleData(lang.default));
            break;
        case 'pt_br':
            // @ts-ignore
            import('@/../node_modules/@angular/common/locales/pt.mjs').then(lang => registerLocaleData(lang.default));
            break;
        case 'eu_es':
            // import(`@angular/common/locales/eu.js`).then(lang => registerLocaleData(lang.default));
            // @ts-ignore
            import('@/../node_modules/@angular/common/locales/es.mjs').then(lang => registerLocaleData(lang.default));
            break;
        case 'it_it':
            // @ts-ignore
            import('@/../node_modules/@angular/common/locales/it.mjs').then(lang => registerLocaleData(lang.default));
            break;
        case 'gl_es':
            // @ts-ignore
            import('@/../node_modules/@angular/common/locales/gl.mjs').then(lang => registerLocaleData(lang.default));
            break;
        case 'en_au':
            // @ts-ignore
            import('@/../node_modules/@angular/common/locales/en.mjs').then(lang => registerLocaleData(lang.default));
            break;
        case 'es_mx':
            // @ts-ignore
            import('@/../node_modules/@angular/common/locales/es-MX.mjs').then(lang => registerLocaleData(lang.default));
            break;
        case 'es_ar':
            // @ts-ignore
            import('@/../node_modules/@angular/common/locales/es-AR.mjs').then(lang => registerLocaleData(lang.default));
            break;
        }
    }
}
