import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

import { BookingEvent } from '@views/booking/models/signalr/booking-event';
import { BookingErrorEvent } from '@views/booking/models/signalr/booking-error-event';

import { environment } from '@environments/environment';
import { BookingCanceledEvent } from '@views/booking/models/signalr/booking-canceled-event';

@Injectable({
    providedIn: 'root'
})
export class SignalRService {
    private hubConnection!: HubConnection;

    get connection(): HubConnection {
        return this.hubConnection;
    }

    startConnection(idCenter: number, userToken: string): void {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(`${environment.apps.realtime}/${environment.hubs.booking}?idCenter=${idCenter}`, {
                accessTokenFactory: () => userToken
            })
            .configureLogging(LogLevel.Warning)
            .build();

        this.hubConnection
            .start()
            .then(() => { })
            .catch(() => { });
    }

    stopConnection(): void {
        this.hubConnection
            .stop()
            .then(() => { })
            .catch(() => { });
    }

    scheduleBookingBooked(): Observable<BookingEvent> {
        return new Observable<BookingEvent>(observable => {
            this.hubConnection.on('scheduleBookingBooked', (data: string) => {
                observable.next(new BookingEvent().build(data));
            });
        });
    }

    scheduleBookingCanceled(): Observable<BookingCanceledEvent> {
        return new Observable<BookingCanceledEvent>(observable => {
            this.hubConnection.on('scheduleBookingCanceled', (data: string) => {
                observable.next(new BookingCanceledEvent().build(data));
            });
        });
    }

    scheduleBookingError(): Observable<BookingErrorEvent> {
        return new Observable<BookingErrorEvent>(observable => {
            this.hubConnection.on('scheduleBookingError', (data: string) => {
                observable.next(new BookingErrorEvent().build(data));
            });
        });
    }
}
