<nz-modal [(nzVisible)]="isVisibleBooked" [nzWidth]="'620px'" [nzFooter]="null" [nzClosable]="false" [nzKeyboard]="false" [nzMaskClosable]="false" nzCentered [nzClassName]="'resume-modal'">
    <ng-container *nzModalContent>
        <div nz-row [nzGutter]="[0, 32]">
            <div nz-col [nzSpan]="24" text-center>
                <img alt="successful" src="assets/icons/successful.svg" />
            </div>

            <div nz-col [nzSpan]="24" text-center class="heading-4">
                {{ 'booking_booked_successful' | translate }}
            </div>
        </div>

        <div nz-row [nzGutter]="24" top-40>
            <div nz-col [nzSpan]="12">
                <button id="btn_goback_dashboard" nz-button nzBlock nzType="text" class="big sub-heading-2" (click)="goToDashboard()">{{ 'go_home' | translate }}</button>
            </div>

            <div nz-col [nzSpan]="12">
                <button id="btn_goback_schedule" nz-button nzBlock nzType="primary" class="big" (click)="close()">{{ 'return_calendar' | translate }} </button>
            </div>
        </div>
    </ng-container>
</nz-modal>