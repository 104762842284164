import { FeeType } from '@enumerates/fee/fee-type';
import { SubscriptionType } from '@enumerates/fee/subscription-type';

import { MemberBasic } from '@models/member/member-basic';
import { CouponType } from '@enumerates/coupon/coupon-type';
import { RateUserStatus } from '@views/private/enumerate/rate-user-status';
import { MemberFeeCouponUsed } from './coupon/member-fee-coupon-used';

export class MemberFee {
    idFee!: string;
    idMemberFee!: string;
    isActive!: boolean;
    isLimited!: boolean;
    member!: MemberBasic;
    nextDate!: Date;
    endDate!: Date;
    endedDate!: Date;
    sessions!: number;
    sessionsUsed!: number;
    title!: string;
    type!: SubscriptionType;
    subtitle!: string;
    price!: number;
    idPrice!: number;
    feeType!: FeeType;
    isFreeTrial!: boolean;

    typeName!: string;
    percentage!: number;

    coupon!: MemberFeeCouponUsed;
    totalPrice!: number;
    currentStatus!: RateUserStatus;

    contractingConditionsUrl!: string;

    build(content: any): this {
        Object.assign(this, content);

        if (content.member) {
            this.member = new MemberBasic().build(content.member);
        }

        this.typeName = SubscriptionType[this.type];
        this.calculatePercentageUsage();
        if (this.coupon) {
            this.coupon.typeName = '';
            const isCouponValid = this.coupon.type === CouponType.forever || this.coupon.type === CouponType.for_x_months;
            const isSubscriptionValid = this.type !== SubscriptionType.puntual;

            if (isCouponValid && isSubscriptionValid) {
                this.coupon.typeName = CouponType[this.coupon.type];

                if (this.coupon.amount) {
                    this.totalPrice = Math.max(this.price - this.coupon.amount, 0);
                } else if (this.coupon.percent) {
                    this.totalPrice = this.price * (1 - this.coupon.percent / 100);
                }
            } else {
                this.totalPrice = this.price;
            }
        } else {
            this.totalPrice = this.price;
        }

        return this;
    }

    private calculatePercentageUsage(): void {
        if (this.isLimited) {
            if (this.sessionsUsed === 0) {
                this.percentage = 0;
            } else {
                this.percentage = (this.sessionsUsed / this.sessions) * 100;
            }
        } else {
            this.percentage = 100;
        }
    }
}
