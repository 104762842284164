<nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="isLoadingData">
    <div class="page-content with-padding gray-content">
        <ng-container *ngIf="!isLoadingData">
            <div nz-row [nzJustify]="'center'" top-40>
                <div nz-col [nzXs]="22" [nzSm]="18" [nzXl]="10" [nzXXl]="8">
                    <div nz-row [nzGutter]="[{ xs: 0, sm: 0 }, { xs: 20, sm: 40 }]">
                        <div nz-col [nzSpan]="24" class="heading-2" text-center>
                            {{ data.title }}
                        </div>

                        <ng-container *ngIf="!showCancelButton && (data.type === 1 && data.prices?.length > 1)">
                            <div nz-col [nzSpan]="24" text-center>
                                <nz-radio-group [(ngModel)]="subscriptionPlanPrice">                                  
                                    <ng-container *ngFor="let price of data.prices">
                                        <label nz-radio-button [nzValue]="price" class="fee-price-radio">{{ price.typeName | translate }}</label>
                                    </ng-container>
                                </nz-radio-group>
                            </div>
                        </ng-container>

                        <div nz-col [nzSpan]="24" class="heading-4" text-center>
                            {{ data.subtitle }}
                        </div>

                        <div nz-col [nzSpan]="24" class="pricing heading-0" text-center>
                            <ng-container *ngIf="data.type !== 1">
                                {{ data.oneTimePrice | number:'1.2-2' }} {{ currency }}
                            </ng-container>
                            <ng-container *ngIf="data.type === 1">
                                {{ subscriptionPlanPrice.priceWithTax | number:'1.2-2' }} {{ currency }} <div class="heading-4">/ {{ subscriptionPlanPrice.typeName | translate }}</div>
                            </ng-container>
                        </div>

                        <div nz-col [nzSpan]="24" class="common-1" text-center style="white-space: break-spaces;">
                            {{ data.description }}
                        </div>

                        <div nz-col [nzSpan]="24">                    
                            <nz-card>
                                <div nz-row [nzGutter]="[0, 16]">
                                    <div nz-col [nzSpan]="24" class="heading-4" text-center>
                                        {{ 'features_included' | translate }}
                                    </div>
                                    <ng-container *ngIf="data.activityFees.length || data.taskFees.length">
                                        <ng-container *ngFor="let activity of data.activityFees">
                                            <div nz-col [nzSpan]="24" class="common-body-label" text-center>
                                                {{ activity.activity.name }}
                                            </div>
                                        </ng-container>
                                        <ng-container *ngFor="let task of data.taskFees">
                                            <div nz-col [nzSpan]="24" class="common-body-label" text-center>
                                                {{ task.staffTask.name }}
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                                
                                <ng-container *ngIf="!data.activityFees.length && !data.taskFees.length">
                                    <div nz-col [nzSpan]="24" class="common-body-label" text-center>
                                        {{ 'all_inclusive' | translate }}
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="(!showCancelButton && showButton) || showCancelButton">
                                    <div nz-col [nzSpan]="24" class="select-price" text-center>
                                        <ng-container *ngIf="showCancelButton">
                                            <button nz-button nzType="default" class="big" [disabled]="!memberFee?.isActive" [nzLoading]="isLoadingCancel" (click)="cancelSubscription()">{{ 'cancel_product' | translate }}</button>
                                        </ng-container>
                                
                                        <ng-container *ngIf="!showCancelButton">
                                            <button nz-button nzType="primary" (click)="viewRegister()" class="big">{{ data.buttonTitle }}</button>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </nz-card>
                        </div>
                        <ng-container *ngIf="memberFee?.contractingConditionsUrl != null">
                            <div nz-col [nzSpan]="24" class="common-1" text-center style="white-space: break-spaces;">
                                <a (click)="showContractingCondition(modalContentTemplate)">{{ 'contracting_conditions' | translate }}</a>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</nz-spin>

<ng-template #indicatorTemplate><i nz-icon nzType="loading" [nzTheme]="'outline'"
    style="font-size: 24px; display: block;"></i>{{ 'loading' | translate }}...
</ng-template>

<ng-template #modalContentTemplate>
    <div [innerHTML]="contractingConditions"></div>
</ng-template>