import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InternationalizationService } from '@services/utils/internationalization/internationalization.service';

import * as moment from 'moment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    constructor(internationalizationService: InternationalizationService, translate: TranslateService) {
        moment.locale(internationalizationService.getLanguage());
        translate.setDefaultLang(internationalizationService.getLanguage());
        translate.use(internationalizationService.getLanguage());
    }
}
