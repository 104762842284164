import { ModalUtil } from './modal-util';

export abstract class Modal<T = void, S = void> {
    modalInstance!: ModalUtil;
    abstract onInjectInputs(inputs?: any): void;

    saving(output?: T): void {
        this.modalInstance.save(output);
    }

    closing(output?: S): void {
        this.modalInstance.close(output);
    }
}
