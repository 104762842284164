
import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { EndpointParams } from '@models/utils/endpoint-params';

const defaultOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export abstract class HttpService {
    noShowErrorNotification = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', showErrorMessage: 'false' })
    };

    defaultEndpoint = environment.apps.booking;

    constructor(protected http: HttpClient) { }

    protected get(url: string, endpointParams: EndpointParams, params?: any): Observable<any> {
        return this.http.get(this.getEndpoint(url, endpointParams), { params });
    }

    protected get2(url: string, endpointParams: EndpointParams, params?: any): Observable<any> {
        return this.http.get(this.getEndpoint(url, endpointParams), params);
    }

    protected post(url: string, endpointParams: EndpointParams, params?: any, options?: any): Observable<any> {
        return this.http.post(this.getEndpoint(url, endpointParams), params, options ? options : defaultOptions);
    }

    protected put(url: string, endpointParams: EndpointParams, params?: any, options?: any): Observable<any> {
        return this.http.put(this.getEndpoint(url, endpointParams), params, options ? options : defaultOptions);
    }

    protected delete(url: string, endpointParams: EndpointParams, options?: any): Observable<any> {
        return this.http.delete(this.getEndpoint(url, endpointParams), options ? options : defaultOptions);
    }

    private getEndpoint(url: string, endpointParams: EndpointParams): string {
        let endpoint = `${environment.apps[endpointParams.app]}`;

        if (endpointParams.version) {
            endpoint += `/v${endpointParams.version}`;
        }
        endpoint += `/${url}`;

        return endpoint;
    }
}
