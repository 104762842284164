export class TGException {
    code!: number;
    msg!: string;
    actionCredits!: string;
    actionFee!: string;

    constructor() { }

    build(content: any): TGException {
        Object.assign(this, content);

        return this;
    }
}
