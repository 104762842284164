import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { finalize, takeWhile } from 'rxjs/operators';

import { BookingState } from '@views/booking/enumerates/booking-state';

import { VideoHelper } from '@helpers/video-helper';

import { CenterInfo } from '@models/center/info/center-info';
import { ScheduleInfoBasic } from '@views/booking/models/calendar-full/schedule/schedule-info-basic';
import { BookingService } from '@views/booking/services/booking/booking.service';
import { SessionService } from '@services/utils/session.service';
import { InternationalizationService } from '@services/utils/internationalization/internationalization.service';

import { BookingSetting } from '@views/booking/models/booking-setting/booking-setting';
import { BookingInfo } from '@views/booking/models/booking-info/booking-info';
import { ModalBuilderService } from '@services/index';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { ScheduleInfoComponent } from './schedule-info/schedule-info.component';


@Component({
    selector: 'app-calendar-detail',
    templateUrl: './calendar-detail.component.html',
    styleUrls: ['./calendar-detail.component.scss']
})
export class CalendarDetailComponent implements OnInit, OnDestroy {
    private isAlive = true;

    @Input() bookingSetting!: BookingSetting | null;
    @Input() schedule!: ScheduleInfoBasic;
    @Input() showDate!: boolean;

    isLogged!: boolean;
    isLoading!: boolean;
    bookingStates = BookingState;
    dateFormat: string;
    hourFormat: string;

    bookingInfo!: BookingInfo;
    private destroy$ = new Subject<boolean>();

    constructor(private router: Router,
                private sessionService: SessionService,
                private bookingService: BookingService,
                private internationalizationService: InternationalizationService,
                private modalBuilderService: ModalBuilderService) {
        this.sessionService.isLogged.pipe(takeWhile(() => this.isAlive)).subscribe(
            isLogged => {
                this.isLogged = isLogged;
            }
        );

        this.dateFormat = this.internationalizationService.getDateFormat();
        this.hourFormat = this.internationalizationService.getHourFormat();
    }

    ngOnInit(): void {
        /* TODO: Comprobar flujo sin sesión?
        this.store.select(data => data.seats).pipe(take(1)).subscribe(
            data => {
                if (data.idSchedule > 0 && data.idSchedule === this.schedule.id && !this.selectedSeat) {
                    this.checkBook(data.position);
                }
            }
        );*/
    }

    ngOnDestroy(): void {
        this.isAlive = false;
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    viewDetails(): void {
        const info: BookingInfo = {
            dateProgram: this.schedule.dateProgram,
            timeStart: this.schedule.timeStart,
            positionName: '',
            duration: this.schedule.duration,
            room: this.schedule.room.room,
            amountMinutesToAccess: Math.abs(this.schedule.accessInfo.amountMinutesToAccessWithBookingFrom),
            maxDateToCancel: moment(`${this.schedule.dateProgram} ${this.schedule.timeStart}`, 'YYYY-MM-DD HH:mm')
                .subtract(this.bookingSetting?.amountMinutesOfCancelBookings, 'minutes').toDate(),
            isPayment: this.schedule.bookingInfo.isPayment,
            bookingPlace: this.schedule.bookedMemberResumen,
            viewDetail: true,
            details: {
                activityName: this.schedule.activity.name,
                staff: this.schedule.staff
            }
        };
        const modalUtil = this.modalBuilderService.open(ScheduleInfoComponent, { schedule: this.schedule, bookingInfo: info }, this.destroy$);
        modalUtil.onResult.subscribe();
    }

    view(urlStreaming: string): void {
        let urlFinal: string;
        if (urlStreaming != null && urlStreaming !== '') {
            urlFinal = urlStreaming;
        } else {
            const centerInfo: CenterInfo = this.sessionService.getCenterInfo();
            urlFinal = centerInfo.bookingOptions.streamingChannel;
        }

        this.bookingService.validate(this.schedule.id, this.sessionService.getIdMember())
            .pipe(
                finalize(() => {
                    if (VideoHelper.isGoogleOrMicrosoft(urlFinal)) {
                        window.open(urlFinal, '_blank');
                    } else{
                        this.isLoading = true;
                        this.router.navigateByUrl('/private/booking/streaming', { state: { urlStreaming: urlFinal } });
                    }
                })
            )
            .subscribe();
    }

    showModalWaitQueue(): void{
        this.bookingService.setWaitQueue(this.schedule);
        this.bookingService.isLoading.subscribe((isLoading) => {
            this.isLoading = isLoading;
        });
    }

    cancelWaitQueue(): void{
        this.isLoading = true;
        this.bookingService.cancelWaitQueue(this.schedule.id)
            .pipe(
                finalize(() => {
                    this.bookingService.setLoading(false);
                    this.bookingService.setReload(true);
                })
            )
            .subscribe();
    }

    goToLogin(): void {
        this.router.navigateByUrl('/login');
    }
}
