import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpService } from '@services/http.service';
import { EndpointParams } from '@models/utils/endpoint-params';
import { ProrateSearch } from '@views/private/models/prorate-info/prorate-search';
import { PaymentProrate } from '@models/prorate/payment-prorate';

@Injectable({
    providedIn: 'root'
})
export class FeeService extends HttpService {
    private readonly defaultEndpointParams: EndpointParams = { app: 'payments', version: 1 };

    searchOneTime(idOneTimeFee: string, publicToken: string): Observable<any> {
        const params = new HttpParams({ fromString: `centerToken=${publicToken}` });

        return this.get(`public/fees/onetime/${idOneTimeFee}`, this.defaultEndpointParams, params);
    }

    searchOneTimeLogged(idOneTimeFee: string): Observable<any> {
        return this.get(`fee/onetime/member/${idOneTimeFee}`, this.defaultEndpointParams);
    }

    searchSubscription(idSubscriptionPlan: string, publicToken: string): Observable<any> {
        const params = new HttpParams({ fromString: `centerToken=${publicToken}` });

        return this.get(`public/fees/recurrentfee/${idSubscriptionPlan}`, this.defaultEndpointParams, params);
    }

    searchSubscriptionLogged(idSubscriptionPlan: string): Observable<any> {
        return this.get(`fee/recurrentfee/member/${idSubscriptionPlan}/rates`, this.defaultEndpointParams);
    }

    searchProduct(idProduct: string, publicToken: string): Observable<any> {
        const params = new HttpParams({ fromString: `centerToken=${publicToken}` });

        return this.get(`public/fees/product/${idProduct}`, this.defaultEndpointParams, params);
    }

    searchProductLogged(idOneTimeFee: string): Observable<any> {
        return this.get(`fee/product/member/${idOneTimeFee}`, this.defaultEndpointParams);
    }

    getProrateData(prorateSearch: ProrateSearch): Observable<PaymentProrate> {
        return this.post('payments/recurrentfee/prorate', this.defaultEndpointParams, prorateSearch);
    }
}
