import * as moment from 'moment';

export class TimeTableCenter {
    weekDay!: number;
    weekDate!: Date;
    openTime!: string;
    closeTime!: string;

    build(content: any, hourFormat: string): this {
        this.weekDay = content.weekDay;

        this.openTime = moment(content.openTime, 'HH:mm:ss').format(hourFormat);
        this.closeTime = moment(content.closeTime, 'HH:mm:ss').format(hourFormat);

        const now = moment();
        this.weekDate = now.clone().weekday(this.weekDay - 1).toDate();

        return this;
    }
}
