<nz-modal [(nzVisible)]="isVisibleWaitQueue" [nzTitle]="('wait_queue_caption' | translate) + schedule?.activity?.name" [nzFooter]="modalFooter" [nzClosable]="false" *ngIf="canUseWaitQueue">
    <ng-container *nzModalContent>
        <ng-container *ngIf="bookingSetting.isActiveWaitQueueNotification && bookingSetting.isActiveWaitQueueAutomatic">
            <nz-radio-group [(ngModel)]="waitQueueType"> 
                <div nz-row class="wait-option-row">
                    <label nz-radio nzValue="1" class="wait-option">{{'wait_queue_option_notify' | translate }}</label>
                    <div nz-col class="text-info">
                        <span>{{'wait_queue_option_notify_text' | translate }}</span>
                    </div>
                </div>
                <div nz-row class="wait-option-row">
                    <label nz-radio nzValue="0" class="wait-option">{{waitQueueOptionAutomatic | translate: {credits: schedule?.bookingInfo?.amountCredits} }}</label>
                    <div nz-col class="text-info">
                        <span>{{waitQueueOptionAutomaticText | translate:{minutes : bookingSetting.amountMinutesFromActivityWaitQueue} }} </span>
                    </div>
                </div>        
            </nz-radio-group>
        </ng-container>

        <ng-container *ngIf="bookingSetting.isActiveWaitQueueNotification && bookingSetting.isActiveWaitQueueAutomatic == false">
            <span class="wait-option">{{'wait_queue_option_notify' | translate }}</span>
            <span>{{' ' + ('wait_queue_option_notify_text' | translate) }}</span>
        </ng-container>

        <ng-container *ngIf="bookingSetting.isActiveWaitQueueNotification == false && bookingSetting.isActiveWaitQueueAutomatic">
            <span class="wait-option">{{waitQueueOptionAutomatic | translate: {credits: schedule?.bookingInfo?.amountCredits} }}</span>
            <span>{{'  ' + (waitQueueOptionAutomaticText | translate:{minutes : bookingSetting.amountMinutesFromActivityWaitQueue}) }}</span>
        </ng-container>
        
        
    </ng-container>
    <ng-template #modalFooter>
        <button nz-button nzType="default" (click)="hiddeWaitQueue()">
            {{ 'cancel' | translate }}
        </button>
        <button nz-button nzType="primary" [disabled]="waitQueueType==null" (click)="acceptWaitQueue()">
            {{ 'confirm' | translate }}
        </button>
    </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="isVisibleWaitQueue" [nzTitle]="modalTitleError" [nzFooter]="modalFooterError" [nzClosable]="false" *ngIf="!canUseWaitQueue">
    <ng-template #modalTitleError>
        <i nz-icon nzType="question-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#FAAD14'"></i>
        <span>{{ '  ' + ('wait_queue_notification_disabled' | translate) }}</span>
    </ng-template>

    <ng-container *nzModalContent>
        <ng-container>
            <span>{{ 'wait_queue_notification_text_disabled' | translate }}</span>
        </ng-container>        
        
    </ng-container>
    <ng-template #modalFooterError>
        <button nz-button nzType="primary" (click)="hiddeWaitQueue()">
            {{ 'close' | translate }}
        </button>
    </ng-template>
</nz-modal>