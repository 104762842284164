export class ActivityFee {
    id!: number;
    idFee!: number;
    idActivity!: number;
    activity!: any;
    sessionsDay!: number;
    sessionsWeek!: number;
    sessionsMonth!: number;

    build(content: any): this {
        Object.assign(this, content);

        return this;
    }
}
