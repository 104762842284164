import { Component, Input, OnInit } from '@angular/core';
import { BookingSetting } from '@views/booking/models/booking-setting/booking-setting';
import { BookingWaitQueueType } from '@views/booking/enumerates/booking-wait-queue-type';
import { ScheduleInfoBasic } from '@views/booking/models/calendar-full/schedule/schedule-info-basic';

import { BookingService } from '@views/booking/services/booking/booking.service';

import { ProfileService } from '@services/profile/profile.service';
import { finalize } from 'rxjs/operators';
import { CalendarFullService } from '@views/booking/services/calendar/calendar-full.service';

@Component({
    selector: 'app-wait-queue-modal',
    templateUrl: './wait-queue-modal.component.html',
    styleUrls: ['./wait-queue-modal.component.scss']
})
export class WaitQueueModalComponent implements OnInit {
    @Input() schedule!: ScheduleInfoBasic | undefined;

    isVisibleWaitQueue = false;
    canUseWaitQueue = true;

    bookingSetting!: BookingSetting;

    waitQueueType: BookingWaitQueueType | undefined;

    waitQueueOptionAutomatic = 'wait_queue_option_automatic';
    waitQueueOptionAutomaticText = 'wait_queue_option_automatic_text';

    constructor(private bookingService: BookingService,
                calendarService: CalendarFullService,
                profileService: ProfileService){
        calendarService.bookingSetting.subscribe((setting) => {
            this.bookingSetting = setting;
            if (this.bookingSetting.isActiveWaitQueueAutomatic &&
                !this.bookingSetting.isActiveWaitQueueNotification){
                this.waitQueueType = BookingWaitQueueType.automatic;
            }
            if (!this.bookingSetting.isActiveWaitQueueAutomatic &&
                this.bookingSetting.isActiveWaitQueueNotification){
                this.waitQueueType = BookingWaitQueueType.notify;
            }
        });

        this.bookingService.isWaitQueue.subscribe((scheduleToShow) => {
            if (this.schedule) {
                if (this.schedule.id === scheduleToShow?.id){
                    this.isVisibleWaitQueue = true;
                }else{
                    this.isVisibleWaitQueue = false;
                }
            }
        });

        profileService.profileInfo.subscribe((data) => {
            this.canUseWaitQueue = data.wantPush || data.wantEmail;
        });
    }

    ngOnInit(): void {
        if (this.schedule?.bookingInfo?.isPayment && !this.schedule?.isAvailableInMyFee && this.schedule?.bookingInfo?.amountCredits > 0){
            this.waitQueueOptionAutomatic = 'wait_queue_option_automatic_premiun';
            this.waitQueueOptionAutomaticText = 'wait_queue_option_automatic_text_premiun';
        }else{
            this.waitQueueOptionAutomatic = 'wait_queue_option_automatic';
            this.waitQueueOptionAutomaticText = 'wait_queue_option_automatic_text';
        }
    }

    acceptWaitQueue(): void{
        if (this.schedule){
            this.bookingService.setWaitQueue(undefined);
            this.bookingService.waitQueue(this.schedule.id, this.waitQueueType as BookingWaitQueueType)
                .pipe(
                    finalize(() => {
                        this.bookingService.setLoading(false);
                        this.bookingService.setReload(true);
                    })
                )
                .subscribe();
        }
    }

    hiddeWaitQueue(): void{
        this.bookingService.setWaitQueue(undefined);
    }
}
