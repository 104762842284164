import { Component } from '@angular/core';

import { Observable } from 'rxjs';

import { RatesProductService } from '@services/rates/rates-product.service';
import { Product } from '@models/index';

@Component({
    selector: 'app-rates-products',
    templateUrl: './rates-products.component.html',
    styleUrls: ['./rates-products.component.scss']
})
export class RatesProductsComponent {
    products$!: Observable<Product[]>;
    isLoading!: Observable<boolean>;

    constructor(productService: RatesProductService) {
        this.products$ = productService.products;
        this.isLoading = productService.loadingData;
    }
}
