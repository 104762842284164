import { Guid } from 'guid-typescript';

export class StaffBasic {
    id!: number;
    name!: string;
    lastName!: string;
    email!: string;
    token!: Guid;
    urlPhoto!: string;
    mobile!: string;

    build(content: any): StaffBasic {
        Object.assign(this, content);

        return this;
    }

    get nameStaff(): string {
        return `${this.name} ${this.lastName}`;
    }
}
