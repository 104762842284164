import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { PublicProfile } from '@models/public-profile/public-profile';

import { NzAvatarComponent } from 'ng-zorro-antd/avatar';

@Component({
    selector: 'app-public-profile-viewer',
    templateUrl: './public-profile-viewer.component.html',
    styleUrls: ['./public-profile-viewer.component.scss']
})
export class PublicProfileViewerComponent {
    @Input() isVisiblePublicProfile!: boolean;
    @Input() publicProfile: PublicProfile | undefined;
    @Input() bookingPlace: PublicProfile[] | undefined;

    @ViewChildren('avatarComponent')
    public avatarComponent!: QueryList<NzAvatarComponent>;

    @Output() closeEvent = new EventEmitter<boolean>();

    constructor(private router: Router) { }

    close(): void {
        this.isVisiblePublicProfile = false;
        this.closeEvent.next(true);
    }


    showPublicProfile(publicProfile: PublicProfile): void{
        if (publicProfile.publicName == null || publicProfile.guest != null) {
            return;
        }
        this.publicProfile = publicProfile;
    }

    public reScaleAvatar(): void {
        // https://github.com/NG-ZORRO/ng-zorro-antd/issues/8363
        if (this.avatarComponent ){
            this.avatarComponent.toArray().forEach(avatar => avatar['notifyCalc']());
        }
    }
}
