import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { CookieService } from 'ngx-cookie-service';

import { CenterInfo } from '@models/center/info/center-info';
import { CenterLogin } from '@models/center/center-login';
import { TokenSession } from '@models/token/token-session';

import * as moment from 'moment';
import { PaymentsDomainHelper } from '@helpers/payments-domain-helper';
import { TGCustom } from '@enumerates/custom/tg-custom';
import { Constants } from '@views/private/models/constants';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    customApps: number[] = [9252, 9253, 9504];
    customTGCustom = [TGCustom.vidya, TGCustom.tonus];
    private dataStore: { domain: string; mode: string | null } = { domain: '', mode: null };

    isLoggedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isActiveToken());

    readonly isLogged = this.isLoggedSubject.asObservable();

    constructor(private cookieService: CookieService) { }

    getDomain(): string {
        if (this.isModeEmbed()) {
            return this.dataStore.domain;
        } else {
            return this.cookieService.get('domain');
        }
    }

    setDomain(domain: string): void {
        if (this.isModeEmbed()) {
            this.dataStore.domain = domain;
        } else {
            this.cookieService.set('domain', domain, 1, '/');
        }
    }

    isDomainMaster(): boolean {
        return PaymentsDomainHelper.isDomainMaster(this.cookieService.get('domain'));
    }

    deleteDomain(): void {
        this.cookieService.delete('domain', '/');
    }

    getIsCustomDomain(): boolean {
        const isCustomDomain: string = this.cookieService.get('isCustomDomain');
        if (isCustomDomain !== null && isCustomDomain !== '') {
            return isCustomDomain === 'true';
        } else {
            return false;
        }
    }

    setIsCustomDomain(isCustomDomain: boolean): void {
        this.cookieService.set('isCustomDomain', isCustomDomain.toString(), 1, '/');
    }

    getPublicToken(): string {
        return this.getCenterInfo().publicToken;
    }

    getToken(): TokenSession {
        const easyToken = this.cookieService.get('token');
        if (easyToken !== null && easyToken !== '') {
            return JSON.parse(easyToken);
        } else {
            return { token: '', session: false };
        }
    }

    getIsTokenNoSession(): boolean {
        const easyToken = this.cookieService.get('token');
        if (easyToken !== null && easyToken !== '') {
            const token = JSON.parse(easyToken);

            return token !== '' && !token.session;
        } else {
            return false;
        }
    }

    setToken(token: string): Observable<boolean> {
        return new Observable<boolean>(
            observable => {
                const easyToken: TokenSession = { token, session: true };
                this.cookieService.set('token', JSON.stringify(easyToken), 1, '/');
                setTimeout(() => {
                    this.isLoggedSubject.next(true);
                    observable.next(true);
                }, 500);
            }
        );
    }

    deleteToken(): void {
        this.cookieService.delete('token', '/');
    }

    setTokenNoSession(token: string): Observable<boolean> {
        return new Observable<boolean>(
            observable => {
                const easyToken: TokenSession = { token, session: false };
                this.cookieService.set('token', JSON.stringify(easyToken), 1, '/');
                setTimeout(() => {
                    observable.next(true);
                }, 500);
            }
        );
    }

    getIdCenter(): number {
        const center: string = this.cookieService.get('center');
        if (center !== null && center !== '') {
            return JSON.parse(center).id;
        } else {
            return 0;
        }
    }

    setCenter(center: CenterLogin): void {
        this.cookieService.set('center', JSON.stringify(center), 1, '/');
    }

    deleteCenter(): void {
        this.cookieService.delete('center', '/');
    }

    getIdMember(): number {
        const member: string = this.cookieService.get('idUser');
        if (member !== null && member !== '') {
            return +member;
        } else {
            return 0;
        }
    }

    setIdMember(idMember: number): void {
        this.cookieService.set('idUser', idMember.toString(), 1, '/');
    }

    getIdMemberHash(): string {
        return this.cookieService.get('idUserHash');
    }

    setIdMemberHash(idMember: string): void {
        this.cookieService.set('idUserHash', idMember, 1, '/');
    }

    getUserPublicToken(): string {
        return this.cookieService.get('userPublicToken');
    }

    setUserPublicToken(userPublicToken: string): void {
        this.cookieService.set('userPublicToken', userPublicToken, 1, '/');
    }

    getCenterInfo(): CenterInfo {
        const centerInfo = localStorage.getItem('center-info');
        if (centerInfo !== null && centerInfo !== '') {
            return new CenterInfo().build(JSON.parse(centerInfo));
        } else {
            return new CenterInfo();
        }
    }

    setCenterInfo(centerInfo: CenterInfo): void {
        localStorage.setItem('center-info', JSON.stringify(centerInfo));
    }

    getCalendarDate(): moment.Moment {
        const calendarDate = this.cookieService.get('calendar-date');
        if (calendarDate != null && calendarDate !== '') {
            const myDate: moment.Moment = moment(JSON.parse(calendarDate));
            this.cookieService.delete('calendar-date', '/');

            return myDate;
        } else {
            return moment();
        }
    }

    setCalendarDate(calendarDate: moment.Moment): void {
        this.cookieService.set('calendar-date', JSON.stringify(calendarDate), 1, '/');
    }

    getIsLoaded(): boolean {
        const isLoaded = this.cookieService.get('is-loaded');
        if (isLoaded !== null && isLoaded !== '') {
            return isLoaded === 'true';
        } else {
            return false;
        }
    }

    setIsLoaded(isLoaded: boolean): void {
        const expires: Date = moment().add(60, 'minutes').toDate();
        this.cookieService.set('is-loaded', isLoaded.toString(), expires, '/');
    }

    logout(): void {
        this.cookieService.delete('token', '/');
        this.cookieService.delete('center', '/');
        this.cookieService.delete('idUser', '/');
        this.isLoggedSubject.next(false);
    }

    isCustomApp(): boolean {
        const centerInfo = localStorage.getItem('center-info');
        if (centerInfo !== null && centerInfo !== '') {
            const info = new CenterInfo().build(JSON.parse(centerInfo));

            return this.customApps.findIndex(id => info.id === id) !== -1;
        } else {
            return false;
        }
    }

    isActiveDigitalKit(): boolean {
        const centerInfo = localStorage.getItem('center-info');
        if (centerInfo !== null && centerInfo !== '') {
            const info = new CenterInfo().build(JSON.parse(centerInfo));

            return info.isActiveDigitalKit;
        } else {
            return false;
        }
    }

    isCustomTGCustom(): boolean {
        const centerInfo = localStorage.getItem('center-info');
        if (centerInfo !== null && centerInfo !== '') {
            const info = new CenterInfo().build(JSON.parse(centerInfo));

            return this.customTGCustom.findIndex(id => info.idTGCustom === id) !== -1;
        } else {
            return false;
        }
    }

    isTGCustom(tgCustom: TGCustom): boolean {
        const centerInfo = localStorage.getItem('center-info');
        if (centerInfo !== null && centerInfo !== '') {
            const info = new CenterInfo().build(JSON.parse(centerInfo));

            return info.idTGCustom === tgCustom && this.customTGCustom.findIndex(id => info.idTGCustom === id) !== -1;
        } else {
            return false;
        }
    }

    isActiveToken(): boolean {
        const easyToken: TokenSession = this.getToken();

        return easyToken !== null && easyToken.token !== '' && easyToken.session;
    }

    isModeEmbed(): boolean {
        return this.dataStore.mode === Constants.MODE.EMBED;
    }

    setViewMode(route: ActivatedRouteSnapshot): void {
        const mode = route.queryParamMap.get(Constants.MODE.MODE_NAME);
        this.dataStore.mode = mode;
    }
}
