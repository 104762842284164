import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { PublicProfile } from '@models/public-profile/public-profile';
import { NzAvatarComponent } from 'ng-zorro-antd/avatar';

@Component({
    selector: 'app-public-profile-avatar',
    templateUrl: './public-profile-avatar.component.html',
    styleUrls: ['./public-profile-avatar.component.scss']
})
export class PublicProfileAvatarComponent implements OnChanges {
    @Input()
        isVisiblePublicProfile!: boolean;

    @Input()
        profile!: PublicProfile;

    @Input()
        bookingPlace!: PublicProfile[];

    @ViewChild('avatarComponent')
    public avatarComponent!: NzAvatarComponent;

    ngOnChanges(): void {
        this.reScaleAvatar();
    }

    closePublicProfile(): void{
        this.isVisiblePublicProfile = false;
    }

    showPublicProfile(): void{
        if (this.profile.publicName != null && this.profile.publicName !== '' && this.profile.guest == null) {
            this.isVisiblePublicProfile = true;
        }
    }

    public reScaleAvatar(): void {
        // https://github.com/NG-ZORRO/ng-zorro-antd/issues/8363
        setTimeout(() => {
            if (this.avatarComponent) {
                this.avatarComponent['notifyCalc']();
            }
        }, 100);

    }
}
