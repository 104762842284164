import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { take, takeWhile } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';

import { SubscriptionPlanPrice } from '@models/fee/subscription-plan/subscription-plan-price';
import { PaymentFlowState } from '@models/payment-flow/payment-flow-state';
import { SessionService } from '@services/utils/session.service';
import { MemberFeeService } from '@views/private/services/rest/member-fee/member-fee.service';
import { MemberFee } from '@views/private/models/member-fee/member-fee';
import { SubscriptionsInfoService } from '@services/dashboard/subscriptions-info.service';
import { PaymentProcessState } from '@views/payment/models/payment-process/payment-process-state';
import { PaymentProcessStep } from '@views/payment/enumerate/payment-process-step';
import { FeeType } from '@enumerates/fee/fee-type';
import { CONTAINER_FEE } from '@models/fee/fee-injector';
import { SubscriptionPlan } from '@models/fee/subscription-plan/subscription-plan';
import { SignupFormType } from '@enumerates/setting/signup-form-type';

import * as paymentProcessActions from '@views/payment/reducers/payment-process.action';
import { NotificationModalService } from '@services/utils/notification-modal.service';
import * as paymentFlowActions from '../../../reducers/payment-flow/payment-flow.action';

@Component({
    selector: 'app-fee-detail-subscription',
    templateUrl: './fee-detail-subscription.component.html'
})
export class FeeDetailSubscriptionComponent implements OnInit, OnDestroy {
    private isAlive = true;

    subscriptionPlanPrice: SubscriptionPlanPrice = new SubscriptionPlanPrice();
    currency = '$';
    memberFee!: MemberFee | undefined;
    showCancelButton = false;
    isLoadingCancel = false;

    constructor(@Inject(CONTAINER_FEE) public fee: SubscriptionPlan,
                private router: Router,
                private sessionService: SessionService,
                private memberFeeService: MemberFeeService,
                private subscriptionsInfoService: SubscriptionsInfoService,
                private translateService: TranslateService,
                private modal: NzModalService,
                private notificationModalService: NotificationModalService,
                private store: Store<{ paymentProcess: PaymentProcessState; paymentFlow: PaymentFlowState }>) {
        this.currency = sessionService.getCenterInfo()?.currency;
        this.subscriptionPlanPrice = fee.prices[0];
    }

    ngOnInit(): void {
        this.sessionService.isLogged.pipe(take(1)).subscribe((isLogged) => {
            if (isLogged || this.sessionService.getIsTokenNoSession()) {
                this.memberFeeService.memberFees.pipe(takeWhile(() => this.isAlive)).subscribe(
                    fees => {
                        if (fees.length > 0) {
                            if (this.fee && this.fee.type === FeeType.subscription) {
                                this.memberFee = fees.find((c: MemberFee) => c.idFee === this.fee.id);
                                if (this.memberFee != null) {
                                    this.showCancelButton = true;
                                }
                            }
                        }
                    }
                );
            }
        });
    }

    ngOnDestroy(): void {
        this.isAlive = false;
    }

    viewDetails(): void {
        this.router.navigate([`/info/s/${this.fee.id}/${this.subscriptionPlanPrice.id}`]);
    }

    viewRegister(): void {
        if (this.fee.subscriptionPrices.length > 1) {
            this.viewDetails();

            return;
        }

        this.store.dispatch(paymentFlowActions.setSubscriptionPlan({
            idSubscriptionPlan: this.fee.id,
            idSubscriptionPlanPrice: this.subscriptionPlanPrice.id,
            title: this.fee.title,
            price: this.subscriptionPlanPrice.priceWithTax,
            isProrated: this.fee.isProrated,
            subscriptionType: this.subscriptionPlanPrice.type,
            isFreeTrial: this.fee.isFreeTrial,
            daysFreeTrial: this.fee.daysFreeTrial,
            isVirtual: this.fee.isVirtual,
            subtotal: this.subscriptionPlanPrice.subtotal,
            tax: this.fee.tax,
            amountTax: this.subscriptionPlanPrice.amountTax,
            itemPrice: this.subscriptionPlanPrice.itemPrice
        }));

        this.sessionService.isLogged.pipe(take(1)).subscribe((isLogged) => {
            if (isLogged || this.sessionService.getIsTokenNoSession()) {
                if (this.sessionService.getCenterInfo().signupSetting.signupFormType === SignupFormType.multiple && !this.sessionService.getToken().session) {
                    this.store.dispatch(paymentProcessActions.setCurrentStep({ step: PaymentProcessStep.data }));
                } else {
                    this.store.dispatch(paymentProcessActions.setCurrentStep({ step: PaymentProcessStep.confirm }));
                }
                this.router.navigateByUrl('payment/process');
            } else {
                this.router.navigateByUrl('register');
            }
        });
    }

    cancelSubscription(): void {
        if (this.memberFee != null) {
            const centerInfo = this.sessionService.getCenterInfo();

            if (centerInfo.paymentConfiguration.isDisabledCancellationFromApp) {
                const modal = this.modal.warning({
                    nzTitle: this.translateService.instant('subscription_cancel'),
                    nzContent: centerInfo.paymentConfiguration.cancellationInfo,
                    nzCentered: true,
                    nzOkText: 'OK',
                    nzOnOk: () => {
                        modal.destroy();
                    }
                });

                return;
            }

            this.isLoadingCancel = true;
            this.translateService.get(['are_you_sure', 'cancel_subscription_description']).subscribe(
                translates => {
                    this.modal.confirm({
                        nzTitle: translates.are_you_sure,
                        nzContent: translates.cancel_subscription_description,
                        nzOnOk: () => {
                            if (this.memberFee != null) {
                                this.subscriptionsInfoService.cancelSubscription(this.memberFee.idMemberFee).subscribe(
                                    () => {
                                        this.memberFeeService.list();
                                        this.isLoadingCancel = false;
                                    },
                                    error => {
                                        this.isLoadingCancel = false;
                                        this.notifyError(error);
                                    }
                                );
                            }
                        },
                        nzOnCancel: () => this.isLoadingCancel = false
                    });
                }
            );
        }
    }

    private notifyError(error: any): void {
        if (!error.error.errors || error.error.errors.length === 0) {
            return;
        }

        this.notificationModalService.add(this.translateService.instant('attention'), error.error.errors[0].message);
    }
}
