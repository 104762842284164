import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { BookingSetting } from '@views/booking/models/booking-setting/booking-setting';
import { FilterDates } from '@models/utils/filter-dates';
import { DateTimeDto } from '@models/basic/datetime-dto';
import { ScheduleInfoBasic } from '@views/booking/models/calendar-full/schedule/schedule-info-basic';

import { HttpService } from '@services/http.service';
import { CalendarFullDay } from '@views/booking/models/calendar-full/day/calendar-full-day';
import { Guest } from '@views/booking/models/guest/guest';
import { BookingState } from '@views/booking/enumerates/booking-state';
import { SessionService } from '@services/utils/session.service';

@Injectable({
    providedIn: 'root'
})
export class CalendarFullService extends HttpService {
    private daysSubject = new BehaviorSubject<CalendarFullDay[]>([]);
    private bookingSettingSubject = new BehaviorSubject<BookingSetting>(new BookingSetting());
    private guestsSubject = new BehaviorSubject<Guest[]>([]);
    private isLoadingSubject = new BehaviorSubject<boolean>(true);
    private dataStore: { bookingSetting: BookingSetting; days: CalendarFullDay[]; guests: Guest[] }
        = { bookingSetting: new BookingSetting(), days: [], guests: [] };

    readonly days = this.daysSubject.asObservable();
    readonly bookingSetting = this.bookingSettingSubject.asObservable();
    readonly guests = this.guestsSubject.asObservable();
    readonly isLoading = this.isLoadingSubject.asObservable();

    constructor(private sessionService: SessionService, httpClient: HttpClient) {
        super(httpClient);
    }

    listPublic(publicToken: string, filterDates: FilterDates): void {
        this.loading(true);
        this.listPublicSchedules(publicToken, filterDates).subscribe(
            () => {
                this.next();
                this.loading(false);
            }
        );
    }

    listPrivate(filterDates: FilterDates): void {
        this.loading(true);
        this.listPrivateSchedules(filterDates).subscribe(
            () => {
                this.next();
                this.loading(false);
            }
        );
    }

    getDate(publicToken: string, idSchedule: number): Observable<DateTimeDto> {
        const params = new HttpParams({ fromString: `centerToken=${publicToken}` });

        return this.get(`schedules/${idSchedule}/date`, { app: 'booking', version: 2 }, params);
    }

    private listPublicSchedules(publicToken: string, filterDates: FilterDates): Observable<boolean> {
        return new Observable<boolean>(
            observable => {
                const params = new HttpParams({ fromString: `centerToken=${publicToken}&${filterDates.queryString()}&forceViewMap=true` });
                this.get('calendar/totem/notauth', { app: 'booking', version: 2 }, params).subscribe(
                    data => {
                        if (data) {
                            this.buildSchedules(data);
                            this.dataStore.bookingSetting = new BookingSetting().build(data.permission);
                            this.dataStore.guests = [];
                        }

                        observable.next(true);
                    }, () => observable.next(false)
                );
            }
        );
    }

    private buildSchedules(data: any): void{
        this.dataStore.days = [];
        data.calendar.forEach((day: any) => {
            const scheduleDay: CalendarFullDay = {
                dateProgram: day.dateProgram,
                schedules: []
            };
            day.schedules.forEach((schedule: any) => {
                const scheduleInfo = new ScheduleInfoBasic().build(schedule);
                scheduleInfo.dateProgram = day.dateProgram;

                if (this.sessionService.isModeEmbed() && scheduleInfo.bookingState === BookingState.canBook) {
                    // In embedded mode avoid showing the reserve button
                    scheduleInfo.bookingState = BookingState.available;
                }

                scheduleDay.schedules.push(scheduleInfo);
            });
            this.dataStore.days.push(scheduleDay);
        });
    }


    private listPrivateSchedules(filterDates: FilterDates): Observable<boolean> {
        return new Observable<boolean>(
            observable => {
                const params = new HttpParams({ fromString: `${filterDates.queryString()}&includeGuests=true` });
                this.get('calendar/app', { app: 'booking', version: 2.2 }, params).subscribe(
                    data => {
                        if (data) {
                            this.dataStore.days = [];
                            data.calendar.forEach((day: any) => {
                                const scheduleDay: CalendarFullDay = {
                                    dateProgram: day.dateProgram,
                                    schedules: []
                                };
                                day.schedules.forEach((schedule: any) => {
                                    const scheduleInfo = new ScheduleInfoBasic().build(schedule);
                                    scheduleInfo.dateProgram = day.dateProgram;
                                    scheduleDay.schedules.push(scheduleInfo);
                                });
                                this.dataStore.days.push(scheduleDay);
                            });

                            this.dataStore.bookingSetting = new BookingSetting().build(data.permission);
                            this.dataStore.guests = [...data.guests];
                        }

                        observable.next(true);
                    }, () => observable.next(false)
                );
            }
        );
    }

    private next(): void {
        this.daysSubject.next([ ...this.dataStore.days ]);
        this.bookingSettingSubject.next(this.dataStore.bookingSetting);
        this.guestsSubject.next([...this.dataStore.guests]);
    }

    private loading(value: boolean): void {
        this.isLoadingSubject.next(value);
    }
}
