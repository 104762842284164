import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { take, takeWhile } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import { SessionService } from '@services/utils/session.service';
import { BookingPlaceState } from '@views/booking/enumerates/booking-place-state';
import { CategoryMachine } from '@views/booking/enumerates/machines/category-machine';
import { ScheduleInfoBasic } from '@views/booking/models/calendar-full/schedule/schedule-info-basic';
import { MapElementMachine } from '@views/booking/models/map/machine/map-element-machine';
import { RoomFullMap } from '@views/booking/models/map/room-full-map';
import { BookingService } from '@views/booking/services/booking/booking.service';
import { NotificationService } from '@services/utils/notification.service';
import { SeatsState } from '@models/seats/seats-state';

import { PublicProfile } from '@models/public-profile/public-profile';
import { BookingState } from '@views/booking/enumerates/booking-state';
import * as seatsActions from '../../../../reducers/seats/seats.action';

@Component({
    selector: 'app-calendar-map',
    templateUrl: './calendar-map.component.html',
    styleUrls: ['./calendar-map.component.scss']
})
export class CalendarMapComponent implements OnInit, OnDestroy {
    private isAlive = true;

    @Input() schedule!: ScheduleInfoBasic;
    @Input() roomMapDetail!: RoomFullMap | null;
    @Input() bookingPlace!: PublicProfile[];

    @Output() closeEvent = new EventEmitter<boolean>();

    isLogged!: boolean;
    selectedPosition?: string;

    isLoadingGrid = false;
    isLoading!: boolean;
    isVisibleSeats = false;
    showGuestsModal = false;
    idGuest: string | null = null;

    readonly X_SIZE = 54;
    readonly Y_SIZE = 76;
    readonly PADDING = 8;
    readonly ROWS = 5;
    readonly bookingPlaceState = BookingPlaceState;

    constructor(private router: Router,
                sessionService: SessionService,
                private bookingService: BookingService,
                private notificationService: NotificationService,
                private translateService: TranslateService,
                private store: Store<{ seats: SeatsState }>) {
        document.documentElement.style.setProperty('--map-x-size', `${this.X_SIZE}px`);
        document.documentElement.style.setProperty('--map-y-size', `${this.Y_SIZE}px`);
        document.documentElement.style.setProperty('--map-padding', `${this.PADDING}px`);
        document.documentElement.style.setProperty('--map-max-height', `${this.Y_SIZE * this.ROWS}px`);
        sessionService.isLogged.pipe(takeWhile(() => this.isAlive)).subscribe(
            isLogged => {
                this.isLogged = isLogged;
            }
        );
    }

    ngOnInit(): void {
        this.isVisibleSeats = true;
        this.buildMap();
    }

    ngOnDestroy(): void {
        this.isAlive = false;
    }

    book(idSchedule: number): void {
        if (!this.isLogged) {
            this.store.dispatch(seatsActions.setSeat({ idSchedule: this.schedule.id, dateProgram: this.schedule.dateProgram, isMap: true, position: this.selectedPosition }));
            this.translateService.get(['need_login_or_signup']).subscribe(
                translates => {
                    this.notificationService.showInfoMessage(translates.need_login_or_signup);
                }
            );
            this.router.navigateByUrl('/login', { state: { redirectUrl: this.router.url }});

            return;
        }

        if (this.schedule.bookingState === BookingState.multipleBookedAvailable) {
            this.showGuestsModal = true;

            return;
        }

        this.bookAction(idSchedule);
    }

    private bookAction(idSchedule: number): void {
        this.isLoading = true;

        this.bookingService.book(idSchedule, 0, this.selectedPosition, undefined, this.idGuest).subscribe(() => {
            this.bookingService.isLoading.subscribe((isLoading) => {
                this.isLoading = isLoading;
                this.isVisibleSeats = false;
                this.store.dispatch(seatsActions.clean());
            });
        });
    }

    selectSeat(element: MapElementMachine): void {
        if (element.isEnabled && element.state === BookingPlaceState.available && !this.isAlreadyBooked()) {
            if (element.isSelected) {
                element.isSelected = false;
                this.selectedPosition = undefined;
            } else {
                if (this.roomMapDetail) {
                    this.roomMapDetail.positions.forEach(position => position.isSelected = false);
                }
                element.isSelected = true;
                this.selectedPosition = element.id;
            }
        }
    }

    acceptSeatBooking(): void {
        this.book(this.schedule.id);
    }

    cancelSeatBooking(): void {
        this.store.dispatch(seatsActions.clean());
        this.closeEvent.emit(true);
    }

    closeEventEmitterBook(idGuest: string | null): void {
        this.showGuestsModal = false;
        this.idGuest = idGuest;
        if (this.idGuest) {
            this.bookAction(this.schedule.id);
        }
    }

    private isAlreadyBooked(): boolean {
        if (this.schedule.bookingInfo.isMultiple) {
            return false;
        }

        if (this.roomMapDetail) {
            return this.roomMapDetail?.positions.findIndex(t => t.state === BookingPlaceState.bookedForMe) > -1;
        } else {
            return false;
        }
    }

    private buildMap(): void {
        if (!this.roomMapDetail) {
            return;
        }

        const cellMap = [];

        this.roomMapDetail.positions = [...this.roomMapDetail.positions.map(
            position => ({
                ...position,
                icon: position.idCategoryMachine ? CategoryMachine[position.idCategoryMachine] : 'other'
            })
        )];

        this.roomMapDetail.items = [...this.roomMapDetail.items.map(
            item => ({
                ...item,
                icon: 'staff'
            })
        )];

        let h = 0;
        if (this.roomMapDetail.map.numberRows && this.roomMapDetail.map.numberColumns) {
            document.documentElement.style.setProperty('--number-columns-map', `${this.roomMapDetail.map.numberColumns}`);
            for (let i = 0; i < this.roomMapDetail.map.numberRows; i++) {
                const elements = [];
                for (let j = 0; j < this.roomMapDetail.map.numberColumns; j++) {
                    elements.push(h);
                    h++;
                }
                cellMap.push(elements);
            }
        }

        this.roomMapDetail = {
            ...this.roomMapDetail,
            grid: cellMap
        };

        this.selectAutomaticPosition();
    }

    private selectAutomaticPosition(): void {
        this.store.select(data => data.seats).pipe(take(1)).subscribe(
            data => {
                if (data.idSchedule > 0) {
                    const element: MapElementMachine | undefined = this.roomMapDetail?.positions.find(c => c.id === data.position);
                    if (element) {
                        this.selectSeat(element);
                    }
                }
            }
        );
    }
}
