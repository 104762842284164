export enum BookingStateColor {
    '34ACE0' = 0,
    '52C41A' = 1,
    '8E44AD' = 2,
    'FF6319' = 3,
    'C0392B' = 4,
    '34ACE1' = 5,
    '52C41B' = 6,
    '8E44AF' = 7,
    'FF6320' = 8,
    'C0392C' = 9,
    '34ACE2' = 10,
    '52C41C' = 11,
    '8E44AC' = 12,
    'FF6318' = 13,
    'FAAD14' = 14,
    'FAAD13' = 15,
    'FF6321' = 16,
    'FF6322' = 17,
    'FF6323' = 18
}
