import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpService } from '@services/http.service';
import { EndpointParams } from '@models/utils/endpoint-params';

@Injectable({
    providedIn: 'root'
})
export class SecuritySignatureService extends HttpService {
    private readonly defaultEndpointParams: EndpointParams = { app: 'payments', version: 1 };

    getSecurity(dictionary: { [key: string]: string }): Observable<string> {
        return this.post('security/member/signature', this.defaultEndpointParams, dictionary);
    }
}
