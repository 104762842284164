import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { HttpService } from '@services/http.service';
import { EndpointParams } from '@models/utils/endpoint-params';
import { SubscriptionPlan } from '@models/fee/subscription-plan/subscription-plan';

@Injectable({
    providedIn: 'root'
})
export class RatesSubscriptionService extends HttpService {
    private readonly defaultEndpointParams: EndpointParams = { app: 'payments', version: 1 };

    private subscriptionSubject = new BehaviorSubject<SubscriptionPlan[]>([]);
    private isLoadingDataSubject = new BehaviorSubject<boolean>(true);
    private dataStore: { fees: SubscriptionPlan[] } = { fees: [] };

    readonly recurrents = this.subscriptionSubject.asObservable();
    readonly loadingData = this.isLoadingDataSubject.asObservable();

    list(idActivity?: number, idTask?: string): void {
        this.loading(true);
        this.listRates(idActivity, idTask).subscribe(
            () => {
                this.next();
                this.loading(false);
            }
        );
    }

    search(idRates: string): Observable<any> {
        return this.get(`fee/recurrentfee/member/${idRates}/rates`, this.defaultEndpointParams);
    }

    private listRates(idActivity?: number, idTask?: string): Observable<boolean> {
        const url = this.getFilteredUrl(idActivity, idTask);

        return new Observable<boolean>(
            observable => {
                this.get(url, this.defaultEndpointParams).subscribe(
                    data => {
                        this.dataStore.fees = [];
                        data.forEach((payment: any) => {
                            this.dataStore.fees.push(new SubscriptionPlan().build(payment));
                        });

                        this.dataStore.fees.sort((a, b) => a.order - b.order);
                        observable.next(true);
                    }, () => observable.next(false)
                );
            }
        );
    }

    private next(): void {
        this.subscriptionSubject.next([ ...this.dataStore.fees ]);
    }

    private loading(value: boolean): void {
        this.isLoadingDataSubject.next(value);
    }

    private getFilteredUrl(idActivity?: number, idTask?: string): string {
        let url = 'fee/recurrentfee/member';

        if (idActivity) {
            url += `?idActivity=${idActivity}`;
        }

        if (idTask) {
            if (!idActivity) {
                url += '?';
            } else {
                url += '&';
            }

            url += `idTask=${idTask}`;
        }

        return url;
    }
}
