import { Seat } from './seat';

export class SeatRow {
    seats: Seat[];

    constructor() {
        this.seats = [];
    }

    build(seats: Seat[]): SeatRow {
        this.seats = seats;

        return this;
    }
}
