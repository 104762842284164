import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { RatesSubscriptionService } from '@services/rates/rates-subscription.service';
import { MemberFeeService } from '@views/private/services/rest/member-fee/member-fee.service';
import { SubscriptionPlan } from '@models/fee/subscription-plan/subscription-plan';

@Component({
    selector: 'app-rates-subscription',
    templateUrl: './rates-subscription.component.html',
    styleUrls: ['./rates-subscription.component.scss']
})
export class RatesSubscriptionComponent implements OnInit {
    recurrents$!: Observable<SubscriptionPlan[]>;
    isLoading!: Observable<boolean>;

    constructor(subscriptionService: RatesSubscriptionService, private memberFeeService: MemberFeeService) {
        this.recurrents$ = subscriptionService.recurrents;
        this.isLoading = subscriptionService.loadingData;
    }

    ngOnInit(): void {
        this.memberFeeService.list();
    }
}
