import { ApplicationRef, ComponentRef, Injectable, Type } from '@angular/core';

import { Subject } from 'rxjs';

import { Modal, ModalUtil } from '@models/index';

@Injectable()
export class ModalBuilderService {
    constructor(private appRef: ApplicationRef) { }

    /**
     * @NzModalService To use modal confirm, use NzModalService directly
     */
    open<T extends Modal<any, any>>(component: Type<T>, inputs?: any, autoDestroy$?: Subject<boolean>): ModalUtil {
        const modalContainer = document.createElement('div');
        document.getElementsByTagName('body')[0].appendChild(modalContainer);
        const componentRef: ComponentRef<T> = this.appRef.bootstrap(component, modalContainer);
        componentRef.instance.onInjectInputs(inputs);

        return new ModalUtil(componentRef, autoDestroy$);
    }
}
