import { SubscriptionType } from '@enumerates/fee/subscription-type';
import { Tax } from '@models/tax/tax';

export class SubscriptionPlanPrice {
    id!: string;
    price!: number;
    priceWithTax!: number;
    type!: SubscriptionType;
    typeName!: string;
    amountTax!: number;
    subtotal!: number;
    itemPrice!: number;

    build(content: any): this {
        Object.assign(this, content);

        this.typeName = SubscriptionType[this.type];

        return this;
    }

    buildWithTax(content: any, tax?: Tax): this {
        Object.assign(this, content);

        this.typeName = SubscriptionType[this.type];

        this.getTaxes(tax);

        return this;
    }

    private getTaxes(tax?: Tax): void {
        this.subtotal = this.price;
        this.itemPrice = this.subtotal;
        if (!tax || tax.isIncludeInPVP) {
            this.priceWithTax = this.price;
        } else {
            this.priceWithTax = this.price * ((tax.rate / 100) + 1);
        }

        if (tax) {
            const taxRate = (tax.rate / 100) + 1;
            this.subtotal = this.priceWithTax / taxRate;
            this.amountTax = this.subtotal * (tax.rate / 100);

            if (!tax.isIncludeInPVP) {
                this.itemPrice = this.subtotal;
            }
        }
    }
}
