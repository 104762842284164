import { BookingEvent } from './booking-event';
import { TGException } from './tg-exception';

export class BookingErrorEvent {
    bookingActivity!: BookingEvent;
    bookingException!: TGException;

    build(content: string): this {
        const dataParsed = JSON.parse(content);

        if (dataParsed.bookingActivity) {
            this.bookingActivity = new BookingEvent().buildFromData(dataParsed.bookingActivity);
        }

        if (dataParsed.bookingException) {
            this.bookingException = new TGException().build(dataParsed.bookingException);
        }

        return this;
    }
}
