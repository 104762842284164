import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { takeWhile } from 'rxjs/operators';

import { PaymentFlowState } from '@models/payment-flow/payment-flow-state';
import { SessionService } from '@services/utils/session.service';
import { InternationalizationService } from '@services/utils/internationalization/internationalization.service';
import { BookingExceptions } from '../enumerates/booking-exceptions';

import { BookingEvent } from '../models/signalr/booking-event';
import { BookingErrorEvent } from '../models/signalr/booking-error-event';

import { SignalRService } from '../services/signalr/signalr.service';
import { BookingService } from '../services/booking/booking.service';
import { BookingRealtimeService } from '../services/booking/realtime/booking-realtime.service';
import { ScheduleInfoBasic } from '../models/calendar-full/schedule/schedule-info-basic';
import { BookingInfoService } from '../services/booking/info/booking-info.service';
import { BookingInfo } from '../models/booking-info/booking-info';

import * as paymentFlowActions from '../../../reducers/payment-flow/payment-flow.action';

@Component({
    selector: 'app-realtime',
    templateUrl: './realtime.component.html'
})
export class RealTimeComponent implements OnDestroy {
    isAlive = true;
    isLogged!: boolean;
    isVisibleBooked = false;
    isVisibleCanceled = false;
    isVisibleError = false;
    error = '';
    errorCode = 0;
    bookingExceptions = BookingExceptions;
    bookingInfo!: BookingInfo;

    idSchedule!: number;
    schedule!: ScheduleInfoBasic | null;

    dateFormat = this.internationalizationService.getDateFormat();
    hourFormat = this.internationalizationService.getHourFormat();

    constructor(private router: Router,
                private bookingRealtimeService: BookingRealtimeService,
                private sessionService: SessionService,
                private signalRService: SignalRService,
                private notification: NzNotificationService,
                private translateService: TranslateService,
                private modal: NzModalService,
                private bookingService: BookingService,
                private bookingInfoService: BookingInfoService,
                private internationalizationService: InternationalizationService,
                private store: Store<{ paymentFlow: PaymentFlowState }>) {
        sessionService.isLogged.pipe(takeWhile(() => this.isAlive)).subscribe(
            isLogged => {
                if (isLogged) {
                    this.isLogged = true;
                    this.signalRService.startConnection(this.sessionService.getIdCenter(), this.sessionService.getToken().token);
                    this.signalRService.scheduleBookingBooked().pipe(takeWhile(() => this.isAlive)).subscribe(
                        data => this.doBooked(data)
                    );
                    this.signalRService.scheduleBookingCanceled().pipe(takeWhile(() => this.isAlive)).subscribe(
                        () => this.doCancel()
                    );
                    this.signalRService.scheduleBookingError().pipe(takeWhile(() => this.isAlive)).subscribe(
                        data => this.doError(data)
                    );
                } else {
                    if (this.isLogged) {
                        this.signalRService.stopConnection();
                    }

                    this.isLogged = false;
                }
            }
        );

        bookingRealtimeService.schedule.subscribe(
            schedule => this.schedule = schedule
        );
    }

    ngOnDestroy(): void {
        this.isAlive = false;
        if (this.isLogged) {
            this.signalRService.stopConnection();
        }
    }

    doBooked(data: BookingEvent): void {
        this.bookingInfoService.info(data.idBooking).subscribe(
            info => {
                this.bookingInfo = {
                    ...info,
                    amountMinutesToAccess: Math.abs(info.amountMinutesToAccess)
                };
                this.isVisibleBooked = true;
                this.bookingService.setLoading(false);
                this.bookingService.setReload(true);
            }
        );
    }

    closeEvent(event: boolean): void {
        if (event) {
            this.isVisibleBooked = false;
        }
    }

    goHome(): void {
        this.accept();
        this.router.navigate(['/private/dashboard']);
    }

    accept(): void {
        this.isVisibleBooked = false;
        this.bookingService.setReload(true);
        this.bookingRealtimeService.removeSchedule();
    }

    doCancel(): void {
        this.translateService.get(['booking_canceled', 'booking_canceled_successful']).subscribe(
            translates => {
                this.notification.success(
                    translates.booking_canceled,
                    translates.booking_canceled_successful,
                    { nzDuration: 5000 }
                );

                this.bookingService.setLoading(false);
                this.bookingService.setReload(true);
            }
        );
    }

    doError(data: BookingErrorEvent): void {
        this.bookingService.setLoading(false);
        if (data.bookingException.code === this.bookingExceptions.notEnoughCredits) {
            this.translateService.get(['purchase_credits', 'buy_more_credits']).subscribe(
                translates => {
                    this.modal.confirm({
                        nzTitle: translates.purchase_credits,
                        nzContent: translates.buy_more_credits,
                        nzOnOk: () => {
                            this.store.dispatch(paymentFlowActions.setSchedule({ idSchedule: data.bookingActivity.idSchedule }));
                            this.router.navigateByUrl('/private/credits');
                        }
                    });
                }
            );
        } else if (data.bookingException.code >= this.bookingExceptions.limitsInFeeReached
            && data.bookingException.code <= this.bookingExceptions.notFeeFoundCanBuyWithCredits) {

            const myFooter: any[] = [];
            let modal: any;
            if (data.bookingException.actionCredits) {
                myFooter.push({
                    label: data.bookingException.actionCredits,
                    type: 'primary',
                    onClick: () => {
                        this.bookingService
                            .book(data.bookingActivity.idSchedule, data.bookingActivity.bookedSeat,
                                data.bookingActivity.idPosition, true, data.bookingActivity.idGuest).subscribe(() => {
                                modal.destroy();
                            });
                    }
                });
            }

            if (data.bookingException.actionFee) {
                myFooter.push({
                    label: data.bookingException.actionFee,
                    type: 'primary',
                    onClick: () => {
                        this.router.navigateByUrl('rates');
                        modal.destroy();
                    }
                });
            }
            this.translateService.get(['operation_failed']).subscribe(
                translates => {
                    modal = this.modal.create({
                        nzTitle: translates.operation_failed,
                        nzContent: data.bookingException.msg,
                        nzOnOk: () => { },
                        nzFooter: myFooter
                    });
                }
            );
        } else {
            this.translateService.get(['operation_failed']).subscribe(
                translates => {
                    this.modal.confirm({
                        nzTitle: translates.operation_failed,
                        nzContent: data.bookingException.msg,
                        nzOnOk: () => { }
                    });
                }
            );
        }
    }
}
