import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';

import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';

import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { FeeOperation } from '@enumerates/fee/fee-operation';

import { FeeService } from '@services/fee/fee.service.';
import { SessionService } from '@services/utils/session.service';

import { PaymentFlowState } from '@models/payment-flow/payment-flow-state';
import { PaymentProcessStep } from '@views/payment/enumerate/payment-process-step';
import { SignupFormType } from '@enumerates/setting/signup-form-type';
import { MemberFeeService } from '@views/private/services/rest/member-fee/member-fee.service';
import { MemberFee } from '@views/private/models/member-fee/member-fee';
import { SubscriptionsInfoService } from '@services/dashboard/subscriptions-info.service';
import { OneTimeFee, Product, SubscriptionPlan, SubscriptionPlanPrice } from '@models/index';
import { Fee } from '@models/fee/fee';

import * as paymentProcessActions from '@views/payment/reducers/payment-process.action';
import { NotificationModalService } from '@services/utils/notification-modal.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as paymentFlowActions from '../../reducers/payment-flow/payment-flow.action';

@Component({
    selector: 'app-fee-detail-info',
    templateUrl: './fee-detail-info.component.html'
})
export class FeeDetailInfoComponent implements OnInit, OnDestroy {
    private isAlive = true;

    data: Fee = new Fee();
    subscriptionPlanPrice!: SubscriptionPlanPrice;
    memberFee!: MemberFee | undefined;
    showCancelButton = false;
    showButton = true;
    idPrice = '';
    idMemberFee!: string;

    isLoadingData = false;
    isLoadingCancel = false;
    contractingConditions!: SafeHtml;

    private type: FeeOperation = FeeOperation.oneTime;
    readonly currency = this.sessionService.getCenterInfo()?.currency;

    constructor(private readonly route: ActivatedRoute,
                private readonly router: Router,
                private readonly sessionService: SessionService,
                private readonly feeService: FeeService,
                private readonly memberFeeService: MemberFeeService,
                private readonly subscriptionsInfoService: SubscriptionsInfoService,
                private readonly location: Location,
                private readonly translateService: TranslateService,
                private readonly modal: NzModalService,
                private readonly notificationModalService: NotificationModalService,
                private readonly store: Store<{ paymentFlow: PaymentFlowState }>,
                private readonly sanitizer: DomSanitizer,
                private readonly nzModalService: NzModalService) {
        const nav = this.router.getCurrentNavigation();

        if (nav && nav.extras.state) {
            this.showButton = nav.extras.state.showButton;
            this.idMemberFee = nav.extras.state.idMemberFee;
        }
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            const idFeeParam: string | null = params.get('idFee');
            const idPriceParam: string | null = params.get('idPrice');
            this.type = params.get('type') as FeeOperation;

            if (idFeeParam != null) {
                switch (this.type) {
                case FeeOperation.oneTime:
                    break;
                case FeeOperation.subscription:
                    break;
                case FeeOperation.product:
                    break;
                default:
                    this.router.navigateByUrl('unauthorized');
                    break;
                }

                if (this.type === FeeOperation.oneTime) {
                    this.loadOneTimeFee(idFeeParam);
                } else if (this.type === FeeOperation.subscription) {
                    this.loadSubscription(idFeeParam, idPriceParam);
                } else if (this.type === FeeOperation.product) {
                    this.loadProduct(idFeeParam);
                } else {
                    this.router.navigateByUrl('unauthorized');
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.isAlive = false;
    }

    viewRegister(): void {
        switch (this.type) {
        case FeeOperation.oneTime:
            this.store.dispatch(paymentFlowActions.setOneTimeFee({
                idOneTimeFee: this.data.id,
                title: this.data.title,
                price: this.data.oneTimePrice,
                subtotal: this.data.subtotal,
                tax: this.data.tax,
                amountTax: this.data.amountTax,
                itemPrice: this.data.itemPrice
            }));
            break;
        case FeeOperation.subscription:
            this.store.dispatch(paymentFlowActions.setSubscriptionPlan({
                idSubscriptionPlan: this.data.id,
                idSubscriptionPlanPrice: this.subscriptionPlanPrice.id,
                title: this.data.title,
                price: this.subscriptionPlanPrice.priceWithTax,
                isProrated: this.data.isProrated,
                subscriptionType: this.subscriptionPlanPrice.type,
                isFreeTrial: this.data.isFreeTrial,
                daysFreeTrial: this.data.daysFreeTrial,
                isVirtual: this.data.isVirtual,
                subtotal: this.subscriptionPlanPrice.subtotal,
                tax: this.data.tax,
                amountTax: this.subscriptionPlanPrice.amountTax,
                itemPrice: this.subscriptionPlanPrice.itemPrice
            }));
            break;
        case FeeOperation.product:
            this.store.dispatch(paymentFlowActions.setProduct({
                idProduct: this.data.id,
                title: this.data.title,
                price: this.data.productPrice,
                subtotal: this.data.subtotal,
                tax: this.data.tax,
                amountTax: this.data.amountTax,
                itemPrice: this.data.itemPrice
            }));
            break;
        default:
            this.router.navigateByUrl('unauthorized');
            break;
        }

        this.sessionService.isLogged.pipe(take(1)).subscribe((isLogged) => {
            if (isLogged || this.sessionService.getIsTokenNoSession()) {
                if (this.sessionService.getCenterInfo().signupSetting.signupFormType === SignupFormType.multiple && !this.sessionService.getToken().session) {
                    this.store.dispatch(paymentProcessActions.setCurrentStep({ step: PaymentProcessStep.data }));
                } else {
                    this.store.dispatch(paymentProcessActions.setCurrentStep({ step: PaymentProcessStep.confirm }));
                }
                this.router.navigateByUrl('payment/process');
            } else {
                this.router.navigateByUrl('register');
            }
        });

    }

    cancelSubscription(): void {
        if (this.memberFee != null) {
            const centerInfo = this.sessionService.getCenterInfo();

            if (centerInfo.paymentConfiguration.isDisabledCancellationFromApp) {
                const modal = this.modal.warning({
                    nzTitle: this.translateService.instant('subscription_cancel'),
                    nzContent: centerInfo.paymentConfiguration.cancellationInfo,
                    nzCentered: true,
                    nzOkText: 'OK',
                    nzOnOk: () => {
                        modal.destroy();
                    }
                });

                return;
            }

            this.isLoadingCancel = true;
            this.translateService.get(['are_you_sure', 'cancel_subscription_description']).subscribe(
                translates => {
                    this.modal.confirm({
                        nzTitle: translates.are_you_sure,
                        nzContent: translates.cancel_subscription_description,
                        nzOnOk: () => {
                            if (this.memberFee != null) {
                                this.subscriptionsInfoService.cancelSubscription(this.memberFee.idMemberFee).subscribe(
                                    () => {
                                        this.memberFeeService.list();
                                        this.isLoadingCancel = false;
                                    },
                                    error => {
                                        this.isLoadingCancel = false;
                                        this.notifyError(error);
                                    }
                                );
                            }
                        },
                        nzOnCancel: () => this.isLoadingCancel = false
                    });
                }
            );
        }
    }

    turnBack(): void {
        this.location.back();
    }

    private loadOneTimeFee(idFeeParam: string): void {
        this.isLoadingData = true;
        if (this.idMemberFee != null){
            this.memberFeeService.searchMemberOneTimeFee(this.idMemberFee)
                .subscribe(res => {
                    this.memberFee = res;
                });
        }
        if (this.sessionService.getCenterInfo().isPayment) {
            this.feeService.searchOneTimeLogged(idFeeParam).subscribe(
                data => {
                    this.data = new OneTimeFee().build(data);
                    this.isLoadingData = false;
                }
            );
        } else {
            this.feeService.searchOneTime(idFeeParam, this.sessionService.getPublicToken()).subscribe(
                data => {
                    this.data = new OneTimeFee().build(data);
                    this.isLoadingData = false;
                }
            );
        }
    }

    private loadSubscription(idFeeParam: string, idPriceParam: string | null): void {
        if (this.idMemberFee != null){
            this.showCancelButton = true;
            this.memberFeeService.searchMemberSubscription(this.idMemberFee)
                .subscribe(res => {
                    this.memberFee = res;
                });
        }
        if (idPriceParam != null) {
            this.idPrice = idPriceParam;
            this.isLoadingData = true;
            if (this.sessionService.getCenterInfo().isPayment) {
                this.feeService.searchSubscriptionLogged(idFeeParam).subscribe(
                    data => {
                        const subscriptionPlan = new SubscriptionPlan().build(data);
                        this.data = subscriptionPlan;
                        const subscriptionPlanPrice = subscriptionPlan.subscriptionPrices.find(t => t.id === this.idPrice);
                        if (subscriptionPlanPrice) {
                            this.subscriptionPlanPrice = subscriptionPlanPrice;
                        }
                        this.isLoadingData = false;
                    }
                );
            } else {
                this.feeService.searchSubscription(idFeeParam, this.sessionService.getPublicToken()).subscribe(
                    data => {
                        const subscriptionPlan = new SubscriptionPlan().build(data);
                        this.data = subscriptionPlan;
                        const subscriptionPlanPrice = subscriptionPlan.subscriptionPrices.find(t => t.id === this.idPrice);
                        if (subscriptionPlanPrice) {
                            this.subscriptionPlanPrice = subscriptionPlanPrice;
                        }
                        this.isLoadingData = false;
                    }
                );
            }
        } else {
            this.router.navigateByUrl('unauthorized');
        }
    }

    private loadProduct(idFeeParam: string): void {
        this.isLoadingData = true;
        if (this.sessionService.getCenterInfo().isPayment) {
            this.feeService.searchProductLogged(idFeeParam).subscribe(
                data => {
                    this.data = new Product().build(data);
                    this.isLoadingData = false;
                }
            );
        } else {
            this.feeService.searchProduct(idFeeParam, this.sessionService.getPublicToken()).subscribe(
                data => {
                    this.data = new Product().build(data);
                    this.isLoadingData = false;
                }
            );
        }
    }

    private notifyError(error: any): void {
        if (!error.error.errors || error.error.errors.length === 0) {
            return;
        }

        this.notificationModalService.add(this.translateService.instant('attention'), error.error.errors[0].message);
    }

    showContractingCondition(template: TemplateRef<object>): void{
        this.memberFeeService.getContractingConditions(this.memberFee?.contractingConditionsUrl ?? '')
            .subscribe(res => {
                this.contractingConditions = this.sanitizer.bypassSecurityTrustHtml(res.value);
                this.nzModalService.confirm({
                    nzContent: template,
                    nzOkText: null,
                    nzCancelText: null,
                    nzWidth: 620,
                    nzIconType: undefined
                });
            });
    }
}
