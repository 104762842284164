import { WebPageDomainType } from '@enumerates/web-page/webpage-domain-type';

export class Domain {
    public url!: string;
    public type!: WebPageDomainType;

    constructor() {
        this.url = '';
    }

    build(content: any): this {
        Object.assign(this, content);

        return this;
    }
}
