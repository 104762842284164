import { Injectable } from '@angular/core';

import { HttpService } from '@services/http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { StringDto } from '@models/basic/string-dto';
import { EndpointParams } from '@models/utils/endpoint-params';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionsInfoService extends HttpService {
    private readonly defaultEndpointParams: EndpointParams = { app: 'payments', version: 1 };

    private isLoadingDataSubject = new BehaviorSubject<boolean>(true);

    readonly loadingData = this.isLoadingDataSubject.asObservable();

    create(): Observable<StringDto> {
        this.loading(true);

        return new Observable<StringDto>(
            observable => {
                this.post('subscription/member',  this.defaultEndpointParams).subscribe(
                    data => {
                        this.loading(false);
                        observable.next(data);
                    },
                    () => {
                        this.loading(false);
                    }
                );
            }
        );
    }

    cancelSubscription(idMemberFee: string): Observable<boolean> {
        return this.delete(`subscription/member/${idMemberFee}`,  this.defaultEndpointParams, this.noShowErrorNotification);
    }

    private loading(value: boolean): void {
        this.isLoadingDataSubject.next(value);
    }
}
