import { Component, inject } from '@angular/core';

import { Modal } from '@models/index';
import { BookingMemberGuestService } from '@views/booking/services/booking/member/booking-member.service';

@Component({
    selector: 'app-cancel-books',
    templateUrl: './cancel-books.component.html'
})
export class CancelBooksComponent extends Modal<string> {
    private readonly bookingMemberGuestService = inject(BookingMemberGuestService);
    showModal = false;
    idGuest!: string;

    readonly bookingMembersGuests$ = this.bookingMemberGuestService.bookingMembersGuests;

    onInjectInputs(inputs?: any): void {
        this.showModal = true;
        this.bookingMemberGuestService.list(inputs.idSchedule);
    }

    close(): void {
        this.showModal = false;
        this.closing();
    }

    confirm(): void {
        this.saving(this.idGuest);
    }
}
