import { PlatformType } from '@views/booking/enumerates/platform-type';
import { BookingMemberEvent } from './booking-member-event';

export class BookingCanceledEvent {
    idSchedule!: number;
    platformType!: PlatformType;
    idCenter!: number;
    bookings!: BookingMemberEvent[];
    reason!: string;

    constructor() { }

    build(content: string): BookingCanceledEvent {
        const dataParsed = JSON.parse(content);

        Object.assign(this, dataParsed);

        this.bookings = [];
        if (dataParsed.bookings) {
            dataParsed.bookings.forEach((bookingMemberEvent: any) => {
                this.bookings.push(new BookingMemberEvent().build(bookingMemberEvent));
            });
        }

        return this;
    }
}
