<div class="error-background">
    <div class="error-body">
        <p class="error-title">
            {{ 'error_404' | translate }}
        </p>

        <img alt="Error404" src="assets/imgs/error404.svg" />

        <p class="error-subtitle">
            {{ 'error_404_title' | translate }}
        </p>

        <p class="error-description">
            {{ 'error_404_description' | translate }}
        </p>
    </div>
</div>