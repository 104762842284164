import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { BookingState } from '@views/booking/enumerates/booking-state';

import { VideoHelper } from '@helpers/video-helper';

import { CenterInfo } from '@models/center/info/center-info';

import { BookingService } from '@views/booking/services/booking/booking.service';
import { SessionService } from '@services/utils/session.service';
import { InternationalizationService } from '@services/utils/internationalization/internationalization.service';
import { finalize } from 'rxjs/operators';
import { ScheduleInfoBasic } from '@views/booking/models/calendar-full/schedule/schedule-info-basic';
import { ModalBuilderService } from '@services/index';
import { Subject } from 'rxjs';
import { BookingInfo } from '@views/booking/models/booking-info/booking-info';
import * as moment from 'moment';
import { BookingSetting } from '@views/booking/models/booking-setting/booking-setting';
import { CancelBooksComponent } from '../../cancel-books/cancel-books.component';
import { ScheduleInfoComponent } from '../schedule-info/schedule-info.component';

@Component({
    selector: 'app-calendar-detail-old',
    templateUrl: './calendar-detail-old.component.html',
    styleUrls: ['./calendar-detail-old.component.scss']
})
export class CalendarDetailOldComponent implements OnDestroy {
    @Input() schedule!: ScheduleInfoBasic;
    @Input() bookingSetting!: BookingSetting | null;

    idGuest: string | null = null;
    isLoading!: boolean;
    bookingStates = BookingState;
    hourFormat: string;

    isCustomApp = false;

    private destroy$ = new Subject<boolean>();

    constructor(private router: Router,
                private sessionService: SessionService,
                private bookingService: BookingService,
                private internationalizationService: InternationalizationService,
                private modalBuilderService: ModalBuilderService) {
        this.isCustomApp = sessionService.isCustomApp();
        this.hourFormat = this.internationalizationService.getHourFormat();
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    cancel(idSchedule: number): void {
        this.isLoading = true;
        this.bookingService.cancel(idSchedule, null).subscribe(() => {
            this.bookingService.isLoading.subscribe((isLoading) => {
                this.isLoading = isLoading;
            });
        });
    }

    cancelMultiple(idSchedule: number): void {
        const modalUtil = this.modalBuilderService.open(CancelBooksComponent, { idSchedule }, this.destroy$);
        modalUtil.onResult.subscribe(
            idGuest => {
                this.idGuest = idGuest;
                this.cancel(this.schedule.id);
            }
        );
    }

    view(urlStreaming: string): void {
        let urlFinal: string;
        if (urlStreaming != null && urlStreaming !== '') {
            urlFinal = urlStreaming;
        } else {
            const centerInfo: CenterInfo = this.sessionService.getCenterInfo();
            urlFinal = centerInfo.bookingOptions.streamingChannel;
        }

        this.bookingService.validate(this.schedule.id, this.sessionService.getIdMember())
            .pipe(
                finalize(() => {
                    if (VideoHelper.isGoogleOrMicrosoft(urlFinal)) {
                        window.open(urlFinal, '_blank');
                    } else{
                        this.isLoading = true;
                        this.router.navigateByUrl('/private/booking/streaming', { state: { urlStreaming: urlFinal } });
                    }
                })
            )
            .subscribe();
    }

    viewDetail(): void {
        const info: BookingInfo = {
            dateProgram: this.schedule.dateProgram,
            timeStart: this.schedule.timeStart,
            positionName: '',
            duration: this.schedule.duration,
            room: this.schedule.room.room,
            amountMinutesToAccess: Math.abs(this.schedule.accessInfo.amountMinutesToAccessWithBookingFrom),
            maxDateToCancel: moment(`${this.schedule.dateProgram} ${this.schedule.timeStart}`, 'YYYY-MM-DD HH:mm')
                .subtract(this.bookingSetting?.amountMinutesOfCancelBookings, 'minutes').toDate(),
            isPayment: this.schedule.bookingInfo.isPayment,
            bookingPlace: this.schedule.bookedMemberResumen,
            viewDetail: true,
            details: {
                activityName: this.schedule.activity.name,
                staff: this.schedule.staff
            }
        };
        const modalUtil = this.modalBuilderService.open(ScheduleInfoComponent, { schedule: this.schedule, bookingInfo: info }, this.destroy$);
        modalUtil.onResult.subscribe();
    }

    showModalWaitQueue(): void{
        this.bookingService.setWaitQueue(this.schedule);
        this.bookingService.isLoading.subscribe((isLoading) => {
            this.isLoading = isLoading;
        });
    }

    cancelWaitQueue(): void{
        this.isLoading = true;
        this.bookingService.cancelWaitQueue(this.schedule.id)
            .pipe(
                finalize(() => {
                    this.bookingService.setLoading(false);
                    this.bookingService.setReload(true);
                })
            )
            .subscribe();
    }
}
