<div class="error-background">
    <div class="error-body">
        <p class="error-title">
            {{ 'error_401' | translate }}
        </p>

        <img alt="Error404" src="assets/imgs/error401.svg" />

        <p class="error-subtitle">
            {{ 'no_access' | translate }}
        </p>

        <p class="error-description">
            {{ 'error_401_description' | translate }}
        </p>
    </div>
</div>