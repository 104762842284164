import { Component } from '@angular/core';

import { Observable } from 'rxjs';

import { RatesSingleService } from '@services/rates/rates-single.service';
import { OneTimeFee } from '@models/fee/one-time/one-time-fee';

@Component({
    selector: 'app-rates-single-payment',
    templateUrl: './rates-single-payment.component.html',
    styleUrls: ['./rates-single-payment.component.scss']
})
export class RatesSinglePaymentComponent {
    oneTimeFees$!: Observable<OneTimeFee[]>;
    isLoading!: Observable<boolean>;

    constructor(singleService: RatesSingleService) {
        this.oneTimeFees$ = singleService.oneTimeFees;
        this.isLoading = singleService.loadingData;
    }
}
