import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { RatesSubscriptionService } from '@services/rates/rates-subscription.service';
import { RatesSingleService } from '@services/rates/rates-single.service';
import { SessionService } from '@services/utils/session.service';
import { RatesProductService } from '@services/rates/rates-product.service';

@Component({
    selector: 'app-rates',
    templateUrl: './rates.component.html'
})
export class RatesComponent implements OnInit {
    actualTab = 1;
    singlePaymentTxt = 'single_payment';
    subscriptionTxt = 'subscription';
    idActivity!: number;
    idTask!: string;
    showProducts = true;

    constructor(private router: Router,
                private ratesSubscriptionService: RatesSubscriptionService,
                private ratesSingleService: RatesSingleService,
                private ratesProductService: RatesProductService,
                private sessionService: SessionService) {
        const navState = this.router.getCurrentNavigation()?.extras?.state ?? history.state;
        if (navState) {
            this.idActivity = navState.idActivity;
            this.idTask = navState.idTask;
            this.showProducts = !this.idActivity && !this.idTask;
        }
    }

    ngOnInit(): void {
        this.loadData();
        if (this.sessionService.isCustomTGCustom()){
            this.singlePaymentTxt = 'singlePayment_txt_alternative';
            this.subscriptionTxt = 'subscription_txt_alternative';
        }
    }

    private loadData(): void {
        this.ratesSubscriptionService.list(this.idActivity, this.idTask);
        this.ratesSingleService.list(this.idActivity, this.idTask);
        this.ratesProductService.list();
    }
}
