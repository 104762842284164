import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { HttpService } from '@services/http.service';
import { EndpointParams } from '@models/utils/endpoint-params';
import { PaymentCreateResponse } from '@models/buy/response/payment-create-response';
import { Product } from '@models/index';
import { ProductBuy } from '@views/private/models/member-fee/product-buy';

@Injectable({
    providedIn: 'root'
})
export class RatesProductService extends HttpService {
    private readonly defaultEndpointParams: EndpointParams = { app: 'payments', version: 1 };

    private productSubject = new BehaviorSubject<Product[]>([]);
    private isLoadingDataSubject = new BehaviorSubject<boolean>(true);
    private dataStore: { fees: Product[] } = { fees: [] };

    readonly products = this.productSubject.asObservable();
    readonly loadingData = this.isLoadingDataSubject.asObservable();

    list(): void {
        this.loading(true);
        this.listFees().subscribe(
            () => {
                this.next();
                this.loading(false);
            }
        );
    }

    search(idProduct: string): Observable<any> {
        return this.get(`fee/product/member/${idProduct}`, this.defaultEndpointParams);
    }

    create(productBuy: ProductBuy): Observable<PaymentCreateResponse> { // TODO: change buy
        return this.post('fee/product/member', this.defaultEndpointParams, productBuy);
    }

    createManual(productBuyManual: ProductBuy): Observable<boolean> {
        return this.post('fee/product/member/manual', this.defaultEndpointParams, productBuyManual);
    }

    private listFees(): Observable<boolean> {
        return new Observable<boolean>(
            observable => {
                this.get('fee/product/member', this.defaultEndpointParams).subscribe(
                    data => {
                        this.dataStore.fees = [];
                        data.forEach((payment: any) => {
                            this.dataStore.fees.push(new Product().build(payment));
                        });

                        this.dataStore.fees.sort((a, b) => a.order - b.order);
                        observable.next(true);
                    }, () => observable.next(false)
                );
            }
        );
    }

    private next(): void {
        this.productSubject.next([ ...this.dataStore.fees ]);
    }

    private loading(value: boolean): void {
        this.isLoadingDataSubject.next(value);
    }
}
