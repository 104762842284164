import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { HttpService } from '@services/http.service';
import { OneTimeFeeBuy } from '@views/private/models/member-fee/onetime-fee-buy';
import { EndpointParams } from '@models/utils/endpoint-params';
import { OneTimeFee } from '@models/fee/one-time/one-time-fee';
import { PaymentCreateResponse } from '@models/buy/response/payment-create-response';

@Injectable({
    providedIn: 'root'
})
export class RatesSingleService extends HttpService {
    private readonly defaultEndpointParams: EndpointParams = { app: 'payments', version: 1 };

    private oneTimeFeeSubject = new BehaviorSubject<OneTimeFee[]>([]);
    private isLoadingDataSubject = new BehaviorSubject<boolean>(true);
    private dataStore: { fees: OneTimeFee[] } = { fees: [] };

    readonly oneTimeFees = this.oneTimeFeeSubject.asObservable();
    readonly loadingData = this.isLoadingDataSubject.asObservable();

    list(idActivity?: number, idTask?: string): void {
        this.loading(true);
        this.listFees(idActivity, idTask).subscribe(
            () => {
                this.next();
                this.loading(false);
            }
        );
    }

    search(idOneTimeFee: string): Observable<any> {
        return this.get(`fee/onetime/member/${idOneTimeFee}`, this.defaultEndpointParams);
    }

    create(oneTimeFeeBuy: OneTimeFeeBuy): Observable<PaymentCreateResponse> {
        return this.post('fee/onetime/member', this.defaultEndpointParams, oneTimeFeeBuy);
    }

    createManual(oneTimeFeeBuyManual: OneTimeFeeBuy): Observable<boolean> {
        return this.post('fee/onetime/member/manual', this.defaultEndpointParams, oneTimeFeeBuyManual);
    }

    private listFees(idActivity?: number, idTask?: string): Observable<boolean> {
        const url = this.getFilteredUrl(idActivity, idTask);

        return new Observable<boolean>(
            observable => {
                this.get(url, this.defaultEndpointParams).subscribe(
                    data => {
                        this.dataStore.fees = [];
                        data.forEach((payment: any) => {
                            this.dataStore.fees.push(new OneTimeFee().build(payment));
                        });

                        this.dataStore.fees.sort((a, b) => a.order - b.order);
                        observable.next(true);
                    }, () => observable.next(false)
                );
            }
        );
    }

    private next(): void {
        this.oneTimeFeeSubject.next([ ...this.dataStore.fees ]);
    }

    private loading(value: boolean): void {
        this.isLoadingDataSubject.next(value);
    }

    private getFilteredUrl(idActivity?: number, idTask?: string): string {
        let url = 'fee/onetime/member';

        if (idActivity) {
            url += `?idActivity=${idActivity}`;
        }

        if (idTask) {
            if (!idActivity) {
                url += '?';
            } else {
                url += '&';
            }

            url += `idTask=${idTask}`;
        }

        return url;
    }
}
