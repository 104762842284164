<div nz-row top-16 class="detail" *ngIf="schedule">
    <div nz-col [nzXl]="4" [nzSm]="6" [nzXs]="24" class="time" [style.border-color]="'#'+schedule.activity.colorScheduler">
        <div nz-row class="time-row" nzAlign="middle">
            <div nz-col [nzXl]="{ span: 24, offset: 0 }" [nzSm]="{ span: 24, offset: 0 }" [nzXs]="{ span: 13, offset: 1 }" text-center class="flex">
                <div class="schedule-time">
                    <i nz-icon nzType="clock-circle" nzTheme="outline"></i>
                    <p class="text-ellipsis" nz-tooltip family-regular [nzTooltipTitle]="(schedule.timeStartDate | date:hourFormat) + ' - ' + (schedule.timeEndDate | date:hourFormat)">
                        {{ schedule.timeStartDate | date:hourFormat }} - {{ schedule.timeEndDate | date:hourFormat }}
                    </p>
                </div>
            </div>
            <div nz-col [nzXs]="9" text-right class="status status-up">
                <nz-badge [nzColor]="'#'+schedule.bookingStateColor" [nzText]="schedule.orderBookingWaitText != null ? schedule.orderBookingWaitText : (schedule.bookingStateName | translate)">
                </nz-badge>
            </div>
        </div>
        <div nz-row class="time-row">
            <div nz-col [nzXl]="{ span: 24, offset: 0 }" [nzSm]="{ span: 24, offset: 0 }" [nzXs]="{ span: 23, offset: 1 }" text-center class="flex">
                <ng-container *ngIf="!isCustomApp">
                    <i nz-icon nzType="sketch" nzTheme="outline" *ngIf="schedule.bookingInfo.isPayment" nz-tooltip [nzTooltipTitle]="'payment_activity' | translate"></i>
                </ng-container>
                <i nz-icon nzType="play-circle" nzTheme="outline" *ngIf="schedule.bookingInfo.isStreaming" nz-tooltip [nzTooltipTitle]="'streaming_activity' | translate"></i>
                <i nz-icon nzType="star" nzTheme="fill" *ngIf="schedule.isFavorite" nz-tooltip [nzTooltipTitle]="'favorite_activity' | translate"></i>
            </div>
        </div>
    </div>
    <div nz-col [nzXl]="12" [nzSm]="11" [nzXs]="24" class="name">
        <div nz-row class="name-row" nzAlign="middle">
            <div nz-col nzSpan="22" nzOffset="1">
                <div class="name-activity">
                    <h1>{{ schedule.activity.name }}</h1>
                </div>
            </div>
        </div>
        <div nz-row class="name-row" nzAlign="middle">
            <div nz-col nzSpan="22" nzOffset="1">
                <div class="name-level">
                    <p>{{ 'level' | translate }}: {{ schedule.scaleDifficulty.name }}</p>
                </div>
            </div>
        </div>
    </div>
    <div nz-col [nzXl]="8" [nzSm]="7" [nzXs]="24" class="actions">        
        <div nz-row class="actions-row status-down" nzAlign="middle">
            <div nz-col [nzXl]="{ span: 23, offset: 0 }" [nzSm]="{ span: 23, offset: 0 }" [nzXs]="{ span: 22, offset: 1 }" text-right class="status">
                <nz-badge [nzColor]="'#'+schedule.bookingStateColor" [nzText]="schedule.orderBookingWaitText != null ? schedule.orderBookingWaitText : (schedule.bookingStateName | translate)"></nz-badge>
            </div>
        </div>
        
        <div nz-row class="actions-row" nzAlign="bottom">
            <div nz-col [nzXl]="{ span: 23, offset: 0 }" [nzSm]="{ span: 23, offset: 0 }" [nzXs]="{ span: 22, offset: 1 }">
                <button nz-button nzBlock nzType="default" (click)="viewDetail()">{{ 'view_details' | translate }}</button>                
            </div>

            <ng-container *ngIf="schedule.bookingState == bookingStates.streamingAvailable">
                <div nz-col [nzXl]="{ span: 23, offset: 0 }" [nzSm]="{ span: 23, offset: 0 }" [nzXs]="{ span: 13, offset: 1 }">
                    <button nz-button nzBlock nzType="primary" (click)="view(schedule.bookingInfo.urlStreaming)" >
                        {{ 'visualize' | translate }}
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="schedule.bookingState == bookingStates.streamingSoon">
                <div nz-col [nzXl]="{ span: 23, offset: 0 }" [nzSm]="{ span: 23, offset: 0 }" [nzXs]="{ span: 13, offset: 1 }">
                    <button nz-button nzBlock nzType="primary" [disabled]="true">
                        {{ 'on_hold' | translate }}
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="schedule.bookingState == bookingStates.waitQueueAvailable">
                <div nz-col [nzXl]="{ span: 23, offset: 0 }" [nzSm]="{ span: 23, offset: 0 }" [nzXs]="{ span: 13, offset: 1 }">
                    <button nz-button nzBlock nzType="primary" (click)="showModalWaitQueue()"  [nzLoading]="isLoading">
                        {{ schedule.bookingStateText }}
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="schedule.bookingState == bookingStates.inWaitQueue">
                <div nz-col [nzXl]="{ span: 23, offset: 0 }" [nzSm]="{ span: 23, offset: 0 }" [nzXs]="{ span: 13, offset: 1 }">
                    <button nz-button nzBlock nzType="default" nz-popconfirm [nzPopconfirmTitle]="'are_you_sure_cancel_wait_queue' | translate"
                    (nzOnConfirm)="cancelWaitQueue()" [nzLoading]="isLoading">
                        {{ schedule.bookingStateText }}
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<app-wait-queue-modal [schedule]="schedule"></app-wait-queue-modal>

<ng-template #indicatorTemplate><i nz-icon nzType="loading" [nzTheme]="'outline'"
    style="font-size: 24px; display: block;"></i>{{ 'loading' | translate }}...
</ng-template>