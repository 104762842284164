<nz-spin class="nopadding-spin" [nzIndicator]="indicatorTemplate" [nzSpinning]="isLoadingGrid">
    <ng-container *ngIf="roomMapDetail">
        <div nz-row>
            <div nz-col [nzSpan]="24" text-center class="heading-4">
                {{ 'select_a_empty_seat' | translate }}
            </div>
        </div>
        <div nz-row top-40>
            <div nz-col [nzSpan]="24" style="overflow: auto;">
                <div class="map-grid">
                    <div class="map-body">
                        <ng-container *ngFor="let row of roomMapDetail.grid; let i=index">
                            <ng-container *ngFor="let cell of row; let j=index">
                                <div class="cell"></div>
                            </ng-container>                        
                        </ng-container>
                        <ng-container *ngFor="let position of roomMapDetail.positions">
                            <div [ngStyle]="{ 'position': 'absolute', 'top': (position.positionY * Y_SIZE) + PADDING + 'px', 'left': (position.positionX * X_SIZE) + PADDING + 'px'  }">
                                <app-public-profile-avatar *ngIf="position.state === bookingPlaceState.booked || position.state === bookingPlaceState.bookedByGuest" [profile]="position.memberPublicProfile" [bookingPlace]="bookingPlace"></app-public-profile-avatar>
                                <div *ngIf="position.state === bookingPlaceState.available || position.state === bookingPlaceState.bookedForMe" class="map-item" [ngClass]="{ 'is-disabled': !position.isEnabled,
                                    'free': (position.state === bookingPlaceState.available && position.isEnabled), 'selected': (position.state === bookingPlaceState.bookedForMe || position.isSelected) }" 
                                    (click)="selectSeat(position)">
                                    <ng-container *ngIf="!(position.state === bookingPlaceState.available && position.isEnabled)">
                                        <img alt="Position" [src]="'assets/icons/map/white/'+position.icon+'.svg'">
                                    </ng-container>
                                    <ng-container *ngIf="(position.state === bookingPlaceState.available && position.isEnabled)">
                                        <img alt="Position" [src]="'assets/icons/map/black/'+position.icon+'.svg'">
                                    </ng-container>
                                </div>
                                
                                <div class="map-text" [ngClass]="{ 'is-disabled': !position.isEnabled, 'booked': (position.state === bookingPlaceState.booked || position.state === bookingPlaceState.bookedByGuest),
                                    'free': (position.state === bookingPlaceState.available && position.isEnabled), 'selected': (position.state === bookingPlaceState.bookedForMe || position.isSelected) }">{{ position.name }}</div>
                            </div>
                        </ng-container>
                        <ng-container *ngFor="let item of roomMapDetail.items">
                            <div [ngStyle]="{ 'position': 'absolute', 'top': (item.positionY * Y_SIZE) + PADDING + 'px', 'left': (item.positionX * X_SIZE) + PADDING + 'px'  }">
                                <div class="map-item is-disabled">
                                    <img alt="Position" [src]="'assets/icons/map/white/'+item.icon+'.svg'">
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div nz-row nzJustify="center" top-16>
            <div nz-col [nzSpan]="23" class="flex justify">
                <div class="caption free"></div> {{ 'vacant' | translate }}
                <div class="caption seat"></div> {{ 'your_seat' | translate }}
                <div class="caption unavailable"></div> {{ 'unavailable' | translate }}
            </div>
        </div>

        <div nz-row top-16>
            <div nz-col [nzSpan]="12">
                <button id="btn_calendar_map_cancel" nz-button nzBlock nzType="text" class="big sub-heading-2" (click)="cancelSeatBooking()">
                    {{ 'cancel' | translate }}
                </button>
            </div>

            <div nz-col [nzSpan]="12">
                <button id="btn_calendar_map_book" nz-button nzBlock nzType="primary" class="big" [disabled]="!selectedPosition" [nzLoading]="isLoading" (click)="acceptSeatBooking()">
                    <span *ngIf="!selectedPosition">
                        {{ 'select_a_seat' | translate }}
                    </span>
                    <span *ngIf="selectedPosition && (!schedule.bookingInfo.isPayment || schedule.isAvailableInMyFee || schedule.bookingInfo.amountCredits == null || schedule.bookingInfo.amountCredits == 0)">
                        {{ 'book' | translate }}
                    </span>
                    <span *ngIf="selectedPosition && schedule.bookingInfo.isPayment && !schedule.isAvailableInMyFee && schedule.bookingInfo.amountCredits != null && schedule.bookingInfo.amountCredits > 0">
                        {{ 'book_premium' | translate:{ credits: schedule.bookingInfo.amountCredits } }}
                    </span>
                </button> 
            </div>
        </div>
    </ng-container>
</nz-spin>

<ng-template #indicatorTemplate><i nz-icon nzType="loading" [nzTheme]="'outline'"
    style="font-size: 24px; display: block;"></i>{{ 'loading' | translate }}...
</ng-template>