import { Fee } from '../fee';
import { ProductStock } from './product-stock';

export class Product extends Fee {
    price!: number;
    isActiveStock!: boolean;
    initialUnits!: number;
    consumedUnits!: number;
    remainingUnits!: number;
    stocks!: ProductStock[];

    get productPrice(): number {
        return this.priceWithTax;
    }

    build(content: any): this {
        super.build(content);

        this.price = content.price;
        this.isActiveStock = content.isActiveStock;
        this.initialUnits = content.initialUnits;
        this.stocks = content.stocks;

        if (content.stocks) {
            this.calculateUnits();
        }

        return this;
    }

    private calculateUnits(): void {
        this.initialUnits = 0;
        this.consumedUnits = 0;
        this.remainingUnits = 0;

        this.stocks.forEach(stock => {
            this.initialUnits += stock.initialUnits;
            this.consumedUnits += stock.usedUnits;
        });

        this.remainingUnits = this.initialUnits - this.consumedUnits;
    }
}
