<nz-card class="card-2">
    <div nz-row [nzGutter]="[0, 16]" top-0>
        <div nz-col [nzSpan]="24" class="schedule-time-box">
            <span nz-icon nzType="clock-circle" nzTheme="outline"></span> {{ schedule.timeStartDate | date:hourFormat }}
        </div>

        <div nz-col [nzSpan]="24" class="common-caption-2 text-ellipsis">
            {{ schedule.activity.name }} <i nz-icon nzType="star" nzTheme="fill" *ngIf="isLogged && schedule.isFavorite" nz-tooltip [nzTooltipTitle]="'favorite_activity' | translate" class="favorite-icon"></i>
        </div>

        <div nz-col [nzSpan]="24" class="common-caption-3">
            <div class="seats-status">
                <ng-container *ngIf="schedule.bookingState == bookingStates.finished">
                    <div class="seats-status-icon finished"></div><div class="seats-status-text">{{ 'finished' | translate }}</div>
                </ng-container>
                <ng-container *ngIf="schedule.bookingState == bookingStates.booked || schedule.bookingState == bookingStates.multipleBookedAvailable || schedule.bookingState == bookingStates.bookedMultipleTimes">
                    <div class="seats-status-icon available"></div><div class="seats-status-text">{{ 'booked' | translate }}</div>
                </ng-container>
                <ng-container *ngIf="schedule.bookingState != bookingStates.finished && schedule.bookingState != bookingStates.booked && schedule.bookingState != bookingStates.multipleBookedAvailable && schedule.bookingState != bookingStates.bookedMultipleTimes">
                    <div class="seats-status-icon" [ngClass]="{ 'available' : schedule.availableSeats > 0, 'unavailable' : schedule.availableSeats === 0 }"></div>
                    <ng-container *ngIf="isLogged || (!bookingSetting?.isHideCapacityAndAvailabilityAnonymous && !isLogged)">
                        <div class="seats-status-text">{{ 'x_available_seats' | translate: { x: schedule.availableSeats } }}</div>
                    </ng-container>
                </ng-container>
            </div>
        </div>

        <ng-container *ngIf="showDate">
            <div nz-col [nzSpan]="24" class="common-2 data-with-icon">
                <span nz-icon nzType="calendar" nzTheme="outline" class="primary-icon-24"></span><span class="info-label">{{ schedule.dateProgram | date:dateFormat }}</span>
            </div>  
        </ng-container>

        <div nz-col [nzSpan]="24" class="common-2 data-with-icon">
            <span nz-icon nzType="field-time" nzTheme="outline" class="primary-icon-24"></span><span class="info-label">{{ schedule.duration }} min</span>
        </div>

        <div nz-col [nzSpan]="24" class="common-2 data-with-icon">
            <span nz-icon nzType="environment" nzTheme="outline" class="primary-icon-24"></span><span class="info-label">{{ schedule.room.room }}</span>
        </div>

        <ng-container *ngIf="schedule.staff">
            <div nz-col [nzSpan]="24" class="staff-avatar">
                <nz-avatar [nzSize]="48" [nzSrc]="schedule.staff.urlPhoto"></nz-avatar>
                <div>
                    <div class="common-3 staff-text">{{ 'staff' | translate }}</div>
                    <div class="common-2 staff-text">{{ schedule.staff.nameStaff }}</div>
                </div>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="!isLogged">
        <div nz-row>
            <div nz-col [nzSpan]="24">
                <button nz-button nzBlock nzType="primary" (click)="goToLogin()">
                    {{ 'book' | translate }}
                </button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isLogged">
        <div nz-row>
            <div nz-col [nzSpan]="24">
                <ng-container *ngIf="schedule.bookingState != bookingStates.streamingAvailable
                    && schedule.bookingState != bookingStates.streamingSoon
                    && schedule.bookingState != bookingStates.waitQueueAvailable
                    && schedule.bookingState != bookingStates.inWaitQueue">
                    <button nz-button nzBlock nzType="primary" (click)="viewDetails()">
                        {{ 'details' | translate }}
                    </button>
                </ng-container>

                <ng-container *ngIf="schedule.bookingState == bookingStates.streamingAvailable">
                    <button nz-button nzBlock nzType="primary" (click)="view(schedule.bookingInfo.urlStreaming)" >
                        {{ 'visualize' | translate }}
                    </button>
                </ng-container>

                <ng-container *ngIf="schedule.bookingState == bookingStates.streamingSoon">
                    <button nz-button nzBlock nzType="primary" [disabled]="true">
                        {{ 'on_hold' | translate }}
                    </button>
                </ng-container>

                <ng-container *ngIf="schedule.bookingState == bookingStates.waitQueueAvailable">
                    <button nz-button nzBlock nzType="primary" (click)="showModalWaitQueue()"  [nzLoading]="isLoading">
                        {{ schedule.bookingStateText }}
                    </button>
                </ng-container>

                <ng-container *ngIf="schedule.bookingState == bookingStates.inWaitQueue">
                    <button nz-button nzBlock nzType="default" nz-popconfirm [nzPopconfirmTitle]="'are_you_sure_cancel_wait_queue' | translate"
                        (nzOnConfirm)="cancelWaitQueue()" [nzLoading]="isLoading">
                        {{ schedule.bookingStateText }}
                    </button>
                </ng-container>
            </div>
        </div>
    </ng-container>
</nz-card>

<app-wait-queue-modal [schedule]="schedule"></app-wait-queue-modal>

<ng-template #indicatorTemplate><i nz-icon nzType="loading" [nzTheme]="'outline'"
    style="font-size: 24px; display: block;"></i>{{ 'loading' | translate }}...
</ng-template>