import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Login } from '@models/login/login';
import { UserLogin } from '@models/user/user-login';

import { HttpService } from '@services/http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EndpointParams } from '@models/utils/endpoint-params';
import { InternationalizationService } from './utils/internationalization/internationalization.service';

@Injectable({
    providedIn: 'root'
})
export class UserService extends HttpService {
    private readonly defaultEndpointParams: EndpointParams = { app: 'member', version: 1 };

    constructor(
        private internalizationService: InternationalizationService,
        http: HttpClient){
        super(http);
    }

    login(login: Login, idCenter: number): Observable<UserLogin[]> {
        const headers = new HttpHeaders({ 'X-idcenterrequest' : idCenter.toString() });
        const languageCode = this.internalizationService.getLanguage();

        return this.post(`auth/list?language_code=${languageCode}`, this.defaultEndpointParams, login, { headers });
    }
}
