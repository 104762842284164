import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpService } from '@services/http.service';
import { EndpointParams } from '@models/utils/endpoint-params';
import { BookingInfo } from '@views/booking/models/booking-info/booking-info';

@Injectable({
    providedIn: 'root'
})
export class BookingInfoService extends HttpService {
    private readonly defaultEndpointParams: EndpointParams = { app: 'booking', version: 2 };

    info(idBooking: number): Observable<BookingInfo> {
        return this.get2(`bookings/members/me/${idBooking}/info`, this.defaultEndpointParams);
    }
}
